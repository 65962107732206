import ApiService from './api.service';

class PermanenceService {

    constructor() {
        this.basePath = "permanences";
        this.fullSelector = `(
            name,
            members,
            stats
        )`;
    }

    async getAll(type) {
        try {
            const response = await ApiService.get(`${this.basePath}/all?selector=${this.fullSelector.replace(/\s/g, '')}&type=${type}`);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    }

    async get() {
        try {
            const response = await ApiService.get(`${this.basePath}?selector=(stats)`);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    }
}

export default new PermanenceService();