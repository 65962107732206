<template>
  <navbar :show-navbar="showNavbar" class="top-navbar" :transparent="false">
    <div class="navbar-wrapper">
      <div class="navbar-toggle" :class="{toggled: $sidebar.showSidebar}">
        <navbar-toggle-button @click.native="toggleSidebar"></navbar-toggle-button>
      </div>
      <a class="navbar-brand">
        {{$route.meta.label}}
      </a>
    </div>
  </navbar>
</template>
<script>
import { Navbar, NavbarToggleButton } from 'src/components';

export default {
    name: 'TopNavbar',
    components: {
        Navbar,
        NavbarToggleButton,
    },
    data() {
        return {
            activeNotifications: false,
            showNavbar: false
        };
    },
    methods: {
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        toggleNotificationDropDown() {
            this.activeNotifications = !this.activeNotifications;
        },
        closeDropDown() {
            this.activeNotifications = false;
        },
        toggleSidebar() {
            this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
        },
        toggleNavbar() {
            this.showNavbar = !this.showNavbar;
        },
        hideSidebar() {
            this.$sidebar.displaySidebar(false);
        }
    }
};
</script>

<style lang="scss">
.top-navbar {
    display: none;
    @media screen and (max-width: 991px) {
        display: block;
    }
}
</style>
