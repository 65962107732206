import ContactService from '../services/contact.service';
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            accountId: null,
            addingContact: false,
            modals: {
                contactModal: {
                    display: false,
                    data: null,
                }
            }
        };
    },
    computed: {
        ...mapGetters({
            accountContacts: 'files/accountContacts'
        }),
    },
    methods: {
        async addContact() {
            this.addingContact = true;
            const newContact = {
                name: "",
                email: "",
                title: "",
                city: "",
                mobilePhone: "",
                officePhone: "",
                stillWorkingThere: true,
                reasonLeaving: "",
                accountId: this.accountId,
            };
            const data = await ContactService.post(newContact);
            this.$store.commit("files/addAccountContact", data)
            this.modals.contactModal.display = true;
            this.modals.contactModal.data = data;
            this.addingContact = false;
        }
    }
};