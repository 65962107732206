const missionInsdustries = [
	"Acier",
	"Aérospatiale",
	"Agroalimentaire",
	"Communication",
	"Construction / Infrastructure",
	"Consultation",
	"Culture / Loisirs / Divertissement",
	"Éducation",
	"Énergies",
	"Environnemental",
	"Forestière",
	"Ingénierie",
	"Machinerie",
	"Manufacturier",
	"Minier / Gaz",
	"Pharmaceutique / Biotechnologie / Soins de santé",
	"Plastique",
	"Technologies de l'information",
	"Textile",
	"Tourisme",
	"Transport"
];

export default missionInsdustries;