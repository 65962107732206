<template>
    <div class="overflow-auto follow-up-widget">
        <el-tooltip :disabled="title.length < 24" :open-delay="100" :content="title" placement="top">
            <div @contextmenu.prevent="() => { if (deleteFile) $refs.menu.open($event) }">
                <div class="d-flex justify-content-be<tween mb-2">

                    <h6 @click="titleClicked" class="px-3 m-0 text-truncate follow-up-widget__account-name"
                        :class="{ 'clickable': titleClickable }">{{ title }}</h6>

                </div>
                <div class="d-flex align-items-center px-3" style="height: 36px">
                    <div v-if="file" class="mr-2 d-flex align-items-center" style="width: 20px">
                      <img v-if="file.type === 'INT'" class="widget-icon" src="img/commerciale-active.png"/>
                      <img v-if="file.type === 'CAN'" class="widget-icon" src="img/recrutement-active.png"/>
                    </div>
                    <div class="mr-2 d-flex align-items-center hot" @click="changeHotStatus">
                        <img v-if="file.hot" class="widget-icon" src="img/fire-red.svg" />
                        <img v-else class="widget-icon" src="img/fire-black.svg" />
                    </div>
                    <span class="ml-2" v-if="file.coordoRessourceID"> {{ coordoRessourceFullName }}</span>
                    <div class="d-flex">
                        <span
                            class="text-white bg-success p-1 d-flex justify-content-center align-items-center rounded-circle"
                            v-if="file.mandateIds && file.mandateIds.length > 0">
                            <i class="now-ui-icons ui-1_check follow-up-widget__outcome-icon"></i>
                        </span>
                        <el-tooltip :content="tooltipContent" placement="top" v-if="file.notSignedReasons.length > 0">
                            <span
                                class="text-white bg-danger p-1 d-flex justify-content-center align-items-center rounded-circle">
                                <i class="now-ui-icons ui-1_simple-remove follow-up-widget__outcome-icon"></i>
                            </span>
                        </el-tooltip>
                    </div>
                </div>
                <ol class="breadcrumb follow-up-widget bg-white d-flex flex-nowrap">
                    <case type="lead" :completed="file.leadIds && file.leadIds.length > 0" :clickable="true"
                        :follow-up-modal="followUpModal" :file="file" />
                    <case type="call" :completed="file.callIds && file.callIds.length > 0"
                        :clickable="file.leadIds && file.leadIds.length > 0" :follow-up-modal="followUpModal"
                        :file="file" />
                    <case type="meeting" :canceled="file.canceledFlag"
                        :completed="file.meetingIds && file.meetingIds.length > 0"
                        :clickable="file.callIds && file.callIds.length > 0" :follow-up-modal="followUpModal"
                        :file="file" />
                    <case type="mandate" :completed="file.mandateIds && file.mandateIds.length !== 0"
                        :clickable="file.meetingIds && file.meetingIds.length > 0" :follow-up-modal="followUpModal"
                        :file="file" />
                </ol>
            </div>
        </el-tooltip>
        <vue-context ref="menu">
            <li v-if="deleteFile">
                <a href="#" @click.prevent="deleteFile(file.id)">
                    <span class="mr-1">Supprimer</span>
                    <i class="fa fa-trash"></i>
                </a>
            </li>
        </vue-context>
    </div>
</template>

<script>
import Case from './FollowUpWidgetStep';
import { Tooltip } from 'element-ui';
import { getLabel } from '../../util/outcomes.util';
import { VueContext } from 'vue-context';
import FileService from '../../services/file.service';
import UserService from "../../services/user.service"

export default {
    components: {
        Case,
        [Tooltip.name]: Tooltip,
        VueContext,
    },
    data() {
        return {
            coordoRessourceFullName: "",
        }
    },
    async created() {
        if (this.file?.coordoRessourceID) {
            const user = await UserService.get(this.file.coordoRessourceID);
            if (user && user.firstName) {
                this.coordoRessourceFullName = this.getFullName(user);
            }
        }
    },
    methods: {
        titleClicked() {
            if (this.titleClickable) {
                const routeData = this.$router.resolve({ name: 'account', params: { id: this.file.account.id } });
                window.open(routeData.href, '_blank');
            }
        },
        async changeHotStatus() {
            if (!this.file.hot) {
                this.file.hot = true;
            } else {
                this.file.hot = false;
            }
            let data = {
                id: this.file.id,
                hot: this.file.hot
            };
            await FileService.put(data);
        },
        getFullName(user) {
            return `${user.firstName} ${user.lastName}`;
        },
    },
    computed: {
        tooltipContent() {
            if (!this.file.notSignedReasons) return '';
            let result = "";
            const uniqueArray = this.$_.uniq(this.file.notSignedReasons);
            for (let i = 0; i < uniqueArray.length; i++) {
                result += getLabel(uniqueArray[i]) + " | ";
            }
            return result;
        },
        title() {
            let name = "";
            if (this.file.account.name) {
                name = this.file.account.name;
            }
            if (this.displayYear) {
                return `${name} (${this.file.year})`;
            } else {
                return name;
            }
        },
    },
    props: {
        file: Object,
        followUpModal: Object,
        displayYear: {
            type: Boolean,
            default: false,
        },
        deleteFile: Function,
        titleClickable: {
            type: Boolean,
            default: false,
        },
    }
}
</script>

<style lang="scss">
.follow-up-widget {
    min-height: 36px;
    line-height: 36px;
    list-style: none;
    overflow: auto;

    .planet-earth-icon {
        font-size: 20px;
        color: blue;
    }

    .hot {
        cursor: pointer;
    }

    .widget-icon {
        height: 20px;
    }

    &__outcome-icon {
        font-size: 0.5rem;
    }

    &__account-name {
        max-width: 248px;

        &.clickable {
            cursor: pointer;
            transition: color 100ms ease-in-out;

            &:hover {
                color: #f96332;
            }
        }
    }

}

.popper__arrow,
.popper__arrow::after {
    border-top-color: white !important;
}
</style>
