<template>
<modal :show.sync="modal.display" header-classes="justify-content-center">
    <h4 slot="header" class="title title-up">{{name}}</h4>
    <p>{{description}}</p>
    <template slot="footer">
        <div></div>
        <n-button type="danger" @click.native="modal.display = false">Close</n-button>
    </template>
</modal>
</template>

<script>
import {
    Modal
} from 'src/components'

export default {
    computed: {
        name() {
            if (!this.modal.data) return "";
            return this.modal.data.name;
        },
        description() {
            if (!this.modal.data) return "";
            return this.modal.data.description;
        },
    },
    components: {
        Modal,
    },
    props: {
        modal: Object,
    }
}
</script>

<style lang="scss" scoped>

</style>
