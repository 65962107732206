<template>
    <card class="meeting-form-cell">
        <div>
            <div class="d-flex justify-content-between align-items-center mb-4">
                <h4 class="card-title m-0">
                    <input class="meeting-cell-title" :value="meeting.name" @keydown="onNameKeyDown"
                        @input="onNameChange" />
                </h4>
                <n-checkbox class="m-0" v-model="meeting.canceled" @input="onCanceledChanged">Annulé</n-checkbox>
            </div>
            <div class="row">
                <div class="col-12 col-lg-6">
                    <div>
                        <label>Mené par</label>
                        <AgentSelector :dropup="false" placeholder="Mené par" v-model="meeting.doneBy" type="search"
                            @input="onDoneByChange" />
                    </div>
                    <fg-input>
                        <el-date-picker :value="meeting.dateStart" :clearable="false" @input="onDateChange" type="datetime"
                            placeholder="Date et heure">
                        </el-date-picker>
                    </fg-input>
                    <div class="mt-3">
                        <label>Accompagnateur</label>
                        <AgentSelector placeholder="Agent" type="search" :value="meeting.partnerId"
                            @input="onPartnerIdChange" />
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="mt-3 d-flex flex-column">
                        <n-checkbox class="ml-3 mb-1" v-model="meeting.virtual"
                            @input="onVirtualChanged">Virtuel</n-checkbox>
                        <el-select multiple="" class="select-primary w-100" collapse-tags=""
                            :value="selects.outcome.value" @input="onOutcomeChanged" placeholder="Résultats">
                            <el-option v-for="option in selects.outcome.options" class="select-primary"
                                :value="option.value" :label="option.label" :key="option.label">
                            </el-option>
                        </el-select>
                        <label>Accompagnateur demandé</label>
                        <toggle-button :value="internationalChecked" @input="onProgramChanged"
                            :color="{ checked: 'blue', unchecked: 'red' }"
                            :labels="{ checked: 'Commercial', unchecked: 'Recrutement' }" style="width: fit-content"
                            :width="110" />
                        <el-select class="select-primary" placeholder="Destination"
                            v-model="meeting.preferredPartnerDelegationId"
                            @input="onPreferredPartnerDelegationIdChanged">
                            <el-option v-for="(delegation, idx) in delegationsOptions" class="select-primary"
                                :value="delegation.id" :label="delegation.name" :key="idx" />
                        </el-select>
                    </div>


                </div>
                <div class="col-12">
                    <div class="form-group has-label mt-3">
                        <label>Description et actions posées</label>
                        <div class="textarea-description-container">
                            <textarea-debounce class="form-control textarea-description"
                                v-model="meeting.description" @save="onDescriptionChanged" />
                        </div>
                    </div>
                </div>
            </div>

            <StaticTable class="mb-2" label="Contacts" :add="addContact" :adding="addingContact"
                adding-tooltip="Ajouter un contact" column1="Nom" column2="Mobile" column3="Email" column4="Personne rencontrée"
                :source="accountContactsHasBeenCalled"
                :selector-one="(e) => e.name"
                :selector-two="(e) => e.mobilePhone"
                :selector-three="(e) => e.email"
                :selector-four="(e) => e.hasBeenCalled"
                @click="(data) => {
                    modals.contactModal.display = true;
                    modals.contactModal.data = data;
                }" 
                :displayisHasBeenCalledCheckbox="this.meeting.id"
                :serviceToUse="'meeting'"
                :error="(e) => !e.stillWorkingThere" />

            <div class="has-label mt-3">
                <label>Marchés visés</label>
                <DefaultTagWidget :value="destinations" @newItem="destinationAdded" @oldItem="destinationRemoved"
                    placeholder="Marchés" :source="countriesRegionsList" attribute="name" :removeWithConfirmation="true"
                    confirmModalTitle="Supprimer le marché"
                    confirmModalMessage="Êtes-vous sûr!! ce marché sera probablement supprimé dans les objectifs" />
            </div>
            <div class="has-label mt-3">
                <label>Objectifs</label>
                <goal mode="EDIT"></goal>
            </div>

            <div class="w-100 d-flex justify-content-center">
                <n-button class="mt-4" type="danger" @click.native="onDeleteMeetingClicked"
                    @blur.native="() => { deleteConfirmation = false }">{{ deleteButtonLabel }}</n-button>
            </div>
        </div>
        <ContactModal :modal="modals.contactModal" />
    </card>
</template>

<script>
import StaticTable from '../../pages/account/StaticTable.vue';
import ContactModal from '../../pages/account/ContactModal';

import { Card, Checkbox, FormGroupInput as FgInput, Goal } from 'src/components';
import { DatePicker, TimeSelect, Select, Option } from 'element-ui';
import MeetingService from '../../services/meeting.service';
import FileService from '../../services/file.service';
import PerfectScrollbar from 'perfect-scrollbar';
import DefaultTagWidget from '../DefaultTagWidget';
import countriesRegionsList from '../../util/countries.regions.util';
import AgentSelector from '../AgentSelector';
import { meetingOutcomes } from '../../util/outcomes.util';
import TextareaDebounce from './TextareaDebouce';
import MarketService from '../../services/market.service';
import { mapGetters } from 'vuex';
import SocketService from '../../services/socket.service';
import { ToggleButton } from 'vue-js-toggle-button';

import contactsMixins from '../../mixins/contacts.mixins';


export default {
    mixins: [contactsMixins],
    components: {
        Card,
        FgInput,
        [Checkbox.name]: Checkbox,
        [DatePicker.name]: DatePicker,
        [TimeSelect.name]: TimeSelect,
        [Select.name]: Select,
        [Option.name]: Option,
        DefaultTagWidget,
        AgentSelector,
        TextareaDebounce,
        ToggleButton,
        Goal,
        StaticTable,
        ContactModal
    },
    computed: {
        deleteButtonLabel() {
            if (this.deleteConfirmation) return 'Supprimer (Confirmez!)';
            return 'Supprimer';
        },
        ...mapGetters({
            file: 'files/active',
            destinations: 'files/destinations'
        }),
        delegationsOptions() {
            let result = JSON.parse(JSON.stringify(this.delegations));
            if (this.meeting.type === "INT") {
                result = this.$_.reject(result, function (e) {
                    return e.type !== "INT";
                });
            } else {
                result = this.$_.reject(result, function (e) {
                    return e.type !== "CAN";
                });
            }
            result.push({
                id: "",
                name: "Peu importe"
            });
            return result;
        },
        internationalChecked() {
            return this.meeting.type === 'INT';
        },
    },
    props: {
        delegations: Array,
        meeting: Object,
        setError: Function,
        deleteMeeting: Function,
        updateCanceledFlag: Function,
        updateVirtualFlag: Function,
    },
    data() {
        let self = this;
        return {
            resizeTimer: null,
            ps: null,
            deleteConfirmation: false,
            selects: {
                outcome: {
                    options: meetingOutcomes,
                    value: self.meeting.selectedOutcomes,
                },
            },
            countriesRegionsList: countriesRegionsList,
            accountContactsHasBeenCalled: [],
        };
    },
    mounted() {
        this.updateDescriptionTextareaHeight(this.$el.querySelector(".textarea-description"));
        this.ps = new PerfectScrollbar(this.$el.querySelector(".textarea-description-container"));
        window.addEventListener('resize', this.onWindowResize);

        let contactIds = this.accountContacts.map(contact => contact.id);

        // Deep copy of this.accountContacts to keep the integrity of the original object
        this.accountContactsHasBeenCalled = JSON.parse(JSON.stringify(this.accountContacts));

        let hasBeenCalledArray = Array.isArray(this.meeting.hasBeenCalled) ? this.meeting.hasBeenCalled : [];

        this.accountContactsHasBeenCalled.forEach(contact => {
            contact.hasBeenCalled = hasBeenCalledArray.includes(contact.id);
        });
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onWindowResize);
    },
    methods: {
        onWindowResize() {
            clearTimeout(this.resizeTimer);
            let self = this;
            this.resizeTimer = setTimeout(function () {
                self.updateDescriptionTextareaHeight(self.$el.querySelector(".textarea-description"));
                self.ps.update();
            }, 250);
        },
        onDeleteMeetingClicked() {
            if (!this.deleteConfirmation) {
                this.deleteConfirmation = true;
            } else {
                this.deleteMeeting(this.meeting.id);
            }
        },
        async onPreferredPartnerDelegationIdChanged(e) {
            this.setError(null);
            await MeetingService.put(this.getData("preferredPartnerDelegationId"));
        },
        async onDoneByChange(e) {
            this.setError(null);
            await MeetingService.put(this.getData("doneBy"));
        },
        async onNameChange(e) {
            this.setError(null);
            this.meeting.name = e.target.value;
            await MeetingService.put(this.getData("name"));
        },
        onNameKeyDown(e) {
            if (e.keyCode === 13) {
                e.preventDefault();
                e.target.blur();
            }
        },
        async onOutcomeChanged(newValue) {
            if (newValue.length > this.selects.outcome.value.length) {
                const addedValue = this.$_.difference(newValue, this.selects.outcome.value)[0];
                this.$store.commit('files/addNotSignedReason', addedValue);
                if (addedValue === "interestedCanada" && this.file.type === "INT") {
                    const file = await FileService.duplicateAndChangeType(this.file.id);
                    this.$store.commit('files/add', file);
                    alert("Un dossier recrutement vient d'être créé!");
                } else if (addedValue === "interestedInternational" && this.file.type === "CAN") {
                    const file = await FileService.duplicateAndChangeType(this.file.id);
                    this.$store.commit('files/add', file);
                    alert("Un dossier commercial vient d'être créé!");
                }
            } else {
                const removedValue = this.$_.difference(this.selects.outcome.value, newValue)[0];
                this.$store.commit('files/removeNotSignedReason', removedValue);
            }
            this.setError(null);
            this.selects.outcome.value = newValue;
            this.meeting.selectedOutcomes = newValue;
            await MeetingService.put(this.getData("selectedOutcomes"));
        },
        async onDateChange(newValue) {
            this.setError(null);
            if(!newValue)
                return
            this.meeting.dateStart = newValue;
            await MeetingService.put(this.getData("dateStart"));
            SocketService.emit('meetingUpdated', this.meeting.id);
        },
        async onPartnerIdChange(newValue) {
            this.setError(null);
            this.meeting.partnerId = newValue;
            await MeetingService.put(this.getData("partnerId"));
            SocketService.emit('meetingUpdated', this.meeting.id);
        },
        async onDescriptionChanged(e) {
            this.setError(null);
            this.updateDescriptionTextareaHeight(e.target);
            this.meeting.description = e.target.value;
            await MeetingService.put(this.getData("description"));
        },
        updateDescriptionTextareaHeight(element) {
            element.style.minHeight = "";
            element.style.minHeight = (element.scrollHeight + 2) + "px";
            if (this.ps) {
                this.ps.update();
            }
        },
        async destinationAdded(newItem) {
            this.setError(null);
            newItem.meetingId = this.meeting.id
            newItem.fileId = this.file.id
            const data = await MarketService.post(newItem, this.file.account.id);
            newItem.id = data.id;
            this.$store.commit('files/addAccountDestination', newItem)
        },
        async destinationRemoved(oldItem) {
            this.setError(null);
            await MarketService.delete(oldItem.id, this.file.account.id);
            this.$store.commit('files/removeDestination', oldItem.id)
            this.$store.commit('files/removeAccountDestination', oldItem.id)
            this.$store.commit('goals/removeMarket', oldItem.id)
        },
        async onCanceledChanged() {
            this.setError(null);
            this.updateCanceledFlag();
            await MeetingService.put(this.getData("canceled"));
        },
        async onVirtualChanged() {
            this.setError(null);
            this.updateVirtualFlag();
            await MeetingService.put(this.getData("virtual"));
        },
        async onProgramChanged(value) {
            this.setError(null);
            if (value) {
                this.meeting.type = 'INT';
            } else {
                this.meeting.type = 'CAN';
            }
            await MeetingService.put(this.getData("type"));
            SocketService.emit('meetingUpdated', this.meeting.id);
        },
        getData(attribute) {
            let data = {
                id: this.meeting.id
            };
            data[attribute] = this.meeting[attribute];
            return data;
        },
    },
}
</script>

<style lang="scss" scoped>
.card.meeting-form-cell .card-title .meeting-cell-title {
    font: inherit;
    margin: inherit;
    padding: inherit;
    border: 1px solid white;
    box-sizing: border-box;
    margin: 0;
    max-width: 75px;
}

.textarea-description-container {
    position: relative;
    border-top: solid 1px rgba(0, 0, 0, .1);
}

:deep .el-tag.el-tag--info {
    border-color: transparent !important;

    .el-tag__close {
        color: white !important;
    }
}
</style>