<template>
    <div>
        <Spinner size="4" v-if="loading" />
        <span v-if="error" class="text-danger italic">{{ formattedError }}</span>
        <div v-if="!loading">
            <tabs ref="TabsRef" type="primary">
                <tab-pane v-for="(lead, index) in leads" :label="lead.name">
                    <LeadFormCell :key="index" :lead="lead" :set-error="setError" :delete-lead="deleteLead" />
                </tab-pane>
            </tabs>
        </div>
    </div>
</template>

<script>
import { Spinner, Tabs, TabPane } from 'src/components';
import LeadFormCell from './LeadFormCell';

export default {
    computed: {
        formattedError() {
            if (this.error.response.status === 403) return "Vous ne pouvez pas supprimer ce lead s'il y a encore des appels!";
            return "Une erreur s'est produite!";
        }
    },
    components: {
        Spinner,
        LeadFormCell,
        Tabs,
        TabPane
    },
    props: {
        leads: Array,
        loading: Boolean,
        error: Error,
        deleteLead: Function,
        setError: Function,
    },
}
</script>

<style lang="scss" scoped>
:deep .nav-link {
    padding: 4px 23px !important;
}

:deep .tab-space {
    padding: 20px 0 0px 0px;
}
</style>
