<template>
    <card class="call-form-cell">
        <div>
            <h4 class="card-title mt-0">
                <input class="call-cell-title" :value="call.name" @keydown="onNameKeyDown" @input="onNameChange" />
            </h4>
            <div>
                <label>Mené par</label>
                <AgentSelector :dropup="false" placeholder="Mené par" v-model="call.doneBy" type="search"
                    @input="onDoneByChange" />
            </div>
            <div>
                <fg-input>
                    <el-date-picker :value="call.dateStart" :clearable="false" @input="onDateChange" type="datetime"
                        placeholder="Date et heure">
                    </el-date-picker>
                </fg-input>
                <el-select multiple="" class="select-info w-100" collapse-tags="" :value="selects.outcome.value"
                    @input="onOutcomeChanged" placeholder="Résultats">
                    <el-option v-for="option in selects.outcome.options" class="select-primary" :value="option.value"
                        :label="option.label" :key="option.label">
                    </el-option>
                </el-select>
                <div class="form-group has-label mt-3">
                    <label>Description et actions posées</label>
                    <div class="textarea-description-container">
                        <textarea-debounce class="form-control textarea-description overflow-hidden"
                            v-model="call.description" @save="onDescriptionChanged" />
                    </div>
                </div>
                <StaticTable class="mb-2" label="Contacts" :add="addContact" :adding="addingContact"
                    adding-tooltip="Ajouter un contact" column1="Nom" column2="Mobile" column3="Email" column4="Personne rencontrée"
                    :source="accountContactsHasBeenCalled" 
                    :selector-one="(e) => e.name" 
                    :selector-two="(e) => e.mobilePhone"
                    :selector-three="(e) => e.email" 
                    :selector-four="(e) => e.hasBeenCalled"
                    @click="(data) => {
                        modals.contactModal.display = true;
                        modals.contactModal.data = data;
                    }" 
                    :displayisHasBeenCalledCheckbox="this.call.id"
                    :serviceToUse="'call'"
                    :error="(e) => !e.stillWorkingThere" />
                <div class="has-label mt-3">
                    <label>Marchés visés</label>
                    <DefaultTagWidget :value="destinations" @newItem="destinationAdded" @oldItem="destinationRemoved"
                        placeholder="Marchés" :source="countriesRegionsList" attribute="name"
                        :removeWithConfirmation="true" confirmModalTitle="Supprimer le marché"
                        confirmModalMessage="Êtes-vous sûr!! ce marché sera probablement supprimé dans les objectifs" />
                </div>
                <div class="has-label mt-3">
                    <label>Objectifs</label>
                    <goal mode="EDIT"></goal>
                </div>
            </div>
            <div class="w-100 d-flex justify-content-center">
                <n-button class="mt-4" type="danger" @click.native="onDeleteCallClicked"
                    @blur.native="() => { deleteConfirmation = false }">{{ deleteButtonLabel }}</n-button>
            </div>
        </div>
        <ContactModal :modal="modals.contactModal" />
    </card>
</template>

<script>
import StaticTable from '../../pages/account/StaticTable.vue';
import ContactModal from '../../pages/account/ContactModal';

import { Card, FormGroupInput as FgInput, Goal } from 'src/components';
import { DatePicker, TimeSelect, Select, Option } from 'element-ui';
import CallService from '../../services/call.service';
import FileService from '../../services/file.service';
import PerfectScrollbar from 'perfect-scrollbar';
import DefaultTagWidget from '../DefaultTagWidget';
import countriesRegionsList from '../../util/countries.regions.util';
import { callOutcomes } from '../../util/outcomes.util';
import TextareaDebounce from './TextareaDebouce';
import MarketService from '../../services/market.service';
import { mapGetters } from 'vuex';
import AgentSelector from '../AgentSelector';
import contactsMixins from '../../mixins/contacts.mixins';

export default {
    mixins: [contactsMixins],
    components: {
        Card,
        FgInput,
        [DatePicker.name]: DatePicker,
        [TimeSelect.name]: TimeSelect,
        [Select.name]: Select,
        [Option.name]: Option,
        DefaultTagWidget,
        TextareaDebounce,
        AgentSelector,
        Goal,
        StaticTable,
        ContactModal
    },
    computed: {
        deleteButtonLabel() {
            if (this.deleteConfirmation) return 'Supprimer (Confirmez!)';
            return 'Supprimer';
        },
        ...mapGetters({
            file: 'files/active',
            destinations: 'files/destinations'
        }),
    },
    props: {
        call: Object,
        setError: Function,
        deleteCall: Function,
    },
    data() {
        let self = this;
        return {
            resizeTimer: null,
            ps: null,
            deleteConfirmation: false,
            selects: {
                outcome: {
                    options: callOutcomes,
                    value: self.call.selectedOutcomes,
                },
            },
            countriesRegionsList: countriesRegionsList,
            accountContactsHasBeenCalled: [],
        };
    },
    mounted() {
        this.updateDescriptionTextareaHeight(this.$el.querySelector(".textarea-description"));
        this.ps = new PerfectScrollbar(this.$el.querySelector(".textarea-description-container"));
        window.addEventListener('resize', this.onWindowResize);

        let contactIds = this.accountContacts.map(contact => contact.id);

        // Deep copy of this.accountContacts to keep the integrity of the original object
        this.accountContactsHasBeenCalled = JSON.parse(JSON.stringify(this.accountContacts));

        let hasBeenCalledArray = Array.isArray(this.call.hasBeenCalled) ? this.call.hasBeenCalled : [];

        this.accountContactsHasBeenCalled.forEach(contact => {
            contact.hasBeenCalled = hasBeenCalledArray.includes(contact.id);
        });
    },
    created() {
        this.accountId = this.file.account.id
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onWindowResize);
    },
    methods: {
        onWindowResize() {
            clearTimeout(this.resizeTimer);
            let self = this;
            this.resizeTimer = setTimeout(function () {
                self.updateDescriptionTextareaHeight(self.$el.querySelector(".textarea-description"));
                self.ps.update();
            }, 250);
        },
        onDeleteCallClicked() {
            if (!this.deleteConfirmation) {
                this.deleteConfirmation = true;
            } else {
                this.deleteCall(this.call.id);
            }
        },
        async onDoneByChange(e) {
            this.setError(null);
            await CallService.put(this.getData("doneBy"));
        },
        async onNameChange(e) {
            this.setError(null);
            this.call.name = e.target.value;
            await CallService.put(this.getData("name"));
        },
        onNameKeyDown(e) {
            if (e.keyCode === 13) {
                e.preventDefault();
                e.target.blur();
            }
        },
        async onOutcomeChanged(newValue) {
            if (newValue.length > this.selects.outcome.value.length) {
                const addedValue = this.$_.difference(newValue, this.selects.outcome.value)[0];
                this.$store.commit('files/addNotSignedReason', addedValue);
                if (addedValue === "interestedCanada" && this.file.type === "INT") {
                    const file = await FileService.duplicateAndChangeType(this.file.id);
                    this.$store.commit('files/add', file);
                    alert("Un dossier recrutement vient d'être créé!");
                } else if (addedValue === "interestedInternational" && this.file.type === "CAN") {
                    const file = await FileService.duplicateAndChangeType(this.file.id);
                    this.$store.commit('files/add', file);
                    alert("Un dossier commercial vient d'être créé!");
                }
            } else {
                const removedValue = this.$_.difference(this.selects.outcome.value, newValue)[0];
                this.$store.commit('files/removeNotSignedReason', removedValue);
            }
            this.setError(null);
            this.selects.outcome.value = newValue;
            this.call.selectedOutcomes = newValue;
            await CallService.put(this.getData("selectedOutcomes"));
        },
        async onDateChange(newValue) {
            this.setError(null);
            if(!newValue)
                return
            this.call.dateStart = newValue;
            await CallService.put(this.getData("dateStart"));
        },
        async onDescriptionChanged(e) {
            this.setError(null);
            this.updateDescriptionTextareaHeight(e.target);
            await CallService.put(this.getData("description"));
        },
        updateDescriptionTextareaHeight(element) {
            element.style.minHeight = "";
            element.style.minHeight = (element.scrollHeight + 2) + "px";
            if (this.ps) {
                this.ps.update();
            }
        },
        async destinationAdded(newItem) {
            this.setError(null);
            newItem.callId = this.call.id
            newItem.fileId = this.file.id
            const data = await MarketService.post(newItem, this.file.account.id);
            newItem.id = data.id;
            this.$store.commit('files/addAccountDestination', newItem)
        },
        async destinationRemoved(oldItem) {
            this.setError(null);
            await MarketService.delete(oldItem.id, this.file.account.id);
            this.$store.commit('files/removeDestination', oldItem.id);
            this.$store.commit('files/removeAccountDestination', oldItem.id)
            this.$store.commit('goals/removeMarket', oldItem.id)
        },
        getData(attribute) {
            let data = {
                id: this.call.id
            };
            data[attribute] = this.call[attribute];
            return data;
        },
    },
}
</script>

<style lang="scss" scoped>
.card.call-form-cell .card-title .call-cell-title {
    font: inherit;
    margin: inherit;
    padding: inherit;
    border: 1px solid white;
    box-sizing: border-box;
    margin: 0;
}

.textarea-description-container {
    position: relative;
    border-top: solid 1px rgba(0, 0, 0, .1);
}

:deep .el-tag.el-tag--info {
    border-color: transparent !important;

    .el-tag__close {
        color: white !important;
    }
}

@media screen and (max-width: 461px) {
    .card.call-form-cell .card-title .call-cell-title {
        max-width: 250px;
    }
}

@media screen and (max-width: 425px) {
    .card.call-form-cell .card-title .call-cell-title {
        max-width: 230px;
    }
}

@media screen and (max-width: 384px) {
    .card.call-form-cell .card-title .call-cell-title {
        max-width: 200px;
    }
}

@media screen and (max-width: 358px) {
    .card.call-form-cell .card-title .call-cell-title {
        max-width: 170px;
    }
}

@media screen and (max-width: 328px) {
    .card.call-form-cell .card-title .call-cell-title {
        max-width: 150px;
    }
}

@media screen and (max-width: 300px) {
    .card.call-form-cell .card-title .call-cell-title {
        max-width: 100px;
    }
}
</style>