import ApiService from './api.service';

class DelegationService {

    constructor() {
        this.basePath = "delegations";
        this.fullSelector = "(name,type,deleted)";
    }

    async getAll(type) {
        try {
            const response = await ApiService.get(`${this.basePath}/all?selector=${this.fullSelector}&type=${type}`);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    }

    async put(data) {
        try {
            const response = await ApiService.put(`${this.basePath}?selector=${this.fullSelector}`, data);
            return response.data;
        } catch (e) {
            throw e;
        }
    }

    async post(data) {
      try {
          const response = await ApiService.post(`${this.basePath}?selector=${this.fullSelector}`, data);
          return response.data;
      } catch (e) {
          throw e;
      }
  }


}

export default new DelegationService();
