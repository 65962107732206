<template>
<div class="client-selector position-relative dropup show">
    <fg-input v-if="!multiple" :placeholder="placeholder" :value="name" @input="onInput" @focus="focused = true" @blur="onblur" @keydown="onKeyDown"></fg-input>
    <ul class="dropdown-menu show" v-if="suggestions.length > 0 && focused && !multiple">
        <a class="dropdown-item cursor-pointer" v-for="(suggestion, index) in suggestions" :key="index" @mousedown="onSuggestionSelect(suggestion)">
            {{getName(suggestion)}}
        </a>
    </ul>
    <el-select v-if="multiple" multiple="" class="select-primary multiple-select" collapse-tags="" :value="multipleSelect" placeholder="Mandant(s)">
        <el-option v-for="option in contacts" class="select-primary" :value="option.id" :label="option.name" :key="option.id">
        </el-option>
    </el-select>
</div>
</template>

<script>
import {FormGroupInput as FgInput} from 'src/components';
import {DropDown} from 'src/components';
import ContactService from '../services/contact.service';
import {normalizeLowerCaseString} from '../util/string.util';
import { mapGetters } from 'vuex';
import {Select, Option} from 'element-ui';

const maxSuggestions = 5;

export default {
    components: {
        FgInput,
        DropDown,
        [Select.name]: Select,
        [Option.name]: Option,
    },
    watch: {
        focused(newValue) {
            if (!newValue) {
                this.currentFocus = -1;
            }
        },
    },
    props: {
        placeholder: String,
        value: String,
        multiple: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            multipleSelect: [],
            contacts: [],
            currentFocus: -1,
            suggestions: [],
            name: '',
            focused: false,
        };
    },
    async created() {
        const promise1 = this.setNameFromValue();
        const promise2 = this.loadContacts();
        await Promise.all([promise1, promise2]);
    },
    computed: {
        ...mapGetters({
            activeFile: 'files/active',
        }),
    },
    methods: {
        async loadContacts() {
            this.contacts = await ContactService.getAccountContacts(this.activeFile.account.id);
        },
        onKeyDown(e) {
            const suggestions = this.$el.querySelectorAll('.dropdown-menu .dropdown-item');
            if (e.keyCode == 40) { //down
                e.preventDefault();
                this.currentFocus++;
                this.addActive(suggestions);
            } else if (e.keyCode == 38) { //up
                e.preventDefault();
                this.currentFocus--;
                this.addActive(suggestions);
            } else if (e.keyCode == 13) { //enter
                e.preventDefault();
                if (this.currentFocus > -1) {
                    if (suggestions) this.onSuggestionSelect(this.suggestions[this.currentFocus]);
                }
            }
        },
        addActive(suggestions) {
            this.removeActive(suggestions);
            if (this.currentFocus >= suggestions.length) this.currentFocus = 0;
            if (this.currentFocus < 0) this.currentFocus = (suggestions.length - 1);
            suggestions[this.currentFocus].classList.add("autocomplete-active");
        },
        removeActive(suggestions) {
            for (let i = 0; i < suggestions.length; i++) {
                suggestions[i].classList.remove("autocomplete-active");
            }
        },
        async setNameFromValue() {
            let contact;
            if (/\S/.test(this.value)) {
                contact = await ContactService.get(this.value);
            }
            if (contact && contact.name) {
                this.name = this.getName(contact);
            } else {
                this.name = "";
            }
        },
        onblur() {
            this.focused = false;
            this.suggestions = [];
            this.setNameFromValue();
        },
        getName(contact) {
            return `${contact.name}`;
        },
        onInput(val) {
            this.focused = true;
            this.name = val;
            let self = this;
            this.currentFocus = -1;
            const normalizedValue = normalizeLowerCaseString(val);
            this.removeActive(this.$el.querySelectorAll('.dropdown-menu .dropdown-item'));
            let suggestions;
            if (/\S/.test(normalizedValue)) {
                suggestions = this.$_.first(self.$_.filter(this.contacts, function(el) { 
                    return normalizeLowerCaseString(el.name).includes(normalizedValue); 
                }), maxSuggestions);
            } else {
                suggestions = [];
            }
            this.suggestions = suggestions;
        },
        onSuggestionSelect(contact) {
            this.$emit('input', contact.id);
            this.focused = false;
            this.name = this.getName(contact);
            this.suggestions = [];
        },
    }
}
</script>

<style lang="scss">
.client-selector {

    .multiple-select input.el-input__inner {
        margin: 0
    }
    
    .form-group {
            input:focus {
            border: 1px solid #E3E3E3;
        }

        &::after {
            content: '';
        }
    }

    .autocomplete-active {
        background-color: hsla(0,0%,87.1%,.3) !important;
    }
}
</style>
