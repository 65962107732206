<template>
  <modal
    :show.sync="followUpModal.display"
    header-classes="justify-content-center"
    class="follow-up-modal"
  >
    <div slot="header" class="title title-up d-flex flex-column align-items-center">
      <div v-if="file" class="row mb-2 programme-type">
        <h5 class="mb-0 mr-3">Programme:</h5>
        <img
          v-if="file.type === 'CAN'"
          class="programme-icon cursor-pointer"
          src="img/commerciale-inactive.png"
          @click="changeFileType('INT')"
        />
        <img
          v-if="file.type === 'INT'"
          class="programme-icon"
          src="img/commerciale-active.png"
          @click="changeFileType('INT')"
        />

        <img
          v-if="file.type === 'INT'"
          class="programme-icon cursor-pointer"
          src="img/recrutement-inactive.png"
          @click="changeFileType('CAN')"
        />
        <img
          v-if="file.type === 'CAN'"
          class="programme-icon"
          src="img/recrutement-active.png"
          @click="changeFileType('CAN')"
        />
      </div>
      <h6 class="m-0 title__account-name text-truncate font-weight-normal" v-if="file">
        {{ file.account.name }}
      </h6>
      <h4 class="m-0 font-weight-bold">{{ title }}</h4>
    </div>
    <div class="d-flex align-items-center position-relative">
      <n-button
        class="addElement"
        type="success"
        round=""
        icon=""
        v-if="
          (file && !file.leadId && followUpModal.form === 'lead') ||
          followUpModal.form !== 'lead'
        "
        @click.native="addElement"
      >
        <i class="now-ui-icons ui-1_simple-add"></i>
      </n-button>
      <div class="mr-3">
        <i
          class="now-ui-icons arrows-1_minimal-left"
          :class="{ 'cursor-pointer': displayLeftArrow, 'text-white': !displayLeftArrow }"
          @click="decrement"
        ></i>
      </div>
      <div class="flex-grow-1">
        <LeadForm
          v-if="followUpModal.form === 'lead'"
          ref="LeadFormRef"
          :leads="leads"
          :loading="loading"
          :error="error"
          :set-error="setError"
          :delete-lead="deleteLead"
        />
        <CallForm
          v-if="followUpModal.form === 'call'"
          ref="CallFormRef"
          :calls="calls"
          :loading="loading"
          :error="error"
          :set-error="setError"
          :delete-call="deleteCall"
        />
        <MeetingForm
          v-if="followUpModal.form === 'meeting'"
          ref="MeetingFormRef"
          :meetings="meetings"
          :loading="loading"
          :error="error"
          :set-error="setError"
          :delete-meeting="deleteMeeting"
          :update-canceled-flag="updateCanceledFlag"
          :update-virtual-flag="updateVirtualFlag"
          :delegations="delegations"
        />
        <MandateForm
          v-if="followUpModal.form === 'mandate'"
          ref="MandateFormRef"
          :mandates="mandates"
          :loading="loading"
          :error="error"
          :set-error="setError"
          :delete-mandate="deleteMandate"
        />
      </div>
      <div class="ml-3">
        <i
          class="now-ui-icons arrows-1_minimal-right"
          :class="{
            'cursor-pointer': displayRightArrow,
            'text-white': !displayRightArrow,
          }"
          @click="increment"
        ></i>
      </div>
    </div>
    <!-- <template slot="footer">
 
            <div class="w-100 d-flex justify-content-center">
                <n-button type="success" round="" icon=""
                    v-if="(file && !file.leadId && followUpModal.form === 'lead') || followUpModal.form !== 'lead'"
                    @click.native="addElement">
                    <i class="now-ui-icons ui-1_simple-add"></i>
                </n-button>
            </div>
        </template> -->
  </modal>
</template>

<script>
import { Modal } from "src/components";
import LeadForm from "./LeadForm";
import CallForm from "./CallForm";
import MeetingForm from "./MeetingForm";
import MandateForm from "./MandateForm";
import LeadService from "../../services/lead.service";
import CallService from "../../services/call.service";
import MeetingService from "../../services/meeting.service";
import MandateService from "../../services/mandate.service";
import DelegationService from "../../services/delegation.service";
import FileService from "../../services/file.service";
import { mapGetters } from "vuex";
import SocketService from "../../services/socket.service";

export default {
  components: {
    Modal,
    LeadForm,
    CallForm,
    MeetingForm,
    MandateForm,
  },
  watch: {
    async "file.id"() {
      this.loading = true;
      this.error = null;
      this.$store.commit("files/clearDestinations");
      const leadPromise = this.getLeads();
      const callPromise = this.getCalls();
      const meetingPromise = this.getMeetings();
      const mandatePromise = this.getMandates();
      const delegationPromise = this.getDelegations();
      const goalPromis = this.getGoals();
      const contactsPromis = this.getContacts();
      let self = this;
      await Promise.all([
        leadPromise,
        callPromise,
        meetingPromise,
        mandatePromise,
        delegationPromise,
        goalPromis,
        contactsPromis,
      ]).then(function () {
        self.loading = false;
      });
    },
  },
  data() {
    return {
      loading: false,
      error: null,
      leads: null,
      calls: null,
      meetings: null,
      mandates: null,
      delegations: null,
    };
  },
  computed: {
    ...mapGetters({
      file: "files/active",
      fileGoals: "goals/fileGoals",
    }),
    title() {
      if (this.followUpModal.form === "lead") {
        return "Lead";
      } else if (this.followUpModal.form === "call") {
        return "Appels";
      } else if (this.followUpModal.form === "meeting") {
        return "RDV";
      } else if (this.followUpModal.form === "mandate") {
        return "Mandats";
      }
      return "Error!";
    },
    displayLeftArrow() {
      if (this.followUpModal.form === "lead") return false;
      return true;
    },
    displayRightArrow() {
      if (!this.file) return true;
      if (
        this.followUpModal.form === "lead" &&
        (!this.file.leadIds || this.file.leadIds.length === 0)
      )
        return false;
      if (
        this.followUpModal.form === "call" &&
        (!this.file.callIds || this.file.callIds.length === 0)
      )
        return false;
      if (
        this.followUpModal.form === "meeting" &&
        (!this.file.meetingIds || this.file.meetingIds.length === 0)
      )
        return false;
      if (this.followUpModal.form === "mandate") return false;
      return true;
    },
  },
  props: {
    followUpModal: Object,
  },
  methods: {
    async changeFileType(type) {
      this.file.type = type;
      let data = {
        id: this.file.id,
        type: this.file.type,
      };
      await FileService.put(data);
    },
    async getDelegations() {
      try {
        let delegations = await DelegationService.getAll();
        if (delegations) {
          this.delegations = delegations;
        }
      } catch (error) {
        this.delegations = [];
      }
    },
    setError(val) {
      this.error = val;
    },
    increment() {
      this.error = null;
      if (this.followUpModal.form === "lead") {
        this.followUpModal.form = "call";
      } else if (this.followUpModal.form === "call") {
        this.followUpModal.form = "meeting";
      } else if (this.followUpModal.form === "meeting") {
        this.followUpModal.form = "mandate";
      }
    },
    decrement() {
      this.error = null;
      if (this.followUpModal.form === "call") {
        this.followUpModal.form = "lead";
      } else if (this.followUpModal.form === "meeting") {
        this.followUpModal.form = "call";
      } else if (this.followUpModal.form === "mandate") {
        this.followUpModal.form = "meeting";
      }
    },
    addElement() {
      if (this.followUpModal.form === "lead") {
        this.addLead();
      } else if (this.followUpModal.form === "call") {
        this.addCall();
      } else if (this.followUpModal.form === "meeting") {
        this.addMeeting();
      } else if (this.followUpModal.form === "mandate") {
        this.addMandate();
      }
    },
    updateCanceledFlag() {
      let result = true;
      for (let i = 0; i < this.meetings.length; i++) {
        if (!this.meetings[i].canceled) {
          result = false;
          break;
        }
      }
      if (this.meetings.length === 0) {
        result = false;
      }
      this.file.canceledFlag = result;
      this.$store.commit("files/update", this.file);
    },
    updateVirtualFlag() {
      let result = true;
      for (let i = 0; i < this.meetings.length; i++) {
        if (!this.meetings[i].virtual) {
          result = false;
          break;
        }
      }
      if (this.meetings.length === 0) {
        result = false;
      }
      this.file.virtualFlag = result;
      this.$store.commit("files/update", this.file);
    },
    async addLead() {
      this.loading = true;
      this.error = null;
      try {
        const newLead = await LeadService.post({
          name: `Lead ${this.leads.length + 1}`,
          fileId: this.file.id,
          accountId: this.file.account.id,
        });
        this.leads.push(newLead);
        this.file.leadIds.push(newLead.id);
        this.$store.commit("files/update", this.file);
        this.$nextTick(() => this.$refs.LeadFormRef?.$refs.TabsRef?.activateLastTab())
      } catch (error) {
        this.error = error;
      }
      this.loading = false;
    },
    async deleteLead(leadId) {
      this.loading = true;
      this.error = null;
      try {
        await LeadService.delete(leadId);
        for (let i = 0; i < this.leads.length; i++) {
          if (this.leads[i].id === leadId) {
            this.leads.splice(i, 1);
          }
          if (this.file.leadIds[i] === leadId) {
            this.file.leadIds.splice(i, 1);
          }
        }
        this.$store.commit("files/update", this.file);
      } catch (error) {
        this.error = error;
      }
      this.loading = false;
    },
    async getLeads() {
      try {
        let leads = await LeadService.getAll(this.file.leadIds);
        if (leads) {
          this.leads = leads;
        }
      } catch (error) {
        this.leads = [];
      }
    },
    async addCall() {
      this.loading = true;
      this.error = null;
      try {
        let newCall = await CallService.post({
          name: `Appel ${this.calls.length + 1}`,
          description: "",
          fileId: this.file.id,
          accountId: this.file.account.id,
        });
        this.calls.push(newCall);
        this.file.callIds.push(newCall.id);
        this.$store.commit("files/update", this.file);
        this.$nextTick(() => this.$refs.CallFormRef?.$refs.TabsRef?.activateLastTab())
      } catch (error) {
        this.error = error;
      }
      this.loading = false;
    },
    async deleteCall(callId) {
      this.loading = true;
      this.error = null;
      try {
        await CallService.delete(callId);
        for (let i = 0; i < this.calls.length; i++) {
          if (this.calls[i].id === callId) {
            this.calls.splice(i, 1);
          }
          if (this.file.callIds[i] === callId) {
            this.file.callIds.splice(i, 1);
          }
        }
        this.$store.commit("files/update", this.file);
      } catch (error) {
        this.error = error;
      }
      this.loading = false;
    },
    async getCalls() {
      try {
        let calls = await CallService.getAll(this.file.callIds);
        if (calls) {
          this.setDestinations(calls);
          this.calls = calls;
        }
      } catch (error) {
        this.calls = [];
      }
    },
    async addMeeting() {
      this.loading = true;
      this.error = null;
      try {
        let newMeeting = await MeetingService.post({
          name: `RDV ${this.meetings.length + 1}`,
          description: "",
          fileId: this.file.id,
          accountId: this.file.account.id,
          type: this.file.type,
        });
        this.meetings.push(newMeeting);
        this.file.meetingIds.push(newMeeting.id);
        this.updateCanceledFlag();
        SocketService.emit("meetingAdded", newMeeting.id);
        this.$nextTick(() => this.$refs.MeetingFormRef?.$refs.TabsRef?.activateLastTab())
      } catch (error) {
        this.error = error;
      }
      this.loading = false;
    },
    async deleteMeeting(meetingId) {
      this.loading = true;
      this.error = null;
      try {
        await MeetingService.delete(meetingId);
        for (let i = 0; i < this.meetings.length; i++) {
          if (this.meetings[i].id === meetingId) {
            this.meetings.splice(i, 1);
          }
          if (this.file.meetingIds[i] === meetingId) {
            this.file.meetingIds.splice(i, 1);
          }
        }
        this.updateCanceledFlag();
        SocketService.emit("meetingDeleted", meetingId);
      } catch (error) {
        this.error = error;
      }
      this.loading = false;
    },
    async getMeetings() {
      try {
        let meetings = await MeetingService.getAll(this.file.meetingIds);
        if (meetings) {
          //this.setDestinations(meetings)
          this.meetings = meetings;
        }
      } catch (error) {
        this.meetings = [];
      }
    },
    async addMandate() {
      this.loading = true;
      this.error = null;
      try {
        let newMandate = await MandateService.post({
          summary: "",
          year: "",
          destination: "",
          agentId: "",
          clientId: "",
          fileId: this.file.id,
          accountId: this.file.account.id,
        });
        this.mandates.push(newMandate);
        this.file.mandateIds.push(newMandate.id);
        this.updateCanceledFlag();
        this.$nextTick(() => this.$refs.MandateFormRef?.$refs.TabsRef?.activateLastTab())
      } catch (error) {
        this.error = error;
      }
      this.loading = false;
    },
    async deleteMandate(mandateId) {
      this.loading = true;
      this.error = null;
      try {
        await MandateService.delete(mandateId);
        for (let i = 0; i < this.mandates.length; i++) {
          if (this.mandates[i].id === mandateId) {
            this.mandates.splice(i, 1);
          }
          if (this.file.mandateIds[i] === mandateId) {
            this.file.mandateIds.splice(i, 1);
          }
        }
        this.updateCanceledFlag();
      } catch (error) {
        this.error = error;
      }
      this.loading = false;
    },
    async getMandates() {
      try {
        let mandates = await MandateService.getAll(this.file.mandateIds);
        if (mandates) {
          this.mandates = mandates;
        }
      } catch (error) {
        this.mandates = [];
      }
    },
    setDestinations(targets) {
      let destinations = [];
      targets.map((target) => {
        destinations.push(...[...target.destinations]);
      });
      destinations = Array.from(new Set(destinations.map((D) => D.id))).map((id) =>
        destinations.find((D) => D.id === id)
      );
      this.$store.commit("files/addDestinations", destinations);
    },
    async getGoals() {
      if (!this.file?.id) return;
      await this.$store.dispatch("goals/load", this.file.id);
    },
    async getContacts() {
      if (!this.file?.account?.id) return;
      if (["home"].includes(this.$route.name))
        await this.$store.dispatch("files/loadContacts", this.file.account.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.follow-up-modal {
  .programme-type {
    align-items: center;
  }

  .programme-icon {
    height: 25px;
    width: 25px;
    margin-right: 5px;
  }
}

.title__account-name {
  max-width: 400px;

  @media screen and (max-width: 516px) {
    max-width: 300px;
  }

  @media screen and (max-width: 400px) {
    max-width: 200px;
  }

  @media screen and (max-width: 300px) {
    max-width: 150px;
  }
}

.addElement {
  position: absolute !important;
  top: -1.6%;
  right: 6.4%;
}

@media screen and (max-width: 640px) {
  .addElement {
    right: -2.4%;
  }
}

:deep .nav-pills {
  width: 90% !important;

  li {
    margin-bottom: 8px !important;
  }
}

:deep .modal-body {
  padding-bottom: 28px !important;
}
</style>
