import ApiService from './api.service';

class UserService {

    constructor() {
        this.basePath = "users";
        this.fullSelector = `(
            username,
            firstName,
            lastName,
            enabled,
            roles,
            region,
            permanenceId,
            delegationId,
            territory
        )`;
    }

    async changePassword(data, admin) {
        try {
            let url = `${this.basePath}/changePassword`;
            if (admin) {
                url = `${url}?admin=true`;
            }
            const response = await ApiService.put(url, data);
            return response;
        } catch (e) {
            throw e;
        }
    }

    async put(data, selector) {
        let choosedSelector = this.fullSelector;
        if (selector) {
            choosedSelector = selector;
        }
        try {
            const response = await ApiService.put(`${this.basePath}?selector=${choosedSelector}`, data);
            return response.data;
        } catch (e) {
            throw e;
        }
    }

    async post(user) {
        try {
            const response = await ApiService.post(`${this.basePath}?selector=${this.fullSelector}`, user);
            return response.data;
        } catch (e) {
            throw e;
        }
    }

    async delete(userId) {
        try {
            await ApiService.delete(`${this.basePath}/${userId}`);
        } catch (e) {
            throw e;
        }
    }

    async get(userId, selector) {
        let choosedSelector = this.fullSelector;
        if (selector) {
            choosedSelector = selector;
        }
        try {
            const response = await ApiService.get(`${this.basePath}/${userId}?selector=${choosedSelector.replace(/\s/g, '')}`);
            return response.data;
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    async getCurrent(selector) {
        let choosedSelector = this.fullSelector;
        if (selector) {
            choosedSelector = selector;
        }
        try {
            const response = await ApiService.get(`${this.basePath}/current?selector=${choosedSelector.replace(/\s/g, '')}`);
            return response.data;
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    async removeShotguns(userId) {
        try {
            const response = await ApiService.post(`${this.basePath}/removeShotguns`, {id: userId});
            return response.data;
        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    async searchUser(query, size) {
        if (!size) {
            size = 5;
        }
        let suggestions;
        if (/\S/.test(query)) {
            const response = await ApiService.get(`${this.basePath}?query=${query}&size=${size}&selector=${this.fullSelector.replace(/\s/g, '')}`);
            suggestions = response.data;
        } else {
            suggestions = [];
        }
        return suggestions;
    }

    async searchCoordinator(query, size) {
        if (!size) {
            size = 5;
        }
        let suggestions;
        if (/\S/.test(query)) {
            const response = await ApiService.get(`${this.basePath}/coordos?query=${query}&size=${size}&selector=${this.fullSelector.replace(/\s/g, '')}`);
            suggestions = response.data;
        } else {
            suggestions = [];
        }
        return suggestions;
    }

    async findByDelegation(delegationId, selector) {
        let choosedSelector = this.fullSelector;
        if (selector) {
            choosedSelector = selector;
        }
        try {
            const response = await ApiService.get(`${this.basePath}/delegation/${delegationId}?selector=${choosedSelector.replace(/\s/g, '')}`);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    }

    async findEnabled() {
        try {
            const response = await ApiService.get(`${this.basePath}/enabled?selector=${this.fullSelector.replace(/\s/g, '')}`);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    }

    async findAll() {
        try {
            const response = await ApiService.get(`${this.basePath}/all?selector=${this.fullSelector.replace(/\s/g, '')}`);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    }
}

export default new UserService();