<template>
    <modal :show.sync="modal.display" header-classes="justify-content-center" class="contact-modal"
        modalClasses="contacat-modal">
        <h4 slot="header" class="title title-up">{{ name }}</h4>
        <div v-if="modal.data">
            <div class="row">
                <fg-input class="col-sm-6 col-12" label="Nom complet" placeholder="Nom" v-model="modal.data.name"
                    :debounce="debounce" @save="defaultOnInput('name')" />
                <fg-input class="col-sm-6 col-12" label="Poste" placeholder="Poste" v-model="modal.data.title"
                    :debounce="debounce" @save="defaultOnInput('title')" />
            </div>
            <div class="row">
                <fg-input class="col-sm-6 col-12" label="Ville" placeholder="Ville" v-model="modal.data.city"
                    :debounce="debounce" @save="defaultOnInput('city')" />
                <fg-input class="col-sm-6 col-12" label="Email" placeholder="Email" v-model="modal.data.email"
                    :debounce="debounce" @save="defaultOnInput('email')" />
            </div>
            <div class="row">
                <fg-input class="col-sm-6 col-12" label="Téléphone Bureau" placeholder="Téléphone Bureau"
                    v-model="modal.data.officePhone" :debounce="debounce" @save="defaultOnInput('officePhone')" />
                <fg-input class="col-sm-6 col-12" label="Téléphone mobile" placeholder="Téléphone mobile"
                    v-model="modal.data.mobilePhone" :debounce="debounce" @save="defaultOnInput('mobilePhone')" />
            </div>
            <div class="row">
                <fg-input class="col-sm-6 col-12" label="LinkedIn" placeholder="LinkedIn" v-model="modal.data.linkedIn"
                    :debounce="debounce" @save="defaultOnInput('linkedIn')" />
                <div class="col-sm-6 col-12">
                    <label>Poste hiérarchique</label>
                    <el-select class="select-primary hierarchy-role" collapse-tags="" :value="modal.data.hierarchyRole"
                        @input="hierarchyRoleChanged" placeholder="Poste hiérarchique">
                        <el-option v-for="option in hierarchyRoles" class="select-primary" :value="option.value"
                            :label="option.label" :key="option.value">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="row mb-1">
                <n-checkbox v-model="modal.data.newsletter" class="ph-15">Abonné(e) à l'infolettre</n-checkbox>
            </div>
            <div class="row mb-2">          
                <n-checkbox v-model="modal.data.interestedInNewsletter" class="ph-15">Intéressé(e) par l'infolettre</n-checkbox>
            </div>
            <div class="row mb-2">
                <n-checkbox v-model="modal.data.isAlumniMCUL" class="ph-15">Alumni MCUL</n-checkbox>
            </div> 
            <div class="row">
                <custom-textarea v-model="modal.data.concerns" label="Préoccupations" :debounce="debounce"
                    @save="defaultOnInput('concerns')" />
            </div>
            <div class="row mb-2">
                <n-checkbox v-model="modal.data.stillWorkingThere" class="ph-15">Y travaille toujours</n-checkbox>
            </div>
            <div class="row" v-if="!modal.data.stillWorkingThere">
                <custom-textarea v-model="modal.data.reasonLeaving" label="Raison du départ" :debounce="debounce"
                    @save="defaultOnInput('reasonLeaving')" />
            </div>
        </div>
        <template slot="footer">
            <span class="save-notice">{{ savingStatus }}</span>
            <n-button type="danger" @click.native="deleteContact" v-click-outside="resetDelete" :disabled="deleting">
                {{ deleteLabel }} <i class="fa fa-trash"></i>
            </n-button>
        </template>
    </modal>
</template>

<script>
import {
    Modal,
    FormGroupInput as FgInput,
    Checkbox,
} from 'src/components';
import CustomTextarea from './CustomTextarea';
import ContactService from '../../services/contact.service';
import { Select, Option } from 'element-ui';

export default {
    watch: {
        'modal.data.stillWorkingThere'() {
            this.defaultOnInput('stillWorkingThere');
        },
        'modal.data.newsletter'() {
            this.defaultOnInput('newsletter');
        },
        'modal.data.isAlumniMCUL'() {
            this.defaultOnInput('isAlumniMCUL');
        },        
        'modal.data.interestedInNewsletter'() {
            this.defaultOnInput('interestedInNewsletter');
        },
    },
    computed: {
        deleteLabel() {
            if (this.confirmDelete) return "Supprimer (Confirmer!) ";
            return "Supprimer ";
        },
        name() {
            if (!this.modal.data) return "";
            return this.modal.data.name;
        },
        savingStatus() {
            if (this.saving) {
                return "Sauvegarde en cours...";
            } else if (this.error) {
                return "Erreur de sauvegarde!";
            } else {
                return "Modifications sauvegardées";
            }
        },
    },
    components: {
        Modal,
        FgInput,
        CustomTextarea,
        [Checkbox.name]: Checkbox,
        [Select.name]: Select,
        [Option.name]: Option,
    },
    props: {
        modal: Object,
        deleteFromParent: Function,
    },
    data() {
        return {
            saving: false,
            error: null,
            debounce: 300,
            confirmDelete: false,
            deleting: false,
            hierarchyRoles: [
                {
                    label: "Preneur de décision le + influent",
                    value: "firstHierarchy"
                },
                {
                    label: "2e preneur de décision le + influent",
                    value: "secondHierarchy"
                },
                {
                    label: "3e preneur de décision le + influent",
                    value: "thirdHierarchy"
                },
                {
                    label: "4e preneur de décision le + influent",
                    value: "fourthHierarchy"
                }
            ]
        };
    },
    methods: {
        resetDelete() {
            this.confirmDelete = false
        },
        async deleteContact() {
            if (this.confirmDelete) {
                this.deleting = true;
                await ContactService.delete(this.modal.data.id);
                // this.deleteFromParent(this.modal.data.id);
                this.$store.commit('files/removeAccountContacts', this.modal.data.id)
                this.modal.display = false;
                this.modal.data = null;
                this.deleting = false;
            } else {
                this.confirmDelete = true;
            }
        },
        hierarchyRoleChanged(e) {
            this.modal.data.hierarchyRole = e;
            this.defaultOnInput('hierarchyRole');
        },
        defaultOnInput(attribute) {
            if (!this.modal?.data)
                return
            let data = {
                id: this.modal?.data?.id
            };
            data[attribute] = this.modal.data[attribute];
            this.$store.commit('files/updateAccountContacts', { id: data.id, value: data[attribute], attribute })
            this.save(data);
        },
        async save(data) {
            this.saving = true;
            this.error = null;
            try {
                await ContactService.put(data);
            } catch (e) {
                alert("Une erreur est survenue lors de la sauvegarde automatique! Rafraîchissez la page!");
                this.error = e;
            } finally {
                this.saving = false;
            }
        }
    },
}
</script>

<style lang="scss" scoped>

.contact-modal {

    .hierarchy-role input.el-input__inner {
        margin: 0;
    }

    label {
        font-size: 0.8571em;
        margin-bottom: 5px;
        color: #9A9A9A;
    }

    .ph-15 {
        padding-right: 15px !important;
        padding-left: 15px !important;
    }
}
</style>
