import io from 'socket.io-client';
import websocketConfig from '../config/websocket.config';
import TokenService from './token.service';

class SocketService {

    constructor() {
        this.socket = null;
    }

    disconnect() {
        if (this.socket) {
            this.socket.emit("forceDisconnect");
        }
        this.socket = null;
    }

    connect() {
        if (this.socket == null && TokenService.getWebsocketToken()) {
            this.socket = io.connect(websocketConfig.apiHost, {
                reconnect: true,
                secure: true,
                query: {token: TokenService.getWebsocketToken()}
            });
        }
    }

    emit(event, value) {
        this._verifyState();
        if (this.socket) {
            this.socket.emit(event, value);
        }
    }

    addListener(event, callback) {
        this._verifyState();
        if (this.socket) {
            this.socket.on(event, callback);
        }
    }

    removeListener(event, callback) {
        this._verifyState();
        if (this.socket) {
            this.socket.removeListener(event, callback);
        }
    }

    _verifyState() {
        if (!this.socket) {
            this.connect();
        }
    }
}

export default new SocketService();