<template>
  <div>
      <Spinner size="4"/>
  </div>
</template>

<script>
import {Spinner} from 'src/components';
import AuthenticationService from '../../services/authentication.service';

export default {
    async created() {
        await AuthenticationService.logout();
        this.$router.push("login");
    },
    components: {
        Spinner
    },
}
</script>

<style>

</style>