<template>
<div class="wrapper" :class="{'nav-open': $sidebar.showSidebar}">
    <notifications></notifications>
    <side-bar>
        <template slot="links">
            <sidebar-item :link="{name: 'Accueil', icon: 'now-ui-icons tech_tv', path: '/home'}"></sidebar-item>
            <sidebar-item :link="{name: 'Recherche', icon: 'now-ui-icons ui-1_zoom-bold', path: '/search'}"></sidebar-item>
            <sidebar-item :link="{name: 'Calendrier', icon: 'now-ui-icons media-1_album', path: '/calendar'}"></sidebar-item>
            <sidebar-item v-if="(admin || coordoInt)" :link="{name: 'Dashboard', icon: 'now-ui-icons location_world', path: '/dashboard'}"></sidebar-item>
            <sidebar-item v-if="admin" :link="{name: 'Administrateur', icon: 'now-ui-icons ui-2_settings-90', path: '/admin'}"></sidebar-item>
            <sidebar-item :link="{name: 'Paramètres', icon: 'now-ui-icons loader_gear', path: '/settings'}"></sidebar-item>
            <sidebar-item :link="{name: 'Déconnexion', icon: 'now-ui-icons sport_user-run', path: '/logout'}"></sidebar-item>
        </template>
    </side-bar>
    <div class="main-panel">
        <top-navbar></top-navbar>
        <div v-if="$route.path !== '/home'" class="main-panel__header-panel"></div>
        <HomeHeader v-if="$route.path === '/home'"/>
        <div @click="toggleSidebar" class="main-panel__content">
            <router-view></router-view>
        </div>
        <Footer/>
    </div>
</div>
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import TopNavbar from './TopNavbar';
import {Footer} from 'src/components';
import HomeHeader from '../pages/home/HomeHeader';
import TokenService from '../services/token.service';

export default {
    components: {
        TopNavbar,
        Footer,
        HomeHeader,
    },
    computed: {
        admin() {
            return TokenService.isAdmin();
        },
        coordoInt() {
            return TokenService.isCoordoInt();
        },
        coordoCan() {
            return TokenService.isCoordoCan();
        }
    },
    methods: {
        toggleSidebar() {
            if (this.$sidebar.showSidebar) {
                this.$sidebar.displaySidebar(false);
            }
        }
    },
    mounted() {
        let docClasses = document.body.classList;
        let isWindows = navigator.platform.startsWith('Win');
        if (isWindows) {
            // if we are on windows OS we activate the perfectScrollbar function
            initScrollbar('sidebar');
            initScrollbar('sidebar-wrapper');

            docClasses.add('perfect-scrollbar-on');
        } else {
            docClasses.add('perfect-scrollbar-off');
        }
    }
}

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}
</script>

<style lang="scss">
.main-panel {
    max-height: unset !important;
    min-height: 100%;
    height: auto !important;

    &__content {
        padding-top: 20px;
        padding-left: 30px;
        padding-right: 30px;
        min-height: calc(100vh - 82px);
        margin-bottom: 69px;

        @media screen and (max-width: 991px) {
            margin-top: 62px;
        }

        @media screen and (max-width: 768px) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    &__header-panel {
        position: absolute;
        background: linear-gradient(90deg,#0c2646 0,#204065 60%,#2a5788);
        overflow: hidden;
        top: 0;
        width: 100%;
        height: 385px;
        z-index: 0;
    }
}
</style>
