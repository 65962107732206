<template>
<div class="card card-stats card-raised">
    <div class="card-body">
        <div class="row">
            <div class="col-md-3">
                <div class="statistics">
                    <div class="info">
                        <div class="icon icon-primary">
                            <i class="fa fa-crosshairs"></i>
                        </div>
                        <h3 class="info-title">
                            <animated-number :value="shotgunsNb"></animated-number>
                        </h3>
                        <h6 class="stats-title">Shotguns</h6>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="statistics">
                    <div class="info">
                        <div class="icon icon-success">
                            <i class="fa fa-tasks"></i>
                        </div>
                        <h3 class="info-title">
                            <animated-number :value="leadsNb"></animated-number>
                        </h3>
                        <h6 class="stats-title">Leads</h6>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="statistics">
                    <div class="info">
                        <div class="icon icon-info">
                            <i class="now-ui-icons tech_mobile"></i>
                        </div>
                        <h3 class="info-title">
                            <animated-number :value="callsNb"></animated-number>
                        </h3>
                        <h6 class="stats-title">Appels</h6>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="statistics">
                    <div class="info">
                        <div class="icon icon-danger">
                            <i class="fa fa-handshake-o"></i>
                        </div>
                        <h3 class="info-title">
                            <animated-number :value="meetingsNb"></animated-number>
                        </h3>
                        <h6 class="stats-title">RDV</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {StatsCard, Card, AnimatedNumber} from 'src/components';

export default {
    components: {
        StatsCard,
        Card,
        AnimatedNumber,
    },
    props: {
        shotgunsNb: Number,
        leadsNb: Number,
        callsNb: Number,
        meetingsNb: Number,
    },
}
</script>

<style lang="scss">

</style>
