<template>
<div class="permanent-panel">
    <div class="d-flex justify-content-end">
        <n-button :disabled="loading" @click.native="refresh" type="warning">{{refreshLabel}} <i class="now-ui-icons loader_refresh"></i></n-button>
    </div>
    <Spinner size="4" v-if="loading"/>
    <div v-if="!loading">
        <card v-for="(permanence, index) in permanences" :key="index">
            <div class="d-flex justify-content-between">
                <h4 class="card-title">{{permanence.name}}</h4>
                <el-tooltip content="Ajouter un membre" :open-delay="100" placement="top" @click.native="() => {
                        addModal.display = true;
                        currentPermanence = permanence; }">
                    <n-button type="success" round="" icon="">
                        <i class="now-ui-icons ui-1_simple-add"></i>
                    </n-button>
                </el-tooltip>
            </div>
            <div class="row">
              <div class="mb-3 col-sm-12 col-md-12 col-lg-4">
                <h6 class="mb-0">Stats:</h6>
                <div class="col-12">
                    <table class="stats-table">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Leads</th>
                                <th>Appels</th>
                                <th>RDV</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(stats, j) in permanence.stats" :key="j">
                                <td>{{stats.name}}</td>
                                <td>{{stats.leadCount}}</td>
                                <td>{{stats.callCount}}</td>
                                <td>{{stats.meetingCount}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-8">

                  <div class="row">
                    <div>
                      <h6>Membres commerciaux:</h6>
                      <div class="row d-flex">
                          <div v-for="(member, i) in permanence.membresCommerciaux" :key="i" class="member-card">
                              <card style="margin-bottom: 0;">
                                  <div class="d-flex justify-content-between align-items-center">
                                      <span>{{member.firstName}} {{member.lastName}}</span>
                                      <n-button type="danger" round="" icon="" class="mt-0 mb-0 btn-remove" @click.native="removeFromPermanence(member.id, permanence)">
                                          <i class="now-ui-icons ui-1_simple-delete"></i>
                                      </n-button>
                                  </div>
                              </card>
                          </div>
                       </div>
                    </div>
                  </div>

                  <div class="row">
                    <div>
                      <h6>Membres recrutement:</h6>
                      <div class="row d-flex">
                          <div v-for="(member, i) in permanence.membresRecrutement" :key="i" class="member-card">
                              <card style="margin-bottom: 0;">
                                  <div class="d-flex justify-content-between align-items-center">
                                      <span>{{member.firstName}} {{member.lastName}}</span>
                                      <n-button type="danger" round="" icon="" class="mt-0 mb-0 btn-remove" @click.native="removeFromPermanence(member.id, permanence)">
                                          <i class="now-ui-icons ui-1_simple-delete"></i>
                                      </n-button>
                                  </div>
                              </card>
                          </div>
                       </div>
                    </div>
                  </div>

              </div>
            </div>

        </card>
    </div>
    <modal :show.sync="addModal.display" header-classes="justify-content-center" >
        <h4 slot="header" class="title title-up">Ajouter des membres</h4>
        <div>
          <label class="filter-label" for="userType">Filter: </label>

          <select name="usetType" id="userType" @change="selectFilterType">
            <option value="tous">Tous</option>
            <option value="AGENT_INT">Agents Commerciaux</option>
            <option value="AGENT_CAN">Agents recrutement</option>
          </select>
        </div>
        <div>
            <table cellspacing="0" cellpadding="0" border="0" class="w-100 custom-table">
                <thead class="has-gutter">
                    <tr class="row">
                        <th rowspan="1" colspan="1" class="col-10 col-md-3 cell-format">User</th>
                        <th rowspan="1" colspan="1" class="d-none d-md-flex col-md-7 cell-format" >Nom</th>
                        <th rowspan="1" colspan="1" class="col-2 col-md-1"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="row" v-for="(user, index) in availableUsers" :key="index">
                        <td rowspan="1" colspan="1" class="col-10 col-md-3 cell-format">{{user.username}}</td>
                        <td rowspan="1" colspan="1" class="d-none d-md-flex col-md-7 cell-format">{{user.firstName}} {{user.lastName}}</td>
                        <td rowspan="1" colspan="1" class="col-2 col-md-1 cell-format">
                            <n-button @click.native.stop="addToPermanence(user.id, currentPermanence.id)" type="success" round="" icon="" class="mt-0 mb-0">
                                <i class="now-ui-icons ui-1_simple-add"></i>
                            </n-button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </modal>
</div>
</template>

<script>
import {Card, Modal, Spinner, } from 'src/components';
import PermanenceService from '../../services/permanence.service';
import UserService from '../../services/user.service';
import {Tooltip} from 'element-ui';

export default {
    components: {
        Card,
        [Tooltip.name]: Tooltip,
        Modal,
        Spinner,
    },
    props: {
        enabledUsers: Array,
        availableUsers: [],
        type: String
    },
    computed: {

        refreshLabel() {
            return this.loaded ? "Rafraîchir" : "Charger les données";
        }
    },
    data() {
        return {
            permanences: [],
            addModal: {
                display: false,
            },
            currentPermanence: null,
            loading: false,
            loaded: false,
            filterType: "tous"
        };
    },
    methods: {
        async refresh() {
            this.loading = true;
            this.permanences = await PermanenceService.getAll(this.type);

            this.filterMembers();
            this.organizeMembers();

            this.loading = false;
            this.loaded = true;
        },
        organizeMembers() {
            const filterAndMap = (type, permanence) => permanence.members.filter(member =>
                member.roles.findIndex(role => role === type) > -1
            );

            this.membresRecrutement = this.permanences.forEach(permanence => {
                  permanence.membresCommerciaux = filterAndMap("AGENT_INT", permanence);
                  permanence.membresRecrutement = filterAndMap("AGENT_CAN", permanence);
            });
        },
        selectFilterType(e) {
          this.filterType = "tous";

          if (e) {
            e.stopPropagation();
            this.filterType = e.currentTarget.value;
          }

          this.filterMembers();
        },
        filterMembers() {

          const filterAvailableUsers = user => (!user.permanenceId || user.permanenceId === "") && !user.admin;

          this.availableUsers = this.enabledUsers.filter(filterAvailableUsers);

          if (this.filterType !== "tous") {
            this.availableUsers = this.availableUsers.filter(user => user.roles.findIndex(role => role === this.filterType) > -1)
          }

        },
        async addToPermanence(userId, permanenceId) {
            const user = await UserService.put({
                id: userId,
                permanenceId: permanenceId,
            }, `(
                firstName,
                lastName,
                roles
            )`);

            this.currentPermanence.members.push(user);

            for (let i = 0; i < this.enabledUsers.length; i++) {
                if (this.enabledUsers[i].id === userId) {
                    this.enabledUsers[i].permanenceId = permanenceId;
                    this.filterMembers();
                    break;
                }
            }

            this.organizeMembers();
        },
        async removeFromPermanence(userId, permanence) {
            await UserService.put({
                id: userId,
                permanenceId: "",
            }, `(
                firstName,
                lastName,
                roles
            )`);

            for (let i = 0; i < permanence.members.length; i++) {
                if (permanence.members[i].id === userId) {
                    permanence.members.splice(i, 1);
                    break;
                }
            }


            for (let i = 0; i < this.enabledUsers.length; i++) {
                if (this.enabledUsers[i].id === userId) {
                    this.enabledUsers[i].permanenceId = "";
                    this.filterMembers();
                    break;
                }
            }

            this.organizeMembers();
        },
    }
}
</script>

<style lang="scss">
.permanent-panel {
    .stats-table {
        td, th {
            text-align: center;
            padding: 5px 10px;
        }
    }

    .btn-remove {
      position: absolute;
      top: 5px;
      height: 1.375rem;
      min-width: 1.375rem;
      width: 1.375rem;
      right: 0;
    }

    .card .card-body {
        > div {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
      }
    }

    .member-card {
      width: 200px;
      margin-right: 10px;
      height: 75px;
    }

    .cell-format {
      padding: 2px !important;
      align-items: center !important;
      justify-content: left !important;
    }

    .modal-content {
      width: 500px;
      height: 700px;
      overflow-y: scroll;
    }

    .filter-label {
      color: black;
      font-size: inherit;
      margin-right: 5px;
    }
}
</style>
