<template>
    <div class="static-table">
        <div class="w-100 d-flex justify-content-between align-items-center">
            <label>{{label}}</label>
            <el-tooltip :content="addingTooltip" :open-delay="100" placement="top" v-if="add">
                <n-button type="success" round="" icon="" @click.native="add" :disabled="adding">
                    <i class="now-ui-icons ui-1_simple-add"></i>
                </n-button>
            </el-tooltip>
        </div>
        <table class="w-100 shadow-sm">
            <thead>
                <tr class="row">
                    <th class="col-6 col-lg-3">
                        {{column1}}
                    </th>
                    <th class="d-none d-lg-block col-lg-3">
                        {{column2}}
                    </th>
                    <th class="col-6 col-lg-3">
                        {{column3}}
                    </th>
                    <th v-if="displayisAlumniMCULCheckbox" class="col-6 col-lg-3 text-center">
                        {{column4}}
                    </th>
                    <th v-if="displayisHasBeenCalledCheckbox" class="col-6 col-lg-3 text-center">
                        {{column4}}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr class="row" v-for="(e, index) in source" :key="index" :class="{'error': hasError(e)}" @click="emit(e)">
                    <td class="col-6 col-lg-3">
                        {{selectorOne(e)}}
                    </td>
                    <td class="d-none d-lg-block col-lg-3">
                        {{selectorTwo(e)}}
                    </td>
                    <td class="col-6 col-lg-3">
                        {{selectorThree(e)}}
                    </td>
                    <td v-if="displayisAlumniMCULCheckbox" class="col-6 col-lg-3 text-center">
                        <input type="checkbox" :checked="selectorFour(e)" @click.stop="handleIsAlumniMCULCheckboxClick(e)" />
                    </td>
                    <td v-if="displayisHasBeenCalledCheckbox" class="col-6 col-lg-3 text-center">
                        <input type="checkbox" :checked="selectorFour(e)" @click.stop="handleIsHasBeenCalledCheckbox(e)" />
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    </template>
    
    <script>
    import ContactService from '../../services/contact.service';
    import CallService from '../../services/call.service';
    import MeetingService from '../../services/meeting.service';
   
    export default {
        watch: {
            source(newSource) {
                this.hasBeenCalledArray = newSource.filter(item => item.hasBeenCalled).map(item => item.id);
            },
        },        
        data() {
            return {
                saving: false,
                apiError: null,
                hasBeenCalledArray: [],
            };
        },
        computed: {
            shouldDisplayisAlumniMCULCheckbox() {
                const show = this.displayisAlumniMCULCheckbox;
                return show;   
            },
            shouldDisplayisHasBeenCalledCheckbox() {
                const show = this.displayisHasBeenCalledCheckbox;
                return show;   
            }
        },
        props: {
            label: String,
            column1: String,
            column2: String,
            column3: String,
            column4: String,
            source: Array,
            selectorOne: Function,
            selectorTwo: Function,
            selectorThree: Function,
            selectorFour: {
                type: Function,
                default: () => {},
            },        
            errorCheck: Function,
            add: {
                type: Function,
                default: null,
            },
            adding: {
                type: Boolean,
                default: false,
            },
            addingTooltip: String,
            displayisAlumniMCULCheckbox: {
                type: Boolean,
                default: false
            },     
            displayisHasBeenCalledCheckbox: String,
            serviceToUse: String,
        },
        methods: {
            emit(e) {
                this.$emit("click", e);
            },
            hasError(e) {
                if (!this.errorCheck) return false;
                return this.errorCheck(e);            
            },
    
            async handleIsAlumniMCULCheckboxClick(e) {
                e.isAlumniMCUL = !e.isAlumniMCUL;
    
                const data = {
                    id: e.id,
                    isAlumniMCUL: e.isAlumniMCUL,
                };
    
                this.saving = true;
                this.error = null;
                
                try {
                    await ContactService.put(data);
                } catch (error) {
                    alert("An error occurred while auto-saving! Refresh the page!");
                    this.error = error;
                } finally {
                    this.saving = false;
                }
            },

            async handleIsHasBeenCalledCheckbox(e) {
                e.hasBeenCalled = !e.hasBeenCalled;

                if (e.hasBeenCalled && e.hasBeenCalled === true) {
                    this.hasBeenCalledArray.push(e.id);
                } else {
                    const index = this.hasBeenCalledArray.indexOf(e.id);
                    if (index > -1) {
                        this.hasBeenCalledArray.splice(index, 1);
                    }
                }                

                const data = {
                    id: this.displayisHasBeenCalledCheckbox,
                    hasBeenCalled: this.hasBeenCalledArray,
                };

                this.saving = true;
                this.error = null;

                if (this.serviceToUse === 'call') {
                    try {
                        await CallService.put(data);
                    } catch (error) {
                        alert("An error occurred while auto-saving! Refresh the page!");
                        this.error = error;
                    } finally {
                        this.saving = false;
                    }     
                } else if (this.serviceToUse === 'meeting') {
                    try {
                        await MeetingService.put(data);
                    } catch (error) {
                        alert("An error occurred while auto-saving! Refresh the page!");
                        this.error = error;
                    } finally {
                        this.saving = false;
                    }     
                }
            },
        },
    }
    </script>
    
    <style lang="scss">
    .static-table {
        $default-table-border: 1px solid #E3E3E3;
        table {
    
            tr {
                margin-right: 0px;
                margin-left: 0px;
                padding-top: 3px;
                padding-bottom: 3px;
            }
    
            thead {
                border: $default-table-border;
            }
    
            tbody {
                border-bottom: $default-table-border;
                border-left: $default-table-border;
                border-right: $default-table-border;
    
                tr {
                    cursor: pointer;
    
                    &:hover {
                        background: aliceblue;
                    }
    
                    &.error {
                        background: rgba(255, 54, 54, 0.3);
                    }
                }
            }
        }
    }
    </style>