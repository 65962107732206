import ApiService from './api.service';

class MeetingService {

    constructor() {
        this.basePath = "meetings";
        this.fullSelector = `(
            name,
            partnerId,
            preferredPartnerDelegationId,
            description,
            selectedOutcomes,
            canceled,
            virtual,
            dateStart,
            destinations:(
                name,
                canceled,
                fileId
            ),
            hasBeenCalled,
            doneBy,
            type
        )`;
    }

    async addCurrentUserAsPartner(meetingId) {
        try {
            await ApiService.put(`${this.basePath}/${meetingId}/addCurrentUserAsPartner`);
        } catch (e) {
            throw e;
        }
    }

    async takeMeetingForCurrentUser(meetingId) {
        await ApiService.put(`${this.basePath}/${meetingId}/takeMeetingForCurrentUser`);
    }

    async get(meetingId, selector) {
        let choosedSelector = this.fullSelector;
        if (selector) {
            choosedSelector = selector;
        }
        try {
            const response = await ApiService.get(`${this.basePath}/${meetingId}?selector=${choosedSelector.replace(/\s/g, '')}`);
            return response.data;
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    async getSummary(year) {
        try {
            const response = await ApiService.get(`${this.basePath}/summary?year=${year}`);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    }

    async getAll(meetingIds) {
        try {
            const response = await ApiService.get(`${this.basePath}?meetingIds=${meetingIds.join()}&selector=${this.fullSelector.replace(/\s/g, '')}`);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    }

    async getInRange(startDate, endDate) {
        try {
            const response = await ApiService.get(`${this.basePath}/inRange?keepDuplicate=false&startDate=${startDate}&endDate=${endDate}&selector=())`);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    }

    async put(data) {
        try {
            const response = await ApiService.put(`${this.basePath}?selector=${this.fullSelector}`, data);
            return response.data;
        } catch (e) {
            throw e;
        }
    }

    async post(meeting) {
        try {
            const response = await ApiService.post(`${this.basePath}?selector=${this.fullSelector}`, meeting);
            return response.data;
        } catch (e) {
            throw e;
        }
    }

    async delete(meetingId) {
        try {
            await ApiService.delete(`${this.basePath}/${meetingId}`);
        } catch (e) {
            throw e;
        }
    }

}

export default new MeetingService();