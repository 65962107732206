<template>
    <div class="agent-selector position-relative show" :class="{ 'dropup': dropup }">
        <i @click="removeAgent" v-if="type === 'search'"
            class="delete-icon position-absolute cursor-pointer now-ui-icons ui-1_simple-remove" />
        <fg-input :debounce="300" :placeholder="placeholder" :value="fullName" @input="onInput" @focus="focused = true"
            @blur="onblur" @keydown="onKeyDown" />
        <ul class="dropdown-menu show" v-if="suggestions.length > 0 && focused">
            <a class="dropdown-item cursor-pointer" v-for="(suggestion, index) in suggestions" :key="index"
                @mousedown="onSuggestionSelect(suggestion)">
                {{ getFullName(suggestion) }}
            </a>
        </ul>
    </div>
</template>

<script>
import { FormGroupInput as FgInput } from 'src/components';
import { DropDown } from 'src/components';
import UserService from '../services/user.service';

export default {
    components: {
        FgInput,
        DropDown,
    },
    watch: {
        focused(newValue) {
            if (!newValue) {
                this.currentFocus = -1;
            }
        },
    },
    props: {
        placeholder: String,
        value: String,
        dropup: {
            default: true,
            type: Boolean,
        },
        type: {
            default: "",
            type: String,
        },
        isCoordinator: {
            default: false,
            type: Boolean
        }
    },
    data() {
        return {
            currentFocus: -1,
            suggestions: [],
            fullName: '',
            focused: false,
        };
    },
    async created() {
        await this.setFullNameFromValue();
    },
    methods: {
        onKeyDown(e) {
            const suggestions = this.$el.querySelectorAll('.dropdown-menu .dropdown-item');
            if (e.keyCode == 40) { //down
                e.preventDefault();
                this.currentFocus++;
                this.addActive(suggestions);
            } else if (e.keyCode == 38) { //up
                e.preventDefault();
                this.currentFocus--;
                this.addActive(suggestions);
            } else if (e.keyCode == 13) { //enter
                e.preventDefault();
                if (this.currentFocus > -1) {
                    if (suggestions) this.onSuggestionSelect(this.suggestions[this.currentFocus]);
                }
            }
        },
        addActive(suggestions) {
            this.removeActive(suggestions);
            if (this.currentFocus >= suggestions.length) this.currentFocus = 0;
            if (this.currentFocus < 0) this.currentFocus = (suggestions.length - 1);
            suggestions[this.currentFocus].classList.add("autocomplete-active");
        },
        removeActive(suggestions) {
            for (let i = 0; i < suggestions.length; i++) {
                suggestions[i].classList.remove("autocomplete-active");
            }
        },
        async setFullNameFromValue() {
            if (!this.value || this.value === "") {
                this.fullName = "";
                return;
            }
            let user = await UserService.get(this.value);
            if (user && user.firstName) {
                this.fullName = this.getFullName(user);
            } else {
                this.fullName = "";
            }
        },
        onblur() {
            this.focused = false;
            this.suggestions = [];
            this.setFullNameFromValue();
        },
        getFullName(user) {
            return `${user.firstName} ${user.lastName}`;
        },
        async onInput(val) {
            this.focused = true;
            this.fullName = val;
            this.currentFocus = -1;
            this.removeActive(this.$el.querySelectorAll('.dropdown-menu .dropdown-item'));
            this.suggestions = this.isCoordinator ? await UserService.searchCoordinator(val) : await UserService.searchUser(val);
        },
        async onSuggestionSelect(user) {
            this.$emit('input', user.id);
            this.focused = false;
            this.fullName = this.getFullName(user);
            this.suggestions = [];
        },
        async removeAgent() {
            this.$emit('input', "");
            this.focused = false;
            this.fullName = "";
            this.suggestions = [];
        },
    },
}
</script>

<style lang="scss">
.agent-selector {

    .delete-icon {
        right: 0;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
    }

    .form-group {
        input:focus {
            border: 1px solid #E3E3E3;
        }

        &::after {
            content: '';
        }
    }

    .autocomplete-active {
        background-color: hsla(0, 0%, 87.1%, .3) !important;
    }
}
</style>
