import ApiService from './api.service';

class MarketService {

    constructor() {
        this.basePath = "markets";
        this.fullSelector = `(
            name,
            fileId
        )`;
    }

    async post(data, accountId) {
        try {
            const response = await ApiService.post(`${this.basePath}?selector=${this.fullSelector}&accountId=${accountId}`, data);
            return response.data;
        } catch (e) {
            throw e;
        }
    }

    async delete(marketId, accountId) {
        try {
            await ApiService.delete(`${this.basePath}/${marketId}?accountId=${accountId}`);
        } catch (e) {
            throw e;
        }
    }
}

export default new MarketService();