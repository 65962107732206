import { Select, Option } from 'element-ui';
import quebecRegions from '../util/quebec.regions.util'

export default {
    components: {
        [Select.name]: Select,
        [Option.name]: Option,
    },
    data() {
        return {
            regions: {
                value: [],
                options: [...quebecRegions.map((region) => {
                    return {
                        value: region,
                        label: region
                    }
                })]
            }
        };
    },
    methods: {
        onRegionsChanged(newValue) {
            this.regions.value = newValue;
        },
    },
};