<template>
    <modal :showInfo="true" :show.sync="modal.display" header-classes="justify-content-center">
        <div slot="info" v-if="!loading && meeting !== null">RDV obtenu par <b>{{ meeting.agent?.firstName }}
                {{ meeting.agent?.lastName }}</b><span
                v-if="meeting.agent.delegationName !== null && meeting.agent.delegationName !== ''"> (Déleg:
                <b>{{ meeting.agent.delegationName }}</b>)</span>.</div>

        <h4 slot="header" class="title title-up">RDV</h4>

        <div v-if="!loading && meeting !== null">
            <div v-if="user !== null && (user.id === meeting.doneBy /*|| user.roles.includes('ADMIN')*/)">
                <p style="font-size: 115%;"><b>Options du RDV</b></p>
                <n-checkbox style="margin-bottom: 50px" v-model="meeting.virtual"
                    @input="onVirtualChanged">Virtuel</n-checkbox>
            </div>
            <p>RDV {{ meeting.virtual ? "virtuel" : "en personne" }} avec <b><a :href=accountUrl
                        target="_blank">{{ meeting.account.name }}</a></b>.</p>
            <p v-if="meeting.virtual === true">Ce RDV est <b>virtuel</b></p>
            <p v-if="meeting.virtual !== true">Dans la ville de <b>{{ meeting.account.city }}</b>.</p>
            <p v-if="meeting.virtual !== true">À l'adresse <b>{{ meeting.account.billingAddress }}</b>.</p>
            <p>Le <b>{{ dateTime }}</b>.</p>

            <hr />

            <div style="display:flex;flex-direction:row;justify-content: space-between;align-items: center;">
                <div>Mené par:</div>
                <n-button @click.native="takeMeetingForCurrentUser" type="info" round=""
                    v-if="(meeting.doneBy === null || meeting.doneBy === '') && meeting.partnerId !== user.id">
                    <i class="mr-1 now-ui-icons business_briefcase-24" />
                    Récupérer ce RDV?
                </n-button>
                <div v-if="meeting.doneBy !== null && meeting.doneBy !== '' && doneByUser !== null">
                    <b>
                        {{ doneByUser?.firstName }} {{ doneByUser.lastName }} <span
                            v-if="doneByUser.delegationName !== '' && doneByUser.delegationName !== null">({{ doneByUser.delegationName }})</span>
                    </b>
                </div>
                <div style="max-width: 50%; text-align: right;"
                    v-if="(meeting.doneBy === null || meeting.doneBy === '') && meeting.partnerId === user.id">Vous ne
                    pouvez pas mener un RDV que vous accompagnez.</div>
            </div>

            <hr />
            <div style="display:flex;flex-direction:row;justify-content: space-between;align-items: center;">
                <div>Accompagné par:</div>
                <n-button @click.native="addPartner" type="info" round=""
                    v-if="!meeting?.partnerId && user.id !== meeting.doneBy">
                    <i class="mr-1 now-ui-icons business_briefcase-24" /> Se mettre accompagnateur?
                </n-button>
                <div style="max-width: 50%; text-align: right;" v-if="user.id === meeting.doneBy">Vous ne pouvez pas
                    accompagner votre propre RDV.</div>
                <div v-if="meeting.partnerId !== null && meeting.partnerId !== ''">
                    <b>
                        {{ meeting.partner?.firstName }} {{ meeting.partner?.lastName }} <span
                            v-if="meeting.partner?.delegationName">({{ meeting.partner.delegationName }})</span>
                    </b>
                </div>
            </div>
            <hr />
            <p v-if="meeting.partnerId === null || meeting.partnerId === ''" v-html="delegationMessage"></p>
            <n-button @click.native="cederRdv" type="danger" round="" v-if="user.id === meeting.doneBy">
                {{ confirmCederText }}
            </n-button>
            <n-button @click.native="cederPartner" type="danger" round="" v-if="user.id === meeting.partnerId">
                {{ confirmCederPartnerText }}
            </n-button>
            <goal v-if="hadGoals" mode="READ" :accountMarkets="markets"></goal>
        </div>
        <Spinner size="4" v-if="loading" />
    </modal>
</template>
<script>
import { Modal, Spinner, Button, Checkbox, Goal } from 'src/components';
import MeetingService from '../../services/meeting.service';
import { getFormattedDateTime } from '../../util/time.util';
import SocketService from '../../services/socket.service';
import UserService from '../../services/user.service';
const CEDER_BUTTON_DEFAULT_TEXT = "Céder ce RDV pour un autre agent?";
const CEDER_PARTNER_BUTTON_DEFAULT_TEXT = "Se retirer du role d'accompagnateur?";
export default {
    created() {
        const self = this;
        SocketService.addListener('meetingDeleted', self.onMeetingDeleted);
        SocketService.addListener('meetingUpdated', self.update);
    },
    destroyed() {
        SocketService.removeListener('meetingDeleted', self.onMeetingDeleted);
        SocketService.removeListener('meetingUpdated', self.update);
    },
    components: {
        Modal,
        Spinner,
        [Button.name]: Button,
        [Checkbox.name]: Checkbox,
        Goal
    },
    computed: {
        dateTime() {
            if (!this.meeting) return "";
            return getFormattedDateTime(this.meeting.dateStart);
        },
        delegationMessage() {
            const delegation = this.meeting.preferredPartnerDelegationName;
            if (delegation === null) {
                return "Il n'y a pas de préférence pour la délégation de l'accompagnateur.";
            } else {
                return "Il faut un accompagnateur de la délégation: <b>" + delegation + "</b>";
            }
        }
    },
    watch: {
        'modal.display'(val) {
            if (val) {
                this.update();
            }
        }
    },
    props: {
        modal: Object,
    },
    data() {
        return {
            loading: false,
            meeting: null,
            user: null,
            doneByUser: null,
            accountUrl: null,
            confirmCeder: false,
            confirmCederText: CEDER_BUTTON_DEFAULT_TEXT,
            confirmCederTimeout: null,
            confirmCederPartner: false,
            confirmCederPartnerText: CEDER_PARTNER_BUTTON_DEFAULT_TEXT,
            confirmCederPartnerTimeout: null,
            markets: [],
            hadGoals: false
        };
    },
    methods: {
        onMeetingDeleted(meetingId) {
            if (self.meeting && meetingId === self.meeting.id) {
                alert("Ce meeting vient d'être supprimé!");
                self.modal.display = false;
            }
        },
        async update() {
            this.loading = true;
            this.user = await UserService.getCurrent(`(id,roles)`);
            this.meeting = await MeetingService.get(this.modal.meetingId,
                `(
                        virtual,
                        doneBy,
                        ceder,
                        dateStart, 
                        preferredPartnerDelegationName, 
                        partnerId,
                        fileId,
                        agent:(firstName,lastName,delegationName), 
                        partner:(firstName,lastName,delegationName),
                        account:(
                            markets:(id,name,meetingId,callId,canceled,fileId), 
                            name, 
                            city, 
                            billingAddress,
                            files:(year,goals:(goalId,fileId,name,marketId,candidats)
                            )
                        )
                    )`
            );
            if (this.meeting.doneBy !== null && this.meeting.doneBy !== "") {
                this.doneByUser = await UserService.get(this.meeting.doneBy,
                    `(
                            firstName,
                            lastName,
                            delegationName
                        )`
                );
            }
            this.accountUrl = `${window.location.origin}/#/account/${this.meeting.account.id}`;
            this.prepareGoals();
            this.loading = false;
        },
        async addPartner() {
            this.loading = true;
            try {
                await MeetingService.addCurrentUserAsPartner(this.modal.meetingId);
                this.$emit("reload");
                this.update();
                SocketService.emit('meetingUpdated', this.modal.meetingId);
            } catch (e) {
                alert(`${e.response.data.message} vient tout juste de s'ajouter comme accompagnateur/accompagnatrice!`);
                this.update();
            }
        },
        async cederRdv() {
            if (this.confirmCeder === true) {
                this.loading = true;
                this.confirmCeder = false
                clearTimeout(this.confirmCederTimeout);
                await MeetingService.put(this.parseData("doneBy", null));
                SocketService.emit('meetingUpdated', this.meeting.id);
                this.update();
                this.$emit("reload");
                this.confirmCederText = CEDER_BUTTON_DEFAULT_TEXT
            } else {
                this.confirmCeder = true;
                this.confirmCederText = "Confirmer!"
                this.confirmCederTimeout = setTimeout(() => {
                    this.confirmCeder = false;
                    this.confirmCederText = CEDER_BUTTON_DEFAULT_TEXT
                }, 5000);
            }
        },
        async cederPartner() {
            if (this.confirmCederPartner === true) {
                this.loading = true;
                this.confirmCederPartner = false
                clearTimeout(this.confirmCederPartnerTimeout);
                await MeetingService.put(this.parseData("partnerId", null))
                SocketService.emit('meetingUpdated', this.meeting.id);
                this.update();
                this.$emit("reload");
                this.confirmCederPartnerText = CEDER_PARTNER_BUTTON_DEFAULT_TEXT
            } else {
                this.confirmCederPartner = true;
                this.confirmCederPartnerText = "Confirmer!"
                this.confirmCederPartnerTimeout = setTimeout(() => {
                    this.confirmCederPartner = false;
                    this.confirmCederPartnerText = CEDER_PARTNER_BUTTON_DEFAULT_TEXT
                }, 5000);
            }
        },
        async takeMeetingForCurrentUser() {
            this.loading = true;
            await MeetingService.takeMeetingForCurrentUser(this.meeting.id);
            SocketService.emit('meetingUpdated', this.meeting.id);
            this.update();
            this.$emit("reload");
        },
        async onVirtualChanged() {
            await MeetingService.put(this.getData("virtual"));
            SocketService.emit('meetingUpdated', this.meeting.id);
            this.$emit("reload");
        },
        getData(attribute) {
            let data = {
                id: this.meeting.id
            };
            data[attribute] = this.meeting[attribute];
            return data;
        },
        parseData(attribute, value) {
            let data = {
                id: this.meeting.id
            };
            data[attribute] = value;
            return data;
        },
        prepareGoals() {
            const fileId = this.meeting?.fileId
            if (fileId) {
                const goals = this.meeting?.account?.files?.filter(file => (file.id === fileId))
                    .map(file => {
                        return file.goals?.map(goal => ({ ...goal, year: file.year }))
                    })
                    .flat()
                    .filter(goal => goal)
                this.hadGoals = goals.length > 0
                this.markets = this.meeting?.account?.markets
                this.$store.commit("goals/addAccountGoals", goals)
            }
        }
    },
}
</script>
<style lang="scss" scoped>@media (min-width: 576px) {
    :deep .modal-dialog {
        max-width: 70% !important;
        margin: 1.75rem auto;
    }
}</style>