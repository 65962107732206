<template>
    <div class="page-admin">
        <card>
            <tabs type="primary" v-if="!loading">
                <tab-pane label="Utilisateurs">
                    <UserPanel :enabled-users="enabledUsers" :all-users="allUsers" :pause-user="pauseUser"
                        :play-user="playUser" :delete-user="deleteUser" :put="put" :post="post"
                        v-bind:default-roles="[]" />
                </tab-pane>

                <tab-pane label="Données">
                    <DataExtractionTab />
                </tab-pane>
            </tabs>
            <Spinner size="4" v-if="loading" />
        </card>
    </div>
</template>

<script>
import {
    Card,
    Tabs,
    TabPane,
    Spinner,
} from 'src/components';
import UserService from '../../services/user.service';
import UserPanel from '../dashboard/UserPanel';
import FilePanel from '../dashboard/FilePanel';
import StatsPanel from '../dashboard/StatsPanel';
import adminMixins from '../../mixins/admin.mixins';
import DataExtractionTab from './DataExtractionTab';

export default {
    mixins: [adminMixins],
    components: {
        Card,
        Tabs,
        TabPane,
        UserPanel,
        StatsPanel,
        FilePanel,
        Spinner,
        DataExtractionTab,
    },
    async created() {
        this.loading = true;
        await Promise.all([this.fetchEnabledUsers(), this.fetchAllUsers()]);
        this.loading = false;
    },
}
</script>

<style lang="scss">

</style>
