<template>
<textarea class="text-area" :rows="rows" :value="value" @input="updateValue"></textarea>
</template>

<script>
export default {
    props: {
        value: String,
        debounce: {
            default: 300,
            type: Number,
        },
        rows: {
            type: Number,
            default: 5
        }
    },
    data() {
        return {
            touched: false,
            focused: false,
            timer: null,
        };
    },
    methods: {
        updateValue(evt) {
            let value = evt.target.value;
            this.$emit('input', value);
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            const self = this;
            this.timer = setTimeout(() => {
                this.$emit('save', evt);
            }, this.debounce);
        }
    },
}
</script>

<style lang="scss" scoped>
.text-area {
    resize: vertical;
    overflow-y: auto !important;
    max-height: none;
}
</style>