import PageLogin from '../pages/login/PageLogin';
import PageHome from '../pages/home/PageHome';
import PageSearch from '../pages/search/PageSearch';
import PageAccount from '../pages/account/PageAccount';
import PageCalendar from '../pages/calendar/PageCalendar';
import PageAdmin from '../pages/admin/PageAdmin';
import PageDashboard from '../pages/dashboard/PageDashboard';
import PageSettings from '../pages/settings/PageSettings';
import PageLogout from '../pages/logout/PageLogout';
import DefaultLayout from '../layout/DefaultLayout';

const routes = [
  {
      path: '/login',
      name: 'login',
      component: PageLogin,
      meta: {
          public: true,
          onlyWhenLoggedOut: true,
      },
  },
  {
    path: '/',
    name: 'default',
    redirect: '/home',
    component: DefaultLayout,
    children: [
        {
            path: 'home',
            name: 'home',
            component: PageHome,
            meta: {
                label: 'Accueil'
            },
        },
        {
            path: 'search',
            name: 'search',
            component: PageSearch,
            meta: {
                label: 'Recherche'
            },
        },
        {
            path: 'account/:id',
            name: 'account',
            component: PageAccount,
            meta: {
                label: 'Compagnie'
            },
        },
        {
            path: 'calendar',
            name: 'calendar',
            component: PageCalendar,
            meta: {
                label: 'Calendrier'
            },
        },
        {
            path: 'dashboard',
            name: 'dashboard',
            component: PageDashboard,
            meta: {
                label: 'Dashboard',
                coordoInt: true,
            },
        },
        {
            path: 'admin',
            name: 'admin',
            component: PageAdmin,
            meta: {
                label: 'Administration',
                admin: true,
            },
        },
        {
            path: 'settings',
            name: 'settings',
            component: PageSettings,
            meta: {
                label: 'Paramètres'
            },
        },
        {
            path: 'logout',
            name: 'logout',
            component: PageLogout,
            meta: {
                label: 'Logout'
            },
        },
      ]
  },
  {
      path: '*',
      redirect: '/login',
  },
];

export default routes;
