<template>
    <div class="page-home">
        <div class="row">
            <div class="col-md-12">
                <SummaryCard :shotguns-nb="shotgunsNb" :leads-nb="leadsNb" :calls-nb="callsNb" :meetings-nb="meetingsNb" />
            </div>
        </div>
        <div class="row">
            <div class="col-lg-3">
                <Graphic title="RDV permanence (Huit dernières semaines)" color="#18ce0f" :data="recentPermanenceMeetings"
                    :loading="permanenceLoading" :update="loadPermanenceMeetings" />
            </div>
            <div class="col-lg-3">
                <Graphic title="Appels (Huit dernières semaines)" color="#2CA8FF" :data="recentCalls" :loading="callLoading"
                    :update="loadRecentCalls" />
            </div>
            <div class="col-lg-3">
                <Graphic title="RDV (Huit dernières semaines)" color="#FF3636" :data="recentMeetings"
                    :loading="meetingLoading" :update="loadRecentMeetings" />
            </div>
            <div class="col-lg-3">
                <Calendar />
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <FileCard title="Dossiers ouverts" :loading="loading" :follow-up-modal="modals.followUpModal"
                    type="opened" />
                <FileCard title="Dossiers clos" :loading="loading" :follow-up-modal="modals.followUpModal" type="closed" />
                <AccountsTable :loading="loading" :accounts="accounts" />
            </div>
        </div>
        <FollowUpModal :follow-up-modal="modals.followUpModal" />
        <FloatingButtonGroup :buttons="[{
            icon: 'now-ui-icons ui-1_simple-add',
            color: 'success',
            tooltip: 'Ajouter une compagnie',
            onClick: addAccount
        }]" />
    </div>
</template>

<script>
import FileCard from './FileCard';
import AccountsTable from './AccountsTable';
import SummaryCard from './SummaryCard';
import Graphic from './Graphic';
import Calendar from './Calendar';

import FollowUpModal from '../../components/FollowUpWidgets/FollowUpModal';
import { mapGetters } from 'vuex';
import { getLastHeightWeeks, getFormattedDateShort } from '../../util/time.util';
import AccountService from '../../services/account.service';
import CallService from '../../services/call.service';
import MeetingService from '../../services/meeting.service';
import PermanenceService from '../../services/permanence.service';
import UserService from '../../services/user.service';
import FloatingButtonGroup from '../../components/FloatingButton/FloatingButtonGroup';

export default {
    components: {
        FileCard,
        AccountsTable,
        Calendar,
        FollowUpModal,
        SummaryCard,
        Graphic,
        FloatingButtonGroup,
    },
    computed: {
        lastHeightWeeks() {
            return getLastHeightWeeks();
        },
    },
    async created() {
        await this.$store.dispatch('files/load');
        let user = await UserService.getCurrent(`(
            accounts:(
                name
            )
        )`);
        this.accounts = user.accounts;
        this.accounts = this.accounts.sort(function (a, b) {
            let firstName = a.name ? a.name.toLowerCase() : "";
            let lastName = b.name ? b.name.toLowerCase() : "";
            return firstName.localeCompare(lastName);
        });
        this.loading = false;
        await this.loadRecentData();
    },
    watch: {
        'modals.followUpModal.display'(val) {
            if (!val) {
                this.$store.commit('files/active', null);
            }
        },
    },
    data() {
        return {
            accounts: [],
            loading: true,
            permanenceLoading: true,
            callLoading: true,
            meetingLoading: true,
            modals: {
                followUpModal: {
                    display: false,
                    form: 'lead',
                }
            },
            recentPermanenceMeetings: null,
            recentCalls: null,
            recentMeetings: null,
            shotgunsNb: 0,
            leadsNb: 0,
            callsNb: 0,
            meetingsNb: 0,
        };
    },
    methods: {
        async addAccount() {
            try {
                const newAccount = {
                    industries: [],
                    markets: []
                };
                const response = await AccountService.post(newAccount);
                const routeData = this.$router.resolve({ name: 'account', params: { id: response.id } });
                if (response && response.id) {
                    window.open(routeData.href, '_blank');
                } else {
                    console.error('Error: Invalid response format');
                }
            } catch (error) {
                console.error('Error adding account:', error);
            }
        },
        async loadRecentData() {
            const leadPromise = this.loadPermanenceMeetings();
            const callPromise = this.loadRecentCalls();
            const meetingPromise = this.loadRecentMeetings();
            const summaryPromise = this.loadSummaryData();
            let self = this;
            await Promise.all([leadPromise, callPromise, meetingPromise, summaryPromise]);
        },
        async loadSummaryData() {
            const user = await UserService.getCurrent("(stats)");
            if (user) {
                this.shotgunsNb = user.stats.shotgunCount;
                this.leadsNb = user.stats.leadCount;
                this.callsNb = user.stats.callCount;
                this.meetingsNb = user.stats.meetingCount;
            }
        },
        async loadPermanenceMeetings() {
            this.permanenceLoading = true;
            let labels = [];
            let values = [];
            const permanence = await PermanenceService.get();
            if (permanence) {
                for (let i = permanence.stats.length - 1; i >= 0; i--) {
                    labels.push(permanence.stats[i].label);
                    values.push(permanence.stats[i].meetingCount);
                }
                this.recentPermanenceMeetings = {
                    labels: labels,
                    values: values,
                };
            } else {
                this.recentPermanenceMeetings = {
                    labels: ["", "", "", "", "", "", "", ""],
                    values: [0, 0, 0, 0, 0, 0, 0, 0],
                };
            }
            this.permanenceLoading = false;
        },
        async loadRecentCalls() {
            this.callLoading = true;
            let labels = [];
            let values = [];
            for (let i = 0; i < this.lastHeightWeeks.length; i++) {
                const start = this.lastHeightWeeks[i].start;
                const end = this.lastHeightWeeks[i].end;
                labels.push(getFormattedDateShort(start) + " à " + getFormattedDateShort(end));
                const calls = await CallService.getInRange(start, end);
                values.push(calls.length);
            }
            this.recentCalls = {
                labels: labels,
                values: values,
            };
            this.callLoading = false;
        },
        async loadRecentMeetings() {
            this.meetingLoading = true;
            let labels = [];
            let values = [];
            for (let i = 0; i < this.lastHeightWeeks.length; i++) {
                const start = this.lastHeightWeeks[i].start;
                const end = this.lastHeightWeeks[i].end;
                labels.push(getFormattedDateShort(start) + " à " + getFormattedDateShort(end));
                const meetings = await MeetingService.getInRange(start, end);
                values.push(meetings.length);
            }
            this.recentMeetings = {
                labels: labels,
                values: values,
            };
            this.meetingLoading = false;
        },
    },
}

</script>

<style lang="scss" scoped>
.page-home {
    margin-top: -50px;
}

@media (min-width: 576px) {
    :deep .modal-dialog {
        max-width: 85% !important;
        margin: 1.75rem auto;
    }
}
</style>
