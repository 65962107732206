const locationOptions = [
    {
        value: "all",
        label: "Tous"
    },
    {
        value: "virtual",
        label: "Virtuel"
    },
    {
        value: "inPerson",
        label: "En personne"
    }
];

export default locationOptions;