<template>
  <SlideYUpTransition :duration="animationDuration">
    <div
      class="modal fade"
      @click.self="closeModal"
      :class="[
        { 'show d-block': show },
        { 'd-none': !show },
        { 'modal-mini': type === 'mini' },
      ]"
      v-show="show"
      tabindex="-1"
      role="dialog"
      :aria-hidden="!show"
    >
      <div
        class="modal-dialog"
        :class="[{ 'modal-notice': type === 'notice' }, modalClasses]"
      >
        <div class="modal-content">
          <div v-if="showInfo" class="modal-info">
            <slot name="info"></slot>
          </div>

          <div class="modal-header" :class="headerClasses" v-if="showHeader">
            <slot name="close-button">
              <button
                type="button"
                v-if="showClose"
                @click="closeModal"
                class="close"
                data-dismiss="modal"
                :aria-hidden="!show"
              >
                <i class="now-ui-icons ui-1_simple-remove"></i>
              </button>
            </slot>
            <slot name="header"></slot>
          </div>

          <div class="modal-body" :class="bodyClasses">
            <slot></slot>
          </div>

          <div
            class="modal-footer"
            :class="footerClasses"
            v-if="showFooter && $slots.footer"
          >
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </SlideYUpTransition>
</template>
<script>
import { SlideYUpTransition } from "vue2-transitions";

export default {
  name: "modal",
  components: {
    SlideYUpTransition,
  },
  props: {
    show: Boolean,
    showInfo: {
      type: Boolean,
      default: false,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: "",
      validator(value) {
        let acceptedValues = ["", "notice", "mini"];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    modalClasses: [Object, String],
    headerClasses: [Object, String],
    bodyClasses: [Object, String],
    footerClasses: [Object, String],
    animationDuration: {
      type: Number,
      default: 500,
    },
  },
  methods: {
    closeModal() {
      this.$emit("update:show", false);
      this.$emit("close");
    },
  },
  watch: {
    show(val) {
      let documentClasses = document.body.classList;
      if (val) {
        documentClasses.add("modal-open");
      } else {
        documentClasses.remove("modal-open");
      }
    },
  },
};
</script>
<style scoped>
.modal {
  overflow-y: auto !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.modal::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.modal {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.modal.show {
  background-color: rgba(0, 0, 0, 0.3);
}

:deep .modal-dialog {
  max-width: 60% !important;
  width: 60% !important;
  margin: 2.5rem auto;
}

@media (max-width: 950px) {
  :deep .modal-dialog {
    max-width: 100% !important;
    width: 100% !important;
    margin: 1rem auto;
  }
}
</style>
