<template>
    <div>
        <table cellspacing="0" cellpadding="0" border="0" class="w-100 custom-table">
            <thead class="has-gutter">
                <tr class="row">
                    <th v-if="mode === 'READ'" rowspan="1" colspan="1" class="col-2">Année</th>
                    <th rowspan="1" colspan="1" :class="mode === 'READ' ? 'col-3' : 'col-3'">Marchés</th>
                    <th rowspan="1" colspan="1" class="col-3">Objectifs</th>
                    <th rowspan="1" colspan="1" :class="mode === 'READ' ? 'col-4' : 'col-5'">Candidats ciblés</th>
                    <th v-if="mode === 'EDIT'" rowspan="1" colspan="1" class="col-1">
                        <el-tooltip :open-delay="100" content="Ajouter un objectif" placement="top">
                            <n-button @click.native="addGoal" type="success" round="" icon="" class="mt-0 mb-0">
                                <i class="now-ui-icons now-ui-icons ui-1_simple-add"></i>
                            </n-button>
                        </el-tooltip>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr :class="`row body ${goal.canceled ? 'archived' : null}`" v-for="(goal, index) in goals" :key="index"
                    @click="displayFileModal(goal)">
                    <td v-if="mode === 'READ'" rowspan="1" colspan="1" class="col-2">
                        <!-- <YearSelector :value="goal.year" v-model="goal.year" @input="updateGoal(index)"
                        :disabled="mode === 'READ'" /> -->
                        {{ goal.year }}
                    </td>
                    <!-- Marchés -->
                    <td rowspan="1" colspan="1" :class="mode === 'READ' ? 'col-3' : 'col-3'">
                        <el-select v-if="mode === 'EDIT'" :disabled="goal.canceled" clearable @input="updateGoal(index)"
                            class="select-transparent" collapse-tags="" v-model="goal.marketId"
                            placeholder="Marchés visés">
                            <el-option v-for="market in markets" class="select-primary" :value="market.id"
                                :label="market.name" :key="market.id">
                            </el-option>
                        </el-select>
                        <span v-else>{{ goal.marketId | marketName(markets) }}</span>
                    </td>
                    <!-- Objectifs -->
                    <td rowspan="1" colspan="1" class="col-3">
                        <el-select v-if="mode === 'EDIT'" :disabled="goal.canceled" @input="updateGoal(index)"
                            class="select-transparent" collapse-tags="" v-model="goal.name" placeholder="Candidat">
                            <el-option v-for="objective in missionObjectives" class="select-primary" :value="objective"
                                :label="objective" :key="objective">
                            </el-option>
                        </el-select>
                        <span v-else>{{ goal.name }}</span>
                    </td>
                    <!-- Candidats ciblés -->
                    <td rowspan="1" colspan="1" :class="mode === 'READ' ? 'col-4' : 'col-5'">
                        <el-select clearable filterable :disabled="goal.canceled"
                            v-if="goal.name === 'Recrutement' && mode === 'EDIT'" @input="updateGoal(index)"
                            class="select-transparent" multiple="" autocomplete="" collapse-tags=""
                            v-model="goal.candidats" placeholder="Candidat">
                            <el-option v-for="targetedCandidate in targetedCandidates" class="select-primary"
                                :value="targetedCandidate" :label="targetedCandidate" :key="targetedCandidate">
                            </el-option>
                        </el-select>
                        <div v-else>
                            <span class="d-block" v-for="candidat in goal.candidats">
                                {{ candidat }}
                            </span>
                        </div>
                    </td>
                    <td v-if="mode === 'EDIT'" rowspan="1" colspan="1" class="col-1">
                        <el-tooltip :open-delay="100" :content="`${goal.canceled ? 'Activer' : 'Archiver'} un objectif`"
                            placement="top">
                            <n-button @click.native.stop="deleteGoal(goal)" :type="`${goal.canceled ? '' : 'warning'}`"
                                round="" icon="" class="mt-0 mb-0">
                                <i
                                    :class="`now-ui-icons ${goal.canceled ? 'arrows-1_refresh-69' : 'ui-1_simple-remove'}`"></i>
                            </n-button>
                        </el-tooltip>
                    </td>
                </tr>
            </tbody>
        </table>
        <confirm-modal ref="confirmModal"></confirm-modal>
    </div>
</template>
<script>
import { missionObjectives } from '../util/objectives.util';
import targetedCandidates from '../util/targeted.candidates.utils'
import YearSelector from './YearSelector';
import eventBus from '../util/eventBus';
import { Select, Option, Tooltip } from 'element-ui'
import { mapGetters } from 'vuex';
import { ConfirmModal } from 'src/components';

export default {
    name: 'goal',
    components: {
        [Select.name]: Select,
        [Option.name]: Option,
        [Tooltip.name]: Tooltip,
        YearSelector,
        ConfirmModal
    },
    props: {
        mode: {
            default: 'READ',
            type: String
        },
        accountMarkets: {
            type: Array
        },
        canDisplayFileModal: {
            default: false,
            type: Boolean
        }
    },
    data() {
        return {
            targetedCandidates,
            missionObjectives,
        }
    },
    computed: {
        ...mapGetters({
            file: 'files/active',
            currentFileMarkets: 'files/destinations',
            accountGoals: 'goals/accountGoals',
            fileGoals: 'goals/fileGoals',
        }),
        markets() {
            return this.accountMarkets ?? this.currentFileMarkets
        },
        goals() {
            return this.mode === "EDIT" ? this.fileGoals : this.accountGoals
        },
        fileId() {
            return this.file.id
        },
        year() {
            return this.file.year
        }
    },
    methods: {
        async addGoal() {
            let goal = {
                name: this.missionObjectives[0],
                marketId: "",
                fileId: this.fileId,
                candidats: [],
                year: this.year
            }
            this.$store.dispatch('goals/addGoal', { goal, fileId: this.fileId })
        },
        async updateGoal(index) {
            const goal = this.goals[index]
            const goalId = goal.goalId
            if (goal.name !== 'Recrutement')
                goal.candidats = []
            goal.year = this.year
            this.$store.dispatch('goals/updateGoal', { goal, fileId: this.fileId, goalId })
        },
        async deleteGoal(goal) {
            const confirmation = await this.$refs.confirmModal.show({
                title: `${goal.canceled ? 'Activer' : 'Archiver'} un objectif.`,
                message: `Êtes-vous sûr de vouloir ${goal.canceled ? 'activer' : 'archiver'} cet objectif ?`,
                okButton: `${goal.canceled ? 'Activer' : 'Archiver'}`,
                type: `${goal.canceled ? 'success' : 'danger'}`,
            })
            if (!confirmation)
                return
            const goalId = goal.goalId
            this.$store.dispatch('goals/deleteGoal', { fileId: this.fileId, goalId })

        },
        displayFileModal(goal) {
            if (!this.canDisplayFileModal)
                return

            eventBus.$emit('displayFileModal', goal.fileId)
        },
    },
    filters: {
        marketName: (marketId, markets) => {
            const market = markets.find(market => (market.id === marketId))
            return market ? market.name : ""
        }
    }
}

</script>
<style lang="scss" scoped>
@import "assets/sass/select-transparent.scss";

:deep .el-tag {
    width: 50% !important;
}

:deep .el-tag~.el-tag {
    width: 10% !important;
    margin-right: 0px !important;
}

.archived {
    background-color: rgba(0, 0, 0, 0.05);
}


.custom-table {
    tr {
        margin: 0;
        transition: background-color 200ms ease-in-out;

        &.body:hover {
            background-color: rgba(0, 0, 0, .05);
            cursor: pointer;
        }
    }

    th,
    td {
        border-bottom: 1px solid #EBEEF5;
        padding: 12px 17px;
        box-sizing: border-box;
        text-overflow: ellipsis;
        text-align: center !important;
        min-width: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    th {
        font-size: 1.1em;
        font-weight: 300;
        overflow: hidden;
        color: #f96332 !important;
    }

    td {
        font-size: 1em;
    }
}
</style>