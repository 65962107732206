import ApiService from './api.service';

class CallService {

    constructor() {
        this.basePath = "calls";
        this.fullSelector = `(
            name,
            description,
            selectedOutcomes,
            dateStart,
            destinations:(
                name,
                canceled,
                fileId
            ),
            hasBeenCalled,
            doneBy,
            fileId
        )`;
    }

    async getSummary(year) {
        try {
            const response = await ApiService.get(`${this.basePath}/summary?year=${year}`);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    }

    async getAll(callIds) {
        try {
            const response = await ApiService.get(`${this.basePath}?callIds=${callIds.join()}&selector=${this.fullSelector.replace(/\s/g, '')}`);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    }

    async getInRange(startDate, endDate) {
        try {
            const response = await ApiService.get(`${this.basePath}/inRange?keepDuplicate=false&startDate=${startDate}&endDate=${endDate}&selector=())`);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    }

    async put(data) {
        try {
            const response = await ApiService.put(`${this.basePath}?selector=${this.fullSelector}`, data);
            return response.data;
        } catch (e) {
            throw e;
        }
    }

    async post(call) {
        try {
            const response = await ApiService.post(`${this.basePath}?selector=${this.fullSelector.replace(/\s/g, '')}`, call);
            return response.data;
        } catch (e) {
            throw e;
        }
    }

    async delete(callId) {
        try {
            await ApiService.delete(`${this.basePath}/${callId}`);
        } catch (e) {
            throw e;
        }
    }
}

export default new CallService();