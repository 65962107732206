import Vue from 'vue';
import VueRouter from 'vue-router';
import DashboardPlugin from './dashboard-plugin';
import underscore from 'vue-underscore';
import moment from 'moment';
import store from './store';

// Set locale
moment.locale('fr');

// Plugins
import App from './App.vue';

// router setup
import router from './routes/router';

// plugin setup
Vue.use(underscore);
Vue.use(VueRouter);
Vue.use(DashboardPlugin);

/* eslint-disable no-new */
new Vue({
  el: '#app',
  store,
  render: h => h(App),
  router
});
