<template>
    <card class="card-calender" no-footer-line>

        <div slot="header">
            <h5 class="card-category">calendrier</h5>
            <h2 class="card-title">
                <animated-number :value="count"></animated-number>
            </h2>
        </div>
        <div class="chart-area">
            <FullCalendar v-if="!loading" :options="calendarOptions" />
            <Spinner size="4" v-if="loading" />
        </div>
        <div slot="footer" class="stats">
            <i class="refresh now-ui-icons arrows-1_refresh-69" @click="loadMyEvents"></i> Mettre à jour
        </div>
        <MeetingModal :modal="meetingModal" @reload="loadMyEvents" />
    </card>
</template>
    
<script>
import {
    Card,
    AnimatedNumber,
    Spinner
} from 'src/components';


import MeetingModal from '../calendar/MeetingModal';
import FullCalendar from '@fullcalendar/vue';
import frLocale from '@fullcalendar/core/locales/fr';
import EventService from '../../services/event.service';
import dayGridPlugin from '@fullcalendar/daygrid';
import SocketService from '../../services/socket.service';


export default {
    components: {
        FullCalendar,
        MeetingModal,
        Card,
        AnimatedNumber,
        Spinner
    },
    async created() {
        this.loading = true;
        await this.loadMyEvents()
        SocketService.addListener('meetingAdded', this.loadMyEvents);
        SocketService.addListener('meetingDeleted', this.loadMyEvents);
        SocketService.addListener('meetingUpdated', this.loadMyEvents);
        SocketService.addListener('fileDeleted', this.loadMyEvents);
        this.loading = false;
    },
    destroyed() {
        SocketService.removeListener('meetingAdded', this.loadMyEvents);
        SocketService.removeListener('meetingDeleted', this.loadMyEvents);
        SocketService.removeListener('meetingUpdated', this.loadMyEvents);
        SocketService.removeListener('fileDeleted', this.loadMyEvents);
    },
    computed: {
        count() {
            if (this.loading) return 0;
            return this.calendarOptions?.events?.length;
        }
    },
    data() {
        return {
            calendarOptions: {
                plugins: [
                    dayGridPlugin,
                ],
                headerToolbar: {
                    left: "prev,next today",
                    center: "",
                    right: 'title'
                },
                initialView: "dayGrid",
                editable: true,
                selectable: true,
                selectMirror: true,
                dayMaxEvents: true,
                weekends: false,
                events: [],
                eventClick: this.handleEventClick,
                locale: frLocale,
            },
            loading: false,
            meetingModal: {
                display: false,
                meetingId: null,
            }
        }
    },
    methods: {
        handleEventClick(arg) {
            this.meetingModal.meetingId = arg.event.extendedProps.meetingId;
            this.meetingModal.display = true;
        },
        async loadMyEvents() {
            this.loading = true
            const events = await EventService.getAllUserMeetings();
            this.calendarOptions.events = events?.map(e => {
                const bg = `bg${e.backgroundColor?.includes('#') ? e.backgroundColor.split('#')[1] : e.backgroundColor}`
                return { ...e, classNames: [bg, ...e.classNames] }
            })
            this.loading = false
        },
    },
}
</script>
    
<style lang="scss" scoped>
@import "assets/sass/calander-event.scss";

.card-calender {
    height: 95%;
    display: flex;

    .refresh {
        cursor: pointer;
    }
}

.stats {
    color: #9A9A9A;
    ;
}

.fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 0.5em;
}


:deep(.fc .fc-toolbar-title) {
    font-size: 1em !important;
}

:deep(.fc-toolbar-chunk) {
    font-size: 0.75em !important;
}
</style>
    