import TokenService from './token.service';
import apiConfig from '../config/api.config';
import axios from 'axios';
import qs from 'querystring';
import router from '../routes/router';

class ApiService {

    constructor() {
        this._401Interceptor = null;
        axios.defaults.baseURL = apiConfig.apiUrl;
        this.setHeader();
        this.mount401Interceptor();
    }

    setHeader() {
        axios.defaults.headers.common["Authorization"] = `Bearer ${TokenService.getToken()}`;
        axios.defaults.headers.common["Accept"] = "application/json";
        axios.defaults.headers.common["Content-Type"] = "application/json;charset=utf-8";
    }

    removeHeader() {
        axios.defaults.headers.common = {};
    }

    async get(resource, params) {
        return await axios.get(resource, params);
    }

    async post(resource, data) {
        return await axios.post(resource.replace(/\s/g, ''), data);
    }

    async put(resource, data) {
        return await axios.put(resource.replace(/\s/g, ''), data);
    }

    async delete(resource) {
        return await axios.delete(resource);
    }

    unmount401Interceptor() {
        axios.interceptors.response.eject(this._401Interceptor);
    }

    mount401Interceptor() {
        const self = this;
        this._401Interceptor = axios.interceptors.response.use(
            (response) => {
                return response;
            },
            async (error) => {
                if (error.response && error.response.status === 401 && !router.app._route.meta.public) {
                    try {
                        self.unmount401Interceptor();
                        const refreshResponse = await self.post("/authentication/refreshToken", {
                            "access_token": TokenService.getToken(),
                            "refresh_token": TokenService.getRefreshToken()
                        });
                        TokenService.saveToken(refreshResponse.data.access_token);
                        TokenService.saveRefreshToken(refreshResponse.data.refresh_token);
                        self.setHeader();
                        let params = "";
                        if (error.config.params) {
                            params = "?" + qs.stringify(error.config.params);
                        }
                        return await self.customRequest({
                            method: error.config.method,
                            url: error.config.url + params,
                            data: error.config.data,
                            responseType: error.config.responseType,
                            cancelToken: error.config.cancelToken,
                            headers: {
                                'Accept': 'application/json',
                                "Content-Type": "application/json;charset=utf-8"
                            },
                        });
                    } catch (e) {
                        self.removeHeader();
                        TokenService.removeToken();
                        TokenService.removeRefreshToken();
                        router.push({
                            path: '/login',
                            query: {
                                redirect: router.currentRoute.fullPath,
                            }
                        });
                    } finally {
                        self.mount401Interceptor();
                    }
                } else {
                   throw error; 
                }
            }
        );
    }

    async customRequest(data) {
        return await axios(data);
    }
}

export default new ApiService();