<template>
  <div class="page-calendar">
    <div class="container-fluid">
      <div class="row d-flex justify-content-center">
        <div class="col-lg-10">
          <card class="card-calendar">
            <tabs type="primary" v-model="activeTab">
              <tab-pane label="Calendrier global"></tab-pane>
              <tab-pane label="Mon calendrier"></tab-pane>
            </tabs>
            <Spinner size="4" v-if="loading" />
            <div class="mt-4">
              <h6>Légende:</h6>
              <div class="row">
                <div
                  class="col-12 col-md-6 col-lg-3"
                  v-for="(caption, index) in captions"
                  :key="index"
                >
                  <span
                    class="caption-color mr-2"
                    v-bind:style="{ background: caption.color }"
                  ></span>
                  <span>{{ caption.label }}</span>
                </div>
              </div>
            </div>
            <div class="mt-4">
              <h6>Filtres:</h6>
              <div class="row" style="margin-bottom: 5px">
                <n-checkbox v-model="intPartnerFilter"
                  >Besoin d'un accompagnateur COMMERCIAL
                </n-checkbox>
                <n-checkbox v-model="canPartnerFilter"
                  >Besoin d'un accompagnateur RECRUTEMENT</n-checkbox
                >
                <n-checkbox v-model="accompaniedMeetingsFilter"
                  >RDV accompagnés</n-checkbox
                >
                <n-checkbox v-model="toBeTakenMeetingsFilter">RDV à récupérer</n-checkbox>
                <n-checkbox v-model="showPassedMeetingsFilter"
                  >RDV passés (12 mois)</n-checkbox
                >
              </div>
              <div class="row" style="margin-bottom: 5px; margin-left: 8px">
                <div>
                  <label>Type de rencontre</label>
                  <MeetingLocationSelector type="search" v-model="eventLocationFilter" />
                </div>
              </div>
              <div class="mt-4">
                <FullCalendar v-if="!loading" :options="calendarOptions" />
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
    <MeetingModal :modal="meetingModal" @reload="loadEvents" />
  </div>
</template>

<script>
import { isDateInLast6Month } from "../../util/time.util";
import MeetingModal from "./MeetingModal";
import FullCalendar from "@fullcalendar/vue";
import frLocale from "@fullcalendar/core/locales/fr";
import EventService from "../../services/event.service";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import { Card, Spinner, Tabs, TabPane, Checkbox } from "src/components";
import SocketService from "../../services/socket.service";
import DelegationService from "../../services/delegation.service";
import TokenService from "../../services/token.service";
import MeetingLocationSelector from "../../components/MeetingLocationSelector.vue";

export default {
  async created() {
    this.loading = true;
    await Promise.all([this.loadDelegations(), this.loadEvents()]);
    SocketService.addListener("meetingAdded", this.loadEvents);
    SocketService.addListener("meetingDeleted", this.loadEvents);
    SocketService.addListener("meetingUpdated", this.loadEvents);
    SocketService.addListener("fileDeleted", this.loadEvents);

    const isAgentCan = TokenService.isAgentCan();
    const isAgentInt = TokenService.isAgentInt();
    const isCoordoCan = TokenService.isCoordoCan();
    const isCoordoInt = TokenService.isCoordoInt();
    const isAdmin = TokenService.isAdmin();
    if (isAgentCan || isCoordoCan) {
      this.intPartnerFilter = false;
      this.canPartnerFilter = true;
    } else if (isAgentInt || isCoordoInt) {
      this.intPartnerFilter = true;
      this.canPartnerFilter = false;
    } else if (isAdmin) {
      this.intPartnerFilter = true;
      this.canPartnerFilter = true;
    }

    this.loading = false;
  },
  destroyed() {
    SocketService.removeListener("meetingAdded", this.loadEvents);
    SocketService.removeListener("meetingDeleted", this.loadEvents);
    SocketService.removeListener("meetingUpdated", this.loadEvents);
    SocketService.removeListener("fileDeleted", this.loadEvents);
  },
  components: {
    FullCalendar,
    Card,
    MeetingModal,
    Spinner,
    Tabs,
    TabPane,
    [Checkbox.name]: Checkbox,
    MeetingLocationSelector,
  },
  computed: {
    events() {
      if (this.activeTab === "Mon calendrier") {
        return this.myEvents;
      } else {
        let events = [];
        let notAccompaniedEvents = this.notAccompaniedEvents ?? [];
        if (this.accompaniedMeetingsFilter) {
          events = this.accompaniedEvents ?? [];
        }
        if (this.canPartnerFilter) {
          events = [
            ...events,
            ...notAccompaniedEvents?.filter((event) => {
              return event.type === "CAN";
            }),
          ];
        }
        if (this.intPartnerFilter) {
          events = [
            ...events,
            ...notAccompaniedEvents?.filter((event) => {
              return event.type === "INT";
            }),
          ];
        }
        if (!this.showPassedMeetingsFilter) {
          events = events.filter((event) => {
            return event.date > Date.now();
          });
        }

        if (this.toBeTakenMeetingsFilter) {
          events = events.filter((event) => {
            return event.title === "À récupérer";
          });
        }

        switch (this.eventLocationFilter) {
          case "inPerson":
            return events.filter((event) => {
              return event.virtual !== true; // using !true to include null and undefined
            });
          case "virtual":
            return events.filter((event) => {
              return event.virtual === true;
            });
          default:
            return events;
        }
      }
    },
    captions() {
      if (this.activeTab === "Mon calendrier") {
        return [
          {
            label: "Mes RDV",
            color: "#f96332",
          },
          {
            label: "RDV que j'accompagne",
            color: "#2ca8ff",
          },
          {
            label: "Annulé",
            color: "#ee36ff",
          },
        ];
      }
      if (this.delegations.length === 0) {
        return [
          {
            label: "...",
            color: "#28a745",
          },
          {
            label: "...",
            color: "#17a2b8",
          },
          {
            label: "...",
            color: "#ffc107",
          },
          {
            label: "...",
            color: "red",
          },
        ];
      }
      return [
        {
          label: this.$_.find(
            this.delegations,
            (e) => e.id === "5b4e50f9e7179a508a8d2c4a"
          ).name,
          color: "#28a745",
        },
        {
          label: this.$_.find(
            this.delegations,
            (e) => e.id === "5b4e5fa5e7179a508a8d3a99"
          ).name,
          color: "#17a2b8",
        },
        {
          label: this.$_.find(
            this.delegations,
            (e) => e.id === "5b4e5faee7179a508a8d3a9d"
          ).name,
          color: "#ffc107",
        },
        {
          label: this.$_.find(
            this.delegations,
            (e) => e.id === "601669c330632a994b0588a6"
          ).name,
          color: "red",
        },
      ];
    },
  },
  watch: {
    events(newEvents) {
      this.calendarOptions.events = newEvents;
      this.calendarOptions.events = newEvents.map((e) => {
        const bg = `bg${
          e.backgroundColor?.includes("#")
            ? e.backgroundColor.split("#")[1]
            : e.backgroundColor
        }`;
        return { ...e, classNames: [bg, ...e.classNames] };
      });
    },
  },
  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, timeGridPlugin],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        },
        initialView: "dayGridMonth",
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: false,
        events: [],
        eventClick: this.handleEventClick,
        locale: frLocale,
      },
      activeTab: "Calendrier global",
      frLocale: frLocale,
      loading: false,
      notAccompaniedEvents: [],
      accompaniedEvents: [],
      myEvents: [],
      meetingModal: {
        display: false,
        meetingId: null,
      },
      delegations: [],
      intPartnerFilter: false,
      canPartnerFilter: false,
      accompaniedMeetingsFilter: false,
      showPassedMeetingsFilter: true,
      toBeTakenMeetingsFilter: false,
      eventLocationFilter: "all",
      isCoordoCan: false,
      isCoordoInt: false,
      isAdmin: false,
    };
  },
  methods: {
    handleEventClick(arg) {
      this.meetingModal.meetingId = arg.event.extendedProps.meetingId;
      this.meetingModal.display = true;
    },
    async loadDelegations() {
      this.delegations = await DelegationService.getAll();
    },
    async loadEvents() {
      await Promise.all([
        this.loadAccompaniedEvents(),
        this.loadNotAccompaniedEvents(),
        this.loadMyEvents(),
      ]);
    },
    async loadAccompaniedEvents() {
      const date = new Date();
      date.setMonth(date.getMonth() - 11);
      date.setDate(1);
      this.notAccompaniedEvents = await EventService.getMeetingsNotAccompanied(
        `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`
      );
    },
    async loadNotAccompaniedEvents() {
      const date = new Date();
      date.setMonth(date.getMonth() - 11);
      date.setDate(1);
      this.accompaniedEvents = await EventService.getMeetingsAccompanied(
        `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`
      );
    },
    async loadMyEvents() {
      this.myEvents = await EventService.getAllUserMeetings();
    },
  },
};
</script>

<style lang="scss">
// @import '~@fullcalendar/core/main.css';
// @import '~@fullcalendar/daygrid/main.css';
@import "assets/sass/calander-event.scss";

.page-calendar {
  .fc-right {
    display: flex;
    align-items: center;

    .fc-today-button {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .tab-content.tab-space {
    padding-top: 0;
    padding-bottom: 0;
  }

  .caption-color {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    display: inline-block;
  }

  .form-check {
    padding-left: 1.25rem !important;
  }
}
</style>
