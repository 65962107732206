import FileService from '../../services/file.service';
import AccountService from '../../services/account.service';
import { _ } from 'vue-underscore';
import Vue from 'vue';
import { getEnding } from '../../util/outcomes.util';
import { getCurrentYear } from '../../util/time.util';

const state = {
    all: {},
    active: null,
    destinations: [],
    accountDestinations: [],
    accountContacts: []
};

// synchronous operations on state.
const mutations = {
    reset(state) {
        state.all = {};
        state.active = null;
    },
    active(state, active) {
        state.active = active
    },
    all(state, files) {
        for (let i = 0; i < files.length; i++) {
            Vue.set(state.all, files[i].id, files[i]);
        }
    },
    add(state, file) {
        Vue.set(state.all, file.id, file);
    },
    remove(state, fileId) {
        Vue.delete(state.all, fileId);
    },
    update(state, file) {
        state.all[file.id] = file;
    },
    addNotSignedReason(state, reason) {
        const active = state.active ? state.all[state.active] : null;
        if (active && getEnding(reason)) {
            active.notSignedReasons.push(reason);
        }
    },
    removeNotSignedReason(state, reason) {
        const active = state.active ? state.all[state.active] : null;
        if (active) {
            const index = active.notSignedReasons.indexOf(reason);
            if (index !== -1) active.notSignedReasons.splice(index, 1);
        }
    },
    clearDestinations(state) {
        state.destinations = []
    },
    addDestinations(state, destinations) {
        state.destinations.push(...[...destinations])
    },
    addDestination(state, destination) {
        state.destinations.push(destination)
    },
    removeDestination(state, id) {
        state.destinations = state.destinations.filter(D => (D.id !== id))
    },
    // accountDestinations
    addAccountDestinations(state, destinations) {
        state.accountDestinations = [...destinations]
    },
    addAccountDestination(state, destination) {
        state.accountDestinations.push(destination)
    },
    removeAccountDestination(state, id) {
        state.accountDestinations = state.accountDestinations.filter(D => (D.id !== id))
    },
    // contact
    addAccountContacts(state, contacts) {
        state.accountContacts = [...contacts]
    },
    addAccountContact(state, contact) {
        state.accountContacts.push(contact)
    },
    removeAccountContacts(state, id) {
        state.accountContacts = state.accountContacts.filter(C => (C.id !== id))
    },
    updateAccountContacts(state, payload) {
        const index = state.accountContacts.findIndex(C => (C.id === payload.id))
        if (index > -1) {
            state.accountContacts[index][payload.attribute] = payload.value
        }
    }
};

// asynchronous operations to backend (then use mutations to update the state).
const actions = {
    async load({ commit }) {
        commit('reset');
        const files = await FileService.get(getCurrentYear());
        for (let file of files) commit('add', file);
    },
    async loadUser({ commit }, userId) {
        commit('reset');
        const files = await FileService.getByUserId(getCurrentYear(), userId);
        files.sort((a, b) => {
            if (a.account.name < b.account.name) {
                return -1;
            }
            if (a.account.name > b.account.name) {
                return 1;
            }
            return 0;
        });
        for (let file of files) commit('add', file);
    },
    async loadContacts({ commit }, accountId) {
        const selector = '(contacts:(name,email,title,city,mobilePhone,officePhone,stillWorkingThere,reasonLeaving,linkedIn,hasBeenCalled,isAlumniMCUL,newsletter,concerns,hierarchyRole))'
        const response = await AccountService.get(accountId, selector)
        if (response?.contacts) {
            commit("addAccountContacts", response.contacts)
        }
    }
};

const getters = {
    all(state) {
        return Object.values(state.all);
    },
    opened(state) {
        return _.filter(Object.values(state.all), function (el) {
            return el.notSignedReasons.length === 0 && !(el.mandateIds && el.mandateIds.length > 0);
        });
    },
    closed(state) {
        return _.filter(Object.values(state.all), function (el) {
            return el.notSignedReasons.length > 0 || (el.mandateIds && el.mandateIds.length > 0);
        });
    },
    active(state) {
        return state.active ? state.all[state.active] : null;
    },
    totalShotguns(_, getters) {
        const all = getters.all;
        return all.length;
    },
    totalLeads(_, getters) {
        let count = 0;
        const all = getters.all;
        for (let i = 0; i < all.length; i++) {
            count += all[i].leadIds.length;
        }
        return count;
    },
    totalCalls(_, getters) {
        let count = 0;
        const all = getters.all;
        for (let i = 0; i < all.length; i++) {
            count += all[i].callIds.length;
        }
        return count;
    },
    totalMeetings(_, getters) {
        let count = 0;
        const all = getters.all;
        for (let i = 0; i < all.length; i++) {
            count += all[i].meetingIds.length;
        }
        return count;
    },
    allLeadIds(_, getters) {
        let result = [];
        const all = getters.all;
        for (let i = 0; i < all.length; i++) {
            result = result.concat(all[i].leadIds);
        }
        return result;
    },
    allCallIds(_, getters) {
        let result = [];
        const all = getters.all;
        for (let i = 0; i < all.length; i++) {
            result = result.concat(all[i].callIds);
        }
        return result;
    },
    allMeetingIds(_, getters) {
        let result = [];
        const all = getters.all;
        for (let i = 0; i < all.length; i++) {
            result = result.concat(all[i].meetingIds);
        }
        return result;
    },
    destinations(state) {
        return state.destinations
    },
    accountDestinations(state) {
        return state.accountDestinations
    },
    // contact
    accountContacts(state) {
        return state.accountContacts
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};