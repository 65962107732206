<template>
    <div>
        <label class="select-magin-page">Page</label>
        <el-select class="select-transparent mr-6 select-page select-magin-page" collapse-tags="" placeholder="Page"
            :value="pageValue" @change="pageChanged">
            <el-option v-for="page in pageCount" lass="select-primary" :value="page" :label="page" :key="page"></el-option>
        </el-select>
        <label class="select-magin-page align-middle">Records</label>
        <el-select class="select-transparent select-page select-magin-page" collapse-tags="" placeholder="Records"
            v-model="size" @change="updateRecherche">
            <el-option v-for="option in choices" class="select-primary" :value="option" :label="option" :key="option">
            </el-option>
        </el-select>
    </div>
</template>
<script>
import { Select, Option } from 'element-ui';
export default {
    name: 'n-pagination-params',
    components: {
        [Select.name]: Select,
        [Option.name]: Option,
    },
    props: {
        query: Object,
        pageCount: Number,
        pageValue: Number,
        pageChanged: Function,
        search: Function
    },
    computed: {
    },
    data() {
        return {
            choices: [30, 50, 70, 90, 100, 120],
            size: this.query.size
        };
    },
    methods: {
        async updateRecherche(newValue) {
            this.query.size = newValue;
            this.search(false, true);
        },
    }
}
</script>
