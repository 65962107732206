const countriesRegionsList = [
	"Amérique du Nord",
	"Amérique du Sud",
    "Amérique centrale",
    "Antarctique",
	"Europe",
	"Asie",
	"Afrique",
	"Océanie",
    "Afghanistan",
    "Albanie",
    "Algérie",
    "Samoa américaine",
    "Andorre",
    "Angola",
    "Anguilla",
    "Antigua et Barbuda",
    "Argentine",
    "Arménie",
    "Aruba",
    "Australie",
    "Autriche",
    "Azerbaidjan",
    "Bahamas",
    "Bahrein",
    "Bangladesh",
    "Barbade",
    "Bielorussie",
    "Belgique",
    "Belize",
    "Bénin",
    "Bermudes",
    "Bhoutan",
    "Bolivie",
    "Bosnie-Herzégovine",
    "Botswana",
    "Île Bouvet",
    "Brésil",
    "Océan Indien Britannique",
    "Brunei Darussalam",
    "Bulgarie",
    "Burkina Faso",
    "Burundi",
    "Cambodge",
    "Cameroun",
    "Canada",
    "Cap-Vert",
    "Caïmanes",
    "Centrafricaine, République",
    "Tchad",
    "Chili",
    "Chine",
    "Île Christmas",
    "Cocos",
    "Colombie",
    "Comores",
    "Congo, République populaire",
    "Congo, République démocratique",
    "Îles Cook",
    "Costa Rica",
    "Côte-d'Ivoire",
    "Croatie",
    "Cuba",
    "Chypre",
    "République tchèque",
    "Danemark",
    "Djibouti",
    "Dominique",
    "République Dominicaine",
    "Équateur",
    "Égypte",
    "El Salvador",
    "Guinée équatoriale",
    "Érythrée",
    "Estonie",
    "Éthiopie",
    "Îles Malouines",
    "Îles Féroé",
    "Fidji",
    "Finlande",
    "France",
    "Guyane française",
    "Polynésie française",
    "Terres australes françaises",
    "Gabon",
    "Gambie",
    "Géorgie",
    "Allemagne",
    "Ghana",
    "Gibraltar",
    "Grèce",
    "Groenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guinée",
    "Guinée-Bissau",
    "Guyana",
    "Haïti",
    "Îles Heard-et-MacDonald",
    "Saint-Siège",
    "Honduras",
    "Hong Kong",
    "Hongrie",
    "Islande",
    "Inde",
    "Indonésie",
    "Iran",
    "Irak",
    "Irlande",
    "Israël",
    "Italie",
    "Jamaïque",
    "Japon",
    "Jordanie",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Corée du Nord, République populaire démocratique",
    "Corée du Sud, République",
    "Koweit",
    "Kirghistan",
    "Laos",
    "Lettonie",
    "Liban",
    "Lesotho",
    "Libéria",
    "Libye",
    "Liechtenstein",
    "Lituanie",
    "Luxembourg, Grand-Duché",
    "Macao",
    "Macédoine, Ex-République yougoslave",
    "Madagascar",
    "Malawi",
    "Malaisie",
    "Maldives",
    "Mali",
    "Malte",
    "Îles Marshall",
    "Martinique",
    "Mauritanie",
    "Maurice",
    "Mayotte",
    "Mexique",
    "Micronésie",
    "Moldavie",
    "Monaco",
    "Mongolie",
    "Montserrat",
    "Maroc",
    "Mozambique",
    "Myanmar",
    "Namibie",
    "Nauru",
    "Népal",
    "Pays-Bas",
    "Nouvelle-Calédonie",
    "Nouvelle-Zélande",
    "Nicaragua",
    "Niger",
    "Nigéria",
    "Niué",
    "Île Norfolk",
    "Mariannes du Nord",
    "Norvège",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine",
    "Panama",
    "Papouasie-Nouvelle-Guinée",
    "Paraguay",
    "Pérou",
    "Philippines",
    "Pitcairn",
    "Pologne",
    "Portugal",
    "Porto Rico",
    "Qatar",
    "Réunion",
    "Roumanie",
    "Russie",
    "Rwanda",
    "Sainte-Hélène",
    "Saint-Christophe-et-Niévès",
    "Sainte-Lucie",
    "Saint Pierre and Miquelon",
    "Saint-Vincent et les Grenadines",
    "Samoa",
    "Saint-Marin",
    "São Tomé et Principe",
    "Arabie Saoudite",
    "Sénégal",
    "Seychelles",
    "Sierra Leone",
    "Singapour",
    "Slovaquie",
    "Slovénie",
    "Salomon",
    "Somalie",
    "Afrique du Sud",
    "Géorgie du Sud-et-les Îles Sandwich du Sud",
    "Espagne",
    "Sri Lanka",
    "Soudan",
    "Suriname",
    "Svalbard et Île Jan Mayen",
    "Ngwane, Royaume du Swaziland",
    "Suède",
    "Suisse",
    "Syrie",
    "Taïwan",
    "Tadjikistan",
    "Tanzanie, République unie",
    "Thaïlande",
    "Timor Leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad et Tobago",
    "Tunisie",
    "Turquie",
    "Turkménistan",
    "Îles Turques-et-Caïques",
    "Tuvalu",
    "Ouganda",
    "Ukraine",
    "Émirats arabes unis",
    "Royaume-Uni",
    "États-Unis d'Amérique",
    "Îles mineures éloignées des États-Unis",
    "Uruguay",
    "Ouzbékistan",
    "Vanuatu",
    "Venezuela",
    "Vietnam",
    "Îles vierges britanniques",
    "Îles vierges américaines",
    "Wallis et Futuna",
    "Sahara occidental",
    "Yémen",
    "Zambie",
    "Zimbabwe",
    "Åland",
    "Bonaire, Saint-Eustache et Saba",
    "Curaçao",
    "Guernesey",
    "Île de Man",
    "Jersey",
    "Monténégro",
    "Saint-Barthélemy",
    "Saint-Martin (partie française)",
    "Serbie",
    "Saint-Martin (partie néerlandaise)",
    "Sud-Soudan",
    "Kosovo"
];

export default countriesRegionsList;