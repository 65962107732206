import GoalService from "../../services/goal.service"
import FileService from "../../services/file.service"

const state = {
    accountGoals: [],
    fileGoals: []
};

const mutations = {
    //acount
    addAccountGoals(state, goals) {
        state.accountGoals = [...goals].sort(G => (G.canceled ? 1 : -1))
    },
    addAccountGoal(state, goal) {
        state.accountGoals.push(goal)
    },
    updateAccountGoal(state, goal) {
        const index = state.accountGoals.findIndex(G => (G.goalId === goal.goalId))
        if (index > -1) {
            state.accountGoals[index] = { ...goal }
            state.accountGoals = [...state.accountGoals]
        }
    },
    removeAccountGoal(state, goalId) {
        const index = state.accountGoals.findIndex(G => (G.goalId === goalId))
        if (index > -1) {
            const goal = state.accountGoals[index]
            state.accountGoals[index] = { ...goal, canceled: !goal.canceled }
            state.accountGoals = [...state.accountGoals]
        }
    },
    // file
    addFileGoals(state, goals) {
        state.fileGoals = [...goals].sort(G => (G.canceled ? 1 : -1))
    },
    addFileGoal(state, goal) {
        state.fileGoals.push(goal)
    },
    updateFileGoal(state, goal) {
        const index = state.fileGoals.findIndex(G => (G.goalId === goal.goalId))
        if (index > -1) {
            state.fileGoals[index] = { ...goal }
            state.fileGoals = [...state.fileGoals]
        }
    },
    removeFileGoal(state, goalId) {
        const index = state.fileGoals.findIndex(G => (G.goalId === goalId))
        if (index > -1) {
            const goal = state.fileGoals[index]
            state.fileGoals[index] = { ...goal, canceled: !goal.canceled }
            state.fileGoals = [...state.fileGoals]
        }
    },
    removeMarket(state, marketId) {
        state.fileGoals = state.fileGoals.map(G => ({ ...G, marketId: G.marketId === marketId ? "" : G.marketId }))
        state.accountGoals = state.accountGoals.map(G => ({ ...G, marketId: G.marketId === marketId ? "" : G.marketId }))
    }
}

const actions = {
    async load({ commit }, fileId) {
        const response = await FileService.getFileById(fileId, "goals:(goalId,fileId,name,marketId,candidats,canceled)");
        const goals = response?.goals
        commit('addFileGoals', goals ?? []);
    },
    async addGoal({ commit }, paylod) {
        const goal = paylod.goal
        const response = await GoalService.post(goal, paylod.fileId);
        if (response) {
            commit('addAccountGoal', { ...response, year: goal.year });
            commit('addFileGoal', { ...response, year: goal.year });
        }
    },
    async updateGoal({ commit }, paylod) {
        const response = GoalService.put(paylod.goal, paylod.fileId, paylod.goalId);

        commit('updateFileGoal', paylod.goal);
        commit('updateAccountGoal', paylod.goal);

    },
    async deleteGoal({ commit }, paylod) {
        await GoalService.delete(paylod.goalId, paylod.fileId)
        commit('removeAccountGoal', paylod.goalId)
        commit('removeFileGoal', paylod.goalId)
    }
}

const getters = {
    accountGoals(state) {
        return state.accountGoals
    },
    fileGoals(state) {
        return state.fileGoals
    }
}


export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};