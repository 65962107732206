<template>
<div class="page-login row m-0">
    <div class="container col-md-7 d-flex flex-column justify-content-center position-relative">
        <div class="col-md-8 col-lg-6 ml-auto mr-auto">
            <form @submit.prevent="login">
                <card class="card-login card-plain">

                    <div slot="header">
                        <div class="logo-container mb-5">
                            <img src="img/logo_mcul.png" alt="">
                        </div>
                    </div>

                    <div>
                        <fg-input v-model="model.username"
                                    v-validate="'required'"
                                    name="username"
                                    :error="getError('username')"
                                    class="form-control-lg"
                                    placeholder="IDUL"
                                    addon-left-icon="now-ui-icons users_single-02">
                        </fg-input>

                        <fg-input v-model="model.password"
                                    v-validate="'required|min:5'"
                                    type="password"
                                    name="password"
                                    :error="getError('password')"
                                    class="form-control-lg"
                                    placeholder="Password"
                                    addon-left-icon="now-ui-icons ui-1_lock-circle-open">
                        </fg-input>

                        <div v-if="getError('accountLocked')" class="mt-4 text-danger font-weight-bold">
                            {{ getError('accountLocked') }}
                        </div>
                    </div>

                    <div slot="footer">
                        <n-button native-type="submit" type="primary" round block>
                            Connexion
                        </n-button>
                    </div>

                </card>
            </form>
        </div>
        <Footer/>
    </div>
    <div class="col-md-5 d-none d-md-block position-relative h-100 p-0 overflow-hidden">
        <img id="image-background-2" class="page-login__image img-fluid position-absolute" src="img/login-page-image-3.jpg">
        <img id="image-background-1" class="page-login__image img-fluid position-absolute" src="img/login-page-image-2.jpg">
        <img id="image-background-0" class="page-login__image img-fluid position-absolute" src="img/login-page-image-1.jpg">
    </div>
</div>
</template>
<script>
import {Footer} from 'src/components';
import AuthenticationService from '../../services/authentication.service';

const nbBackgroundImages = 3;

export default {
    name: 'PageLogin',
    components: {
        Footer,
    },
    data() {
        return {
            model: {
                username: '',
                password: ''
            },
            imageInterval: null,
            nextImageIndex: 1,
        };
    },
    mounted() {
        document.getElementById('image-background-0').style.opacity = '1';
        let self = this;
        this.imageInterval = setInterval(function () {
            for (let i = 0; i < nbBackgroundImages; i++) {
                if (i === self.nextImageIndex) {
                    document.getElementById('image-background-' + i).style.opacity = '1';
                } else {
                    document.getElementById('image-background-' + i).style.opacity = '0';
                }
            }
            if (self.nextImageIndex === (nbBackgroundImages - 1)) {
                self.nextImageIndex = 0;
            } else {
                self.nextImageIndex = self.nextImageIndex + 1;
            }
        }, 5000);
    },
    beforeDestroy() {
        clearInterval(this.imageInterval);
    },
    methods: {
        getError(fieldName) {
            return this.errors.first(fieldName);
        },
        async login() {
            let isValidForm = await this.$validator.validateAll();
            if (isValidForm) {
                this.errors.clear();
                try {
                    await AuthenticationService.login(this.model);
                    const redirect = this.$route.query.redirect;
                    if (redirect) {
                        this.$router.push({ path: redirect });
                    } else {
                        this.$router.push("home");
                    }
                } catch (e) {
                    if (e.response && e.response.status === 403) {
                        // Handle 403 Forbidden error
                        this.errors.add({
                            field: 'accountLocked',
                            msg: e.response.data
                        });
                    } else {
                        const attempts = e.response.data.error;
                        const message = attempts.split('|').length > 1 ? ` ${attempts.split('|')[1]} / 5` : '';
                        this.errors.add({
                            field: 'password',
                            msg: `La connexion a échoué. Réessayez.${message}`
                        });
                    }
                }
            }
        }
    }
};
</script>
<style lang="scss">
.page-login {

    .form-control-lg {
        height: unset;
    }

    height: 100vh;

    &__image {
        width: auto;
        height: auto;
        min-width: 100%;
        min-height: 100%;
        max-width: none;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        transition: opacity 1s ease-in-out;
    }
}
</style>
