<template>
    <div class="location-selector position-relative show">
        <i @click="removeAgent" v-if="type === 'search'" class="delete-icon position-absolute cursor-pointer now-ui-icons ui-1_simple-remove" />
        <fg-input placeholder="Tous" :value="location" @focus="focused = true" @blur="onblur"></fg-input>
        <ul class="dropdown-menu show" v-if="focused">
            <a class="dropdown-item cursor-pointer" v-for="(suggestion, index) in suggestions" :key="index" @mousedown="onSuggestionSelect(suggestion.value)">
                {{suggestion.label}}
            </a>
        </ul>
    </div>
    </template>
    
    <script>
    import {FormGroupInput as FgInput} from 'src/components';
    import {DropDown} from 'src/components';
    import locationsList from '../util/filter.util';
    
    export default {
        components: {
            FgInput,
            DropDown,
        },
        watch: {
            focused(newValue) {
                if (!newValue) {
                    this.currentFocus = -1;
                }
            },
        },
        props: {
            value: String,
            type: String,
        },
        data() {
            return {
                currentFocus: -1,
                suggestions: [],
                location: 'Tous',
                focused: false,
            };
        },
        created() {
            if (this.type === "search") {
                this.suggestions = locationsList;
                const index = this.suggestions.findIndex((e) => e.value === "unknown");
                if (index !== -1) {
                    this.suggestions.splice(index, 1);
                }
            } else {
                this.suggestions = locationsList;
            }
            this.setRevenueFromValue();
        },
        methods: {
            setRevenueFromValue() {
                for (let i = 0; i < this.suggestions.length; i++) {
                    if (this.suggestions[i].value === this.value) {
                        this.location = this.suggestions[i].label;
                        break;
                    }
                }
            },
            onblur() {
                this.focused = false;
                this.setRevenueFromValue();
            },
            onSuggestionSelect(location) {
                this.$emit('input', location);
                this.focused = false;
                for (let i = 0; i < this.suggestions.length; i++) {
                    if (this.suggestions[i].value === location) {
                        this.location = this.suggestions[i].label;
                        break;
                    }
                }
            },
            removeAgent() {
                this.$emit('input', "");
                this.focused = false;
                this.location = "";
            },
        },
    }
    </script>
    
    <style lang="scss">
    .location-selector {
    
        .delete-icon {
            right: 0;
            top: 50%;
            transform: translate(-50%, -50%);
                z-index: 1;
        }
    
        .form-group {
            input:focus {
                border: 1px solid #E3E3E3;
            }
    
            &::after {
                content: '';
            }
        }
    
        .autocomplete-active {
            background-color: hsla(0,0%,87.1%,.3) !important;
        }
    }
    </style>