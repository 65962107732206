<template>
  <card>
    <div class="row m-0 mb-4 d-flex align-items-center">
      <h3 class="card-title mr-3 mb-0">{{ title }}</h3>
      <fg-input
        class="no-border mr-3 mb-0"
        placeholder="Filtrer"
        v-model="filesFilter"
      ></fg-input>
      <el-select
        class="select-primary file-card__header-select"
        placeholder="Trier"
        v-model="selects.simple"
      >
        <el-option
          v-for="option in selects.options"
          class="select-primary"
          :value="option.value"
          :label="option.label"
          :key="option.label"
        >
        </el-option>
      </el-select>
    </div>
    <div class="overflow-hidden">
      <Spinner size="4" v-if="loading" />
      <div class="row" v-if="!loading">
        <FollowUpWidget
          v-for="(file, index) in filteredFiles"
          :key="index"
          :file="file"
          :follow-up-modal="followUpModal"
          :title-clickable="true"
          :delete-file="deleteFile"
        />
      </div>
      <div
        v-if="files.length > defaultNumberOfFilesShown"
        class="w-100 d-flex justify-content-center"
      >
        <span class="file-card__show-more-less" v-if="!seeMore" @click="setSeeMore(true)"
          >Voir plus <i class="now-ui-icons ui-1_simple-add"></i
        ></span>
        <span class="file-card__show-more-less" v-if="seeMore" @click="setSeeMore(false)"
          >Voir moins <i class="now-ui-icons ui-1_simple-delete"></i
        ></span>
      </div>
    </div>
  </card>
</template>

<script>
import { Card, FollowUpWidget, Spinner, FormGroupInput as FgInput } from "src/components";
import { normalizeLowerCaseString } from "../../util/string.util";
import { Select, Option } from "element-ui";
import { mapGetters } from "vuex";
import FileService from "../../services/file.service";
import SocketService from "../../services/socket.service";

const defaultNumberOfFilesShown = 15;

export default {
  components: {
    Card,
    FollowUpWidget,
    Spinner,
    FgInput,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  computed: {
    ...mapGetters({
      openedFiles: "files/opened",
      closedFiles: "files/closed",
    }),
    files() {
      if (this.type === "opened") {
        return this.openedFiles;
      } else {
        return this.closedFiles;
      }
    },
    filteredFiles() {
      return this.sortFilteredFiles.slice(0, this.filesDisplayLessMaxIndex);
    },
    sortFilteredFiles() {
      if (this.selects.simple === "alphabeticalUp") {
        return this.textFilteredFiles.slice().sort(function (a, b) {
          if (a.account.name < b.account.name) {
            return -1;
          }
          if (a.account.name > b.account.name) {
            return 1;
          }
          return 0;
        });
      } else if (this.selects.simple === "alphabeticalDown") {
        return this.textFilteredFiles.slice().sort(function (a, b) {
          if (a.account.name < b.account.name) {
            return 1;
          }
          if (a.account.name > b.account.name) {
            return -1;
          }
          return 0;
        });
      } else if (this.selects.simple === "advancementsUp") {
        return this.textFilteredFiles.slice().sort(function (a, b) {
          if (
            a.mandateIds &&
            a.mandateIds.length > 0 &&
            (!b.mandateIds || b.mandateIds.length === 0)
          ) {
            return 1;
          }
          if (
            a.meetingIds &&
            a.meetingIds.length > 0 &&
            (!b.meetingIds || b.meetingIds.length === 0)
          ) {
            return 1;
          }
          if (
            a.callIds &&
            a.callIds.length > 0 &&
            (!b.callIds || b.callIds.length === 0)
          ) {
            return 1;
          }
          if (a.leadId && a.leadId !== "" && (!b.leadId || b.leadId === "")) {
            return 1;
          }

          if (
            b.mandateIds &&
            b.mandateIds.length > 0 &&
            (!a.mandateIds || a.mandateIds.length === 0)
          ) {
            return -1;
          }
          if (
            b.meetingIds &&
            b.meetingIds.length > 0 &&
            (!a.meetingIds || a.meetingIds.length === 0)
          ) {
            return -1;
          }
          if (
            b.callIds &&
            b.callIds.length > 0 &&
            (!a.callIds || a.callIds.length === 0)
          ) {
            return -1;
          }
          if (b.leadId && b.leadId !== "" && (!a.leadId || a.leadId === "")) {
            return -1;
          }

          return 0;
        });
      } else if (this.selects.simple === "advancementsDown") {
        return this.textFilteredFiles.slice().sort(function (a, b) {
          if (
            a.mandateIds &&
            a.mandateIds.length > 0 &&
            (!b.mandateIds || b.mandateIds.length === 0)
          ) {
            return -1;
          }
          if (
            a.meetingIds &&
            a.meetingIds.length > 0 &&
            (!b.meetingIds || b.meetingIds.length === 0)
          ) {
            return -1;
          }
          if (
            a.callIds &&
            a.callIds.length > 0 &&
            (!b.callIds || b.callIds.length === 0)
          ) {
            return -1;
          }
          if (a.leadId && a.leadId !== "" && (!b.leadId || b.leadId === "")) {
            return -1;
          }

          if (
            b.mandateIds &&
            b.mandateIds.length > 0 &&
            (!a.mandateIds || a.mandateIds.length === 0)
          ) {
            return 1;
          }
          if (
            b.meetingIds &&
            b.meetingIds.length > 0 &&
            (!a.meetingIds || a.meetingIds.length === 0)
          ) {
            return 1;
          }
          if (
            b.callIds &&
            b.callIds.length > 0 &&
            (!a.callIds || a.callIds.length === 0)
          ) {
            return 1;
          }
          if (b.leadId && b.leadId !== "" && (!a.leadId || a.leadId === "")) {
            return 1;
          }

          return 0;
        });
      } else if (this.selects.simple === "chronological") {
        return this.textFilteredFiles.slice().sort(function (a, b) {
          return new Date(b.dateModified) - new Date(a.dateModified);
        });
      } else {
        return this.textFilteredFiles;
      }
    },
    textFilteredFiles() {
      if (this.files) return this.applyTextFilter(this.files, this.filesFilter);
      return [];
    },
  },
  props: {
    title: String,
    loading: Boolean,
    type: {
      type: String,
      validator: (value) => ["closed", "opened"].indexOf(value) !== -1,
    },
    followUpModal: Object,
  },
  data() {
    return {
      filesFilter: "",
      filesDisplayLessMaxIndex: defaultNumberOfFilesShown,
      defaultNumberOfFilesShown: defaultNumberOfFilesShown,
      seeMore: false,
      selects: {
        simple: "",
        options: [
          { value: "alphabeticalUp", label: "Alphabétique <" },
          { value: "alphabeticalDown", label: "Alphabétique >" },
          { value: "advancementsUp", label: "Avancements <" },
          { value: "advancementsDown", label: "Avancements >" },
          //{value: 'chronological', label: 'Chronologique'},
        ],
      },
    };
  },
  methods: {
    async deleteFile(fileId) {
      await FileService.delete(fileId);
      this.$store.commit("files/remove", fileId);
      SocketService.emit("fileDeleted", fileId);
    },
    applyTextFilter(files, textFilter) {
      let fileredFiles = [];
      for (let i = 0; i < files.length; i++) {
        if (!files[i].account.name && textFilter === "") {
          fileredFiles.push(files[i]);
        } else if (
          normalizeLowerCaseString(files[i].account.name).includes(
            normalizeLowerCaseString(textFilter)
          )
        ) {
          fileredFiles.push(files[i]);
        }
      }
      return fileredFiles;
    },
    setSeeMore(newValue) {
      if (newValue) {
        this.filesDisplayLessMaxIndex = this.files.length;
      } else {
        this.filesDisplayLessMaxIndex = defaultNumberOfFilesShown;
      }
      this.seeMore = newValue;
    },
  },
};
</script>

<style lang="scss">
.file-card {
  &__show-more-less {
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: transparent;
    transition: text-decoration-color 200ms ease-in-out;

    &:hover {
      text-decoration-color: #2c2c2c;
    }

    .now-ui-icons {
      font-size: 0.5rem;
    }
  }

  &__header-select .el-input__inner {
    transition: background-color 200ms ease-in-out;

    &:hover {
      box-shadow: none !important;
    }
  }
}
</style>
