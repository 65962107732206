import TokenService from './token.service';
import ApiService from './api.service';
import SocketService from './socket.service';

class AuthenticationService {

    constructor() {
        this.basePath = "authentication";
    }

    async login(credentials) {
        const response = await ApiService.post(`${this.basePath}/login`, credentials);
        TokenService.saveToken(response.data.access_token);
        TokenService.saveRefreshToken(response.data.refresh_token);
        TokenService.saveWebsocketToken(response.data.websocket_token);
        ApiService.setHeader();
        SocketService.connect();
    }

    async logout() {
        //await ApiService.post(`${this.basePath}/logout`, {});
        TokenService.removeToken();
        TokenService.removeRefreshToken();
        TokenService.removeWebsocketToken();
        ApiService.removeHeader();
        SocketService.disconnect();
    }
}

export default new AuthenticationService();