const leadOutcomes = [
    { value: 'coldCallObtained', label: 'Appel obtenu', ending: false },
    { value: 'personNotThere', label: 'Personne absente', ending: false },
    { value: 'personOccupied', label: 'Personne occupée', ending: false },
    { value: 'secretaryFilter', label: 'Secrétaire fait le filtre', ending: false },
    { value: 'voiceMail', label: 'Boîte vocale', ending: false },
];

const leadContact = [
    { value: 'contactCourriel', label: 'Contact par courriel', ending: false },
    { value: 'contactLinkedIn', label: 'Contact par linkedIn', ending: false },
    { value: 'contactTelephone', label: 'Contact par téléphone', ending: false },
    { value: 'rencontreLorsEvenement', label: "Rencontré lors d'un événement", ending: false },
    { value: 'refereContact', label: 'Référé par un contact commun', ending: false },
    { value: 'autreMoyen', label: 'Autres (à préciser dans Notes)', ending: false }
];

const callOutcomes = [
    { value: 'coldCallObtained', label: 'Autre appel obtenu', ending: false },
    { value: 'meetingScheduled', label: 'RDV obtenu', ending: false },
    { value: 'noWorkforce', label: "Manque de main d'oeuvre", ending: true },
    { value: 'restructuration', label: "Restructuration", ending: true },
    { value: 'otherMarket', label: "Se concentre sur un autre marché", ending: true },
    { value: 'noMoney', label: "Manque d'argent", ending: true },
    { value: 'headOfficeOutside', label: "Siège social à l'extérieur", ending: true },
    { value: 'notInterested', label: "Pas intéressé", ending: true },
    { value: 'interestedCanada', label: "Intéressé par le recrutement international", ending: false },
    { value: 'interestedInternational', label: "Intéressé par International", ending: false },
];

const meetingOutcomes = [
    { value: 'mandateSigned', label: 'Mandat(s) signé(s)', ending: false },
    { value: 'meetingScheduled', label: 'Autre RDV obtenu', ending: false },
    { value: 'noWorkforce', label: "Manque de main d'oeuvre", ending: true },
    { value: 'restructuration', label: "Restructuration", ending: true },
    { value: 'otherMarket', label: "Se concentre sur un autre marché", ending: true },
    { value: 'noMoney', label: "Manque d'argent", ending: true },
    { value: 'headOfficeOutside', label: "Siège social à l'extérieur", ending: true },
    { value: 'notInterested', label: "Pas intéressé", ending: true },
    { value: 'interestedCanada', label: "Intéressé par le recrutement international", ending: false },
    { value: 'interestedInternational', label: "Intéressé par International", ending: false },
];

const allOutcomes = [
    { value: 'coldCallObtained', label: 'Appel obtenu', ending: false },
    { value: 'personNotThere', label: 'Personne absente', ending: false },
    { value: 'personOccupied', label: 'Personne occupée', ending: false },
    { value: 'secretaryFilter', label: 'Secrétaire fait le filtre', ending: false },
    { value: 'voiceMail', label: 'Boîte vocale', ending: false },
    { value: 'mandateSigned', label: 'Mandat(s) signé(s)', ending: false },
    { value: 'meetingScheduled', label: 'RDV obtenu', ending: false },
    { value: 'noWorkforce', label: "Manque de main d'oeuvre", ending: true },
    { value: 'restructuration', label: "Restructuration", ending: true },
    { value: 'otherMarket', label: "Se concentre sur un autre marché", ending: true },
    { value: 'noMoney', label: "Manque d'argent", ending: true },
    { value: 'headOfficeOutside', label: "Siège social à l'extérieur", ending: true },
    { value: 'notInterested', label: "Pas intéressé", ending: true },
    { value: 'interestedCanada', label: "Intéressé par le recrutement international", ending: false },
    { value: 'interestedInternational', label: "Intéressé par International", ending: false },
];

function getLabel(value) {
    for (let i = 0; i < allOutcomes.length; i++) {
        if (allOutcomes[i].value === value) {
            return allOutcomes[i].label;
        }
    }
    return "???";
}

function getEnding(value) {
    for (let i = 0; i < allOutcomes.length; i++) {
        if (allOutcomes[i].value === value) {
            return allOutcomes[i].ending;
        }
    }
    return false;
}

export { callOutcomes, meetingOutcomes, allOutcomes, leadOutcomes, leadContact, getLabel, getEnding };