const quebecRegions = [
    '01. Bas-Saint-Laurent',
    '02. Saguenay–Lac-Saint-Jean',
    '03. Capitale-Nationale',
    '04. Mauricie',
    '05. Estrie',
    '06. Montréal (région administrative)',
    '07. Outaouais',
    '08. Abitibi-Témiscaminque',
    '09. Côte-Nord',
    '10. Nord-du-Québec',
    '11. Gaspésie-Îles-de-la-Madeleine',
    '12. Chaudière Appalaches',
    '13. Laval',
    '14. Lanaudière',
    '15. Laurentides (région administrative)',
    '16. Montérégie',
    '17. Centre-du-Québec'
]

export default quebecRegions