const missionObjectives = [
	"Étude de marché",
	"Importation", 
    "Exportation", 
    "Partenariat",
    "Alliance stratégique",
    "Fusion et acquisition",
    "Implantation",
    "Recrutement"
];

export {missionObjectives};