import Vue from 'vue'
import Vuex from 'vuex';
import filesModule from './modules/files.module';
import goalsModule from './modules/goals.module';

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        files: filesModule,
        goals: goalsModule
    },
});

export default store;