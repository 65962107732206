const revenues = [
    {
        value: "unknown",
        label: "Inconnu"
    },
    {
        value: "less100K",
        label: "Moins de 100 000$"
    },
    {
        value: "100Kto499K",
        label: "100 000$ à 499 999$"
    },
    {
        value: "500Kto999K",
        label: "500 000$ à 999 999$"
    },
    {
        value: "1Mto3M",
        label: "1M$ à 3M$"
    },
    {
        value: "3Mto5M",
        label: "3M$ à 5M$"
    },
    {
        value: "5Mto10M",
        label: "5M$ à 10M$"
    },
    {
        value: "10Mto25M",
        label: "10M$ à 25M$"
    },
    {
        value: "25Mto50M",
        label: "25M$ à 50M$"
    },
    {
        value: "50Mto100M",
        label: "50M$ à 100M$"
    },
    {
        value: "100Mto250M",
        label: "100M$ à 250M$"
    },
    {
        value: "250Mto1G",
        label: "250M$ à 1G$"
    },
];

export default revenues;