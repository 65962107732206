<template>
  <card class="mandate-form-cell">
    <div>
      <div class="row">
        <div class="col-12 col-sm-6">
          <label>Destination</label>
          <MarketSelector :value="mandate.destination" @input="onDestinationChanged" />
        </div>
        <div class="col-12 col-sm-6">
          <label>Année</label>
          <YearSelector :value="mandate.year" @input="onYearChanged" />
        </div>
        <div class="col-12 col-sm-6">
          <label>Agent</label>
          <AgentSelector
            placeholder="Agent"
            :value="mandate.agentId"
            @input="onAgentIdChanged"
          />
        </div>
        <div class="col-12 col-sm-6">
          <label>Mandant</label>
          <ClientSelector
            placeholder="Mandant"
            :value="mandate.clientId"
            @input="onClientIdChanged"
            :multiple="false"
          />
        </div>
      </div>
      <div class="form-group has-label mt-1">
        <label>Résumé</label>
        <div class="textarea-summary-container">
          <textarea-debounce
            class="form-control textarea-summary overflow-hidden"
            v-model="mandate.summary"
            @save="onSummaryChanged"
          />
        </div>
      </div>
      <div class="form-group has-label mt-1">
        <label>Suivi</label>
        <div class="textarea-followup-container">
          <textarea-debounce
            class="form-control textarea-followup overflow-hidden"
            v-model="mandate.followUp"
            @save="onFollowUpChanged"
          />
        </div>
      </div>
      <div class="form-group has-label mt-1" v-if="mandate.confidentialDetails">
        <label>Infos. confidentielles</label>
        <div class="textarea-confidential-container">
          <textarea-debounce
            class="form-control textarea-confidential overflow-hidden"
            v-model="mandate.confidentialDetails"
            @save="onConfidentialChanged"
          />
        </div>
      </div>

      <div class="form-group has-label mt-1" v-if="isAdmin">
        <label>Notes Admin</label>
        <div class="textarea-notes-admin-container">
          <textarea-debounce
            class="form-control textarea-notes-admin overflow-hidden"
            v-model="mandate.notesAdmin"
            @save="onNotesAdminChanged"
          />
        </div>
      </div>

      <div class="has-label mt-3">
        <label>Objectifs</label>
        <!-- <DefaultTagWidget :value="mandate.objectives" @input="onObjectivesChanged" placeholder="Objectifs"
                    :source="missionObjectives" :max-suggestions="null" :display-suggestions-on-focus="true" /> -->
        <goal mode="EDIT"></goal>
      </div>
      <div class="w-100 d-flex justify-content-center">
        <n-button
          class="mt-4"
          type="danger"
          @click.native="onDeleteMandateClicked"
          @blur.native="
            () => {
              deleteConfirmation = false;
            }
          "
          >{{ deleteButtonLabel }}</n-button
        >
      </div>
    </div>
  </card>
</template>

<script>
import { Card, Goal } from "src/components";
import MandateService from "../../services/mandate.service";
import PerfectScrollbar from "perfect-scrollbar";
import DefaultTagWidget from "../DefaultTagWidget";
import YearSelector from "../YearSelector";
import AgentSelector from "../AgentSelector";
import MarketSelector from "../MarketSelector";
import ClientSelector from "../ClientSelector";
import { missionObjectives } from "../../util/objectives.util";
import TextareaDebounce from "./TextareaDebouce";
import { mapGetters } from "vuex";
import TokenService from "../../services/token.service";

export default {
  components: {
    Card,
    DefaultTagWidget,
    YearSelector,
    AgentSelector,
    MarketSelector,
    ClientSelector,
    TextareaDebounce,
    Goal,
  },
  computed: {
    deleteButtonLabel() {
      if (this.deleteConfirmation) return "Supprimer (Confirmez!)";
      return "Supprimer";
    },
    ...mapGetters({
      file: "files/active",
    }),
    isAdmin() {
      return TokenService.isAdmin();
    },
  },
  props: {
    mandate: Object,
    setError: Function,
    deleteMandate: Function,
  },
  data() {
    let self = this;
    return {
      resizeTimer: null,
      ps: null,
      psFollowUp: null,
      psAdmin: null,
      psConfidential: null,
      deleteConfirmation: false,
      missionObjectives: missionObjectives,
    };
  },
  mounted() {
    this.updateSummaryTextareaHeight(this.$el.querySelector(".textarea-summary"));
    this.updateFollowUpTextareaHeight(this.$el.querySelector(".textarea-followup"));
    if (this.mandate.confidentialDetails) {
      this.updateConfidentialTextareaHeight(
        this.$el.querySelector(".textarea-confidential")
      );
    }
    this.ps = new PerfectScrollbar(this.$el.querySelector(".textarea-summary-container"));
    this.psFollowUp = new PerfectScrollbar(
      this.$el.querySelector(".textarea-followup-container")
    );
    this.psAdmin = new PerfectScrollbar(
      this.$el.querySelector(".textarea-notes-admin-container")
    );
    if (this.mandate.confidentialDetails) {
      this.psConfidential = new PerfectScrollbar(
        this.$el.querySelector(".textarea-confidential-container")
      );
    }
    window.addEventListener("resize", this.onWindowResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onWindowResize);
  },
  methods: {
    onWindowResize() {
      clearTimeout(this.resizeTimer);
      let self = this;
      this.resizeTimer = setTimeout(function () {
        self.updateSummaryTextareaHeight(self.$el.querySelector(".textarea-summary"));
        self.updateFollowUpTextareaHeight(self.$el.querySelector(".textarea-followup"));
        if (self.mandate.confidentialDetails) {
          self.updateConfidentialTextareaHeight(
            self.$el.querySelector(".textarea-confidential")
          );
        }
        // self.ps.update();
        // self.psFollowUp.update();
        // self.psConfidential.update();
      }, 250);
    },
    async onDestinationChanged(val) {
      this.setError(null);
      this.mandate.destination = val;
      await MandateService.put(this.getData("destination"));
    },
    async onYearChanged(val) {
      this.setError(null);
      this.mandate.year = val;
      await MandateService.put(this.getData("year"));
    },
    async onAgentIdChanged(val) {
      this.setError(null);
      this.mandate.agentId = val;
      await MandateService.put(this.getData("agentId"));
    },
    async onClientIdChanged(val) {
      this.setError(null);
      this.mandate.clientId = val;
      await MandateService.put(this.getData("clientId"));
    },
    async onSummaryChanged(e) {
      this.setError(null);
      this.updateSummaryTextareaHeight(e.target);
      this.mandate.summary = e.target.value;
      await MandateService.put(this.getData("summary"));
    },
    async onFollowUpChanged(e) {
      this.setError(null);
      this.updateFollowUpTextareaHeight(e.target);
      this.mandate.followUp = e.target.value;
      await MandateService.put(this.getData("followUp"));
    },
    async onNotesAdminChanged(e) {
      this.setError(null);
      this.updateSNotesAdminTextareaHeight(e.target);
      this.mandate.notesAdmin = e.target.value;
      await MandateService.put(this.getData("notesAdmin"));
    },
    async onConfidentialChanged(e) {
      this.setError(null);
      this.updateSNotesAdminTextareaHeight(e.target);
      this.mandate.confidentialDetails = e.target.value;
      await MandateService.put(this.getData("confidentialDetails"));
    },
    updateSummaryTextareaHeight(element) {
      element.style.minHeight = "";
      element.style.minHeight = element.scrollHeight + 2 + "px";
      if (this.ps) {
        this.ps.update();
      }
    },
    updateSNotesAdminTextareaHeight(element) {
      element.style.minHeight = "";
      element.style.minHeight = element.scrollHeight + 2 + "px";
      if (this.psAdmin) {
        this.psAdmin.update();
      }
    },
    updateFollowUpTextareaHeight(element) {
      element.style.minHeight = "";
      element.style.minHeight = element.scrollHeight + 2 + "px";
      if (this.psFollowUp) {
        this.psFollowUp.update();
      }
    },
    updateConfidentialTextareaHeight(element) {
      element.style.minHeight = "";
      element.style.minHeight = element.scrollHeight + 2 + "px";
      if (this.psConfidential) {
        this.psConfidential.update();
      }
    },
    async onObjectivesChanged(newValue) {
      this.setError(null);
      this.mandate.objectives = newValue;
      await MandateService.put(this.getData("objectives"));
    },
    onDeleteMandateClicked() {
      if (!this.deleteConfirmation) {
        this.deleteConfirmation = true;
      } else {
        this.deleteMandate(this.mandate.id);
      }
    },
    getData(attribute) {
      let data = {
        id: this.mandate.id,
      };
      data[attribute] = this.mandate[attribute];
      return data;
    },
  },
};
</script>
<style lang="scss" scoped>
.mandate-form-cell {
  .textarea-summary-container,
  .textarea-followup-container,
  .textarea-notes-admin,
  .textarea-confidential-container {
    position: relative;
  }

  .has-success.form-group .form-control {
    padding-right: 18px;
  }
}
</style>
