import ApiService from './api.service';

class EventService {

    constructor() {
        this.basePath = "events";
    }

    async getMeetingsNotAccompanied(fromDate) {
        try {
            const response = await ApiService.get(`${this.basePath}/meetings/notAccompanied${fromDate ? "?fromDate="+fromDate : ""}`);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    }

    async getMeetingsAccompanied(fromDate) {
        try {
            const response = await ApiService.get(`${this.basePath}/meetings/accompanied${fromDate ? "?fromDate="+fromDate : ""}`);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    }

    async getAllUserMeetings() {
        try {
            const response = await ApiService.get(`${this.basePath}/meetings`);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    }

}

export default new EventService();