<template>
<modal :show.sync="modal.display" header-classes="justify-content-center">
    <h4 slot="header" class="title title-up">{{title}}</h4>
    <p><b>Agent: </b>{{agent}}</p>
    <div>
        <label><b>Sommaire:</b></label>
        <p>{{summary}}</p>
    </div>
    <div>
        <label><b>Suivi:</b></label>
        <p>{{followUp}}</p>
    </div>
    <div v-if="modal.data && modal.data.confidentialDetails !== null && modal.data.confidentialDetails !== ''">
        <label><b>Détails confidentiels:</b></label>
        <p>{{confidentialDetails}}</p>
    </div>
    <template slot="footer">
        <div></div>
        <n-button type="danger" @click.native="modal.display = false">Close</n-button>
    </template>
</modal>
</template>

<script>
import {
    Modal
} from 'src/components';

export default {
    components: {
        Modal,
    },
    computed: {
        title() {
            if (!this.modal.data) return "";
            return `Mandat ${this.modal.data.year} - ${this.modal.data.destination}`;
        },
        summary() {
            if (!this.modal.data) return "";
            return this.modal.data.summary;
        },
        agent() {
            if (!this.modal.data) return "";
            return `${this.modal.data.agent.firstName} ${this.modal.data.agent.lastName}`;
        },
        followUp() {
            if (!this.modal.data) return "";
            return this.modal.data.followUp;
        },
        confidentialDetails() {
            return this.modal.data.confidentialDetails;
        },
    },
    props: {
        modal: Object,
    }
}
</script>

<style lang="scss">
</style>
