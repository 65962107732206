<template>
<div class="form-group has-label col-12">
    <label>{{label}}</label>
    <div class="textarea-container">
        <textarea class="form-control custom-textarea overflow-hidden" :value="value" @input="onInput"></textarea>
    </div>
</div>
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar';

export default {
    props: {
        value: String,
        label: String,
        debounce: {
            default: 0,
            type: Number,
        },
    },
    mounted() {
        this.updateTextareaHeight(this.$el.querySelector(".custom-textarea"));
        this.ps = new PerfectScrollbar(this.$el.querySelector(".textarea-container"));
        window.addEventListener('resize', this.onWindowResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onWindowResize);
    },
    data() {
        return {
            resizeTimer: null,
            ps: null,
            timer: null,
        };
    },
    methods: {
        onWindowResize() {
            clearTimeout(this.resizeTimer);
            let self = this;
            this.resizeTimer = setTimeout(function() {
                self.updateTextareaHeight(self.$el.querySelector(".custom-textarea"));
            }, 250);
        },
        updateTextareaHeight(element) {
            element.style.minHeight = "";
            element.style.minHeight = element.scrollHeight + "px";
            if (this.ps) {
                this.ps.update();
            }
        },
        onInput(e) {
            this.updateTextareaHeight(e.target);
            let value = e.target.value;
            this.$emit('input', value);
            const self = this;
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(() => {
                this.$emit('save');
            }, this.debounce);
        },
    },
}
</script>

<style lang="scss">
.textarea-container {
    max-height: 130px;
    position: relative;
}
</style>
