import ApiService from './api.service';

class LeadService {

    constructor() {
        this.basePath = "leads";
        this.fullSelector = `(
            name,
            description,
            selectedOutcomes,
            selectedContact,
            notes,
            dateCreated,
            doneBy
        )`;
    }

    async getOne(leadId) {
        try {
            const response = await ApiService.get(`${this.basePath}/${leadId}?selector=${this.fullSelector.replace(/\s/g, '')}`);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    }

    async getAll(leadIds) {
        try {
            const response = await ApiService.get(`${this.basePath}?leadIds=${leadIds.join()}&selector=${this.fullSelector.replace(/\s/g, '')}`);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    }

    async put(data) {
        try {
            const response = await ApiService.put(`${this.basePath}?selector=${this.fullSelector}`, data);
            return response.data;
        } catch (e) {
            throw e;
        }
    }

    async post(lead) {
        try {
            const response = await ApiService.post(`${this.basePath}?selector=${this.fullSelector}`, lead);
            return response.data;
        } catch (e) {
            throw e;
        }
    }

    async delete(leadId) {
        try {
            await ApiService.delete(`${this.basePath}/${leadId}`);
        } catch (e) {
            throw e;
        }
    }
}

export default new LeadService();