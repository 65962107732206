<template>
    <div class="page-data">
        <div>
            <h4 class="card-title">Extraction</h4>
        </div>
        <div>
            <div class="d-inline-flex flex-column mr-2">
                <label>Fichier de sortie</label>
                <el-select class="select-info" v-model="outputFile.value" placeholder="Output">
                    <el-option v-for="option in outputFile.options" class="select-primary" :value="option.value"
                        :label="option.label" :key="option.value">
                    </el-option>
                </el-select>
            </div>
            <div class="d-inline-flex flex-column mr-2">
                <label>Attributs de sortie</label>
                <el-select class="select-info" multiple="" collapse-tags :value="outputAttributes.value"
                    @input="onAttributesChanged" placeholder="Attributs">
                    <el-option v-for="option in outputAttributes.options" class="select-primary" :value="option.value"
                        :label="option.label" :key="option.value">
                    </el-option>
                </el-select>
            </div>
        </div>
        <div class="mt-4">
            <h6>Filtres</h6>
            <div class="row align-items-start mb-2">
                <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                    <label>Revenu annuel</label>
                    <RevenueSelector type="search" v-model="annualRevenue" />
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                    <label>Marchés visés</label>
                    <DefaultTagWidget :dropup="false" v-model="markets" placeholder="Marchés"
                        :source="countriesRegionsList" />
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                    <label class="d-block">Années de mandat</label>
                    <el-select class="select-transparent" multiple="" collapse-tags :value="yearSelect.value"
                        @input="onYearsChanged" placeholder="Années">
                        <el-option v-for="option in yearSelect.options" class="select-primary" :value="option.value"
                            :label="option.label" :key="option.value">
                        </el-option>
                    </el-select>
                </div>

                <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                    <label class="d-block">Résultat d'appel/rdv</label>
                    <el-select class="select-transparent" multiple="" collapse-tags :value="outcomeSelect.value"
                        @input="onOutcomeChanged" placeholder="Resultats">
                        <el-option v-for="option in outcomeSelect.options" class="select-primary" :value="option.value"
                            :label="option.label" :key="option.value">
                        </el-option>
                    </el-select>
                </div>

                <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                    <label>Industries</label>
                    <DefaultTagWidget :display-suggestions-on-focus="true" :max-suggestions="null" :dropup="false"
                        v-model="industries" placeholder="Indust..." :source="industriesList" />
                </div>
            </div>
            <div class="row align-items-start">
                <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                    <label class="d-block">Regions</label>
                    <el-select class="select-transparent" multiple="" collapse-tags :value="regions.value"
                        @input="onRegionsChanged" placeholder="Regions">
                        <el-option v-for="option in regions.options" class="select-primary" :value="option.value"
                            :label="option.label" :key="option.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                    <label>Pays mandat</label>
                    <DefaultTagWidget :dropup="false" v-model="mandateCountries" placeholder="Pays"
                        :source="countriesRegionsList" />
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                    <n-checkbox v-model="newsletter">
                        Abonnés à l'infolettre
                    </n-checkbox>
                </div>

            </div>
        </div>
        <div class="mt-2 row d-flex justify-content-center">
            <n-button type="primary" round="" v-if="!downloading" @click.native="download">
                Télécharger<i class="now-ui-icons arrows-1_cloud-download-93 ml-2"></i>
            </n-button>
            <n-button type="danger" round="" v-if="downloading" @click.native="cancel">
                Annuler<i class="now-ui-icons ui-1_simple-remove ml-2"></i>
            </n-button>
        </div>
        <Spinner size="4" v-if="downloading" />
    </div>
</template>

<script>
import {
    Card,
    Checkbox,
    Button,
    Spinner,
} from 'src/components';
import RevenueSelector from 'src/components/RevenueSelector';
import DefaultTagWidget from '../../components/DefaultTagWidget';
import countriesRegionsList from '../../util/countries.regions.util';
import industriesList from '../../util/industries.util';
import DataService from '../../services/data.service';
import regionsMixins from '../../mixins/regions.mixins';
import yearsMixins from '../../mixins/selectYear.mixins';
import outcomesMixins from '../../mixins/outcomes.mixins';
export default {
    mixins: [regionsMixins, yearsMixins, outcomesMixins],
    components: {
        [Checkbox.name]: Checkbox,
        [Button.name]: Button,
        Card,
        Spinner,
        RevenueSelector,
        DefaultTagWidget
    },
    data() {
        return {
            downloading: false,
            countriesRegionsList: countriesRegionsList,
            industriesList: industriesList,
            newsletter: false,
            markets: [],
            industries: [],
            mandateCountries: [],
            annualRevenue: "unknown",
            outputFile: {
                value: "excel",
                options: [
                    {
                        value: "excel",
                        label: "Excel"
                    }
                ]
            },
            outputAttributes: {
                value: ["accountName", "contactName", "title", "email", "officePhone", "mobilePhone", "linkedIn", "newsletter", "interestedInNewsletter", "markets","goals", "outcomes"],
                options: [
                    {
                        value: "accountName",
                        label: "Compagnie"
                    },
                    {
                        value: "contactName",
                        label: "Contact"
                    },
                    {
                        value: "title",
                        label: "Poste"
                    },
                    {
                        value: "email",
                        label: "Courriel"
                    },
                    {
                        value: "officePhone",
                        label: "Téléphone bureau"
                    },
                    {
                        value: "mobilePhone",
                        label: "Téléphone mobile"
                    },
                    {
                        value: "markets",
                        label: "Marchés visés"
                    },
                    {
                        value: "goals",
                        label: "Objectifs et candidats"
                    },
                    {
                        value: "linkedIn",
                        label: "LinkedIn"
                    },
                    {
                        value: "newsletter",
                        label: "Infolettre"
                    },
                    {
                        value: "interestedInNewsletter",
                        label: "Intéressé(e) par l'infolettre"
                    },
                    {
                        value: "outcomes",
                        label: "Résultats d'appel et rdv"
                    },
                ]
            },
        };
    },
    methods: {
        onAttributesChanged(newValue) {
            this.outputAttributes.value = newValue;
        },
        async download() {
            this.downloading = true;
            const payload = {
                mandateGivenYears: this.yearSelect.value,
                meetingOutcomes: this.outcomeSelect.value,
                newsletter: this.newsletter,
                markets: this.markets,
                industries: this.industries,
                mandateCountries: this.mandateCountries,
                annualRevenue: this.annualRevenue,
                outputFile: this.outputFile.value,
                outputAttributes: this.outputAttributes.value,
                regions: this.regions.value,
            };
            await DataService.downloadData(payload);
            this.downloading = false;
        },
        cancel() {
            DataService.cancelRequest();
        }
    },
}
</script>

<style lang="scss" scoped>
@import "assets/sass/select-transparent.scss";

.select-info {
    :deep .el-tag.el-tag--info {
        border-color: transparent !important;

        .el-tag__close {
            color: white !important;
        }

    }
}

:deep {
    .form-group {
        margin-bottom: 0 !important;
    }
}

:deep .el-input {
    input {
        margin: 0 !important;
    }
}


:deep .el-select {
    width: 100%;
}

:deep .el-tag {
    margin-top: 4px !important;
}
</style>