<template>
<div class="text-center">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
</template>

<script>
export default {
    props: {
        size: {
            default: '2',
            type: String,
        },
    },
    mounted() {
        this.$el.querySelector('.spinner-border').style.width = `${this.size}rem`;
        this.$el.querySelector('.spinner-border').style.height = `${this.size}rem`;
    },
}
</script>

<style lang="scss">

</style>
