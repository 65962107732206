import ApiService from './api.service';

class GoalService {

    constructor() {
        this.basePath = "goals";
        this.fullSelector = `(
            goalId,
            fileId,
            name,
            marketId,
            candidats
        )`;
    }

    async getSummary(year) {
        try {
            const response = await ApiService.get(`${this.basePath}/summary?year=${year}`);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    }

    async getAll(accountId) {
        try {
            const response = await ApiService.get(`${this.basePath}?accountId=${accountId}`);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    }

    async put(goal, fileId, goalId) {
        try {
            const response = await ApiService.put(`${this.basePath}/${goalId}?fileId=${fileId}&selector=${this.fullSelector}`, goal);
            return response.data;
        } catch (e) {
            throw e;
        }
    }

    async post(goal, fileId) {
        try {
            const response = await ApiService.post(`${this.basePath}?fileId=${fileId}&selector=${this.fullSelector}`, goal);
            return response.data;
        } catch (e) {
            throw e;
        }
    }

    async delete(goalId, fileId) {
        try {
            await ApiService.delete(`${this.basePath}/${goalId}?fileId=${fileId}`);
        } catch (e) {
            throw e;
        }
    }

}

export default new GoalService();