<template>
  <nav aria-label="breadcrumb" role="navigation">
    <ul class="breadcrumb">
      <slot>

      </slot>
    </ul>
  </nav>
</template>
<script>
export default {
  name: 'breadcrumb'
};
</script>
<style>
</style>
