import {allOutcomes} from '../util/outcomes.util';

export default {
    data() {
        return {
            outcomeSelect: {
                value: '',
                options: allOutcomes
            },
        };
    },
    methods: {
        onOutcomeChanged(newValue) {
            this.outcomeSelect.value = newValue;
        },
    },
};