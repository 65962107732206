<template>
    <div>
        <Spinner size="4" v-if="loading" />
        <span v-if="error" class="text-danger italic">{{ error.message }}</span>
        <div v-if="!loading">
            <tabs ref="TabsRef" type="primary">
                <tab-pane v-for="(mandate, index) in mandates" :label="`Mandate ${index + 1}`">
                    <MandateFormCell :mandate="mandate" :set-error="setError" :delete-mandate="deleteMandate" />
                </tab-pane>
            </tabs>

        </div>
    </div>
</template>

<script>
import {
    Spinner,
    Tabs,
    TabPane,
} from 'src/components';
import MandateFormCell from './MandateFormCell';
export default {
    components: {
        Spinner,
        MandateFormCell,
        Tabs,
        TabPane
    },
    props: {
        mandates: Array,
        loading: Boolean,
        error: Error,
        deleteMandate: Function,
        setError: Function,
    },
}
</script>

<style lang="scss" scoped>
:deep .nav-link {
    padding: 4px 23px !important;
}

:deep .tab-space {
    padding: 20px 0 0px 0px;
}
</style>
