<template>
    <div>
        <div>
            <el-select class="select-danger" placeholder="Année" v-model="yearSelect.value">
                <el-option v-for="option in yearSelect.options" class="select-danger" :value="option.value"
                    :label="option.label" :key="option.label">
                </el-option>
            </el-select>
            <n-button @click.native="search" type="primary" round="" class="ml-2">
                <i class="now-ui-icons ui-1_zoom-bold"></i> Rechercher!
            </n-button>
        </div>
        <div v-if="isStatsPanelClicked">
            <div v-if="mandateData && !loading" class="panel-header panel-header-lg">
                <h6 class="position-absolute text-light ml-3 mt-3" style="top: 0;">Mandats</h6>
                <header-chart :height="255" :data="mandateData" :labels="chartLabels" v-if="!loading">
                </header-chart>
            </div>
            <div v-if="meetingData && !loading" class="panel-header panel-header-lg">
                <h6 class="position-absolute text-light ml-3 mt-3" style="top: 0;">RDV</h6>
                <header-chart :height="255" :data="meetingData" :labels="chartLabels" v-if="!loading">
                </header-chart>
            </div>
            <div v-if="callData && !loading" class="panel-header panel-header-lg">
                <h6 class="position-absolute text-light ml-3 mt-3" style="top: 0;">Appels</h6>
                <header-chart :height="255" :data="callData" :labels="chartLabels" v-if="!loading">
                </header-chart>
            </div>
        </div>
        <Spinner size="4" v-if="loading" />
    </div>
</template>

<script>
import MandateService from '../../services/mandate.service';
import MeetingService from '../../services/meeting.service';
import CallService from '../../services/call.service';
import { Select, Option } from 'element-ui';
import { Button, Spinner } from 'src/components';
import HeaderChart from '../home/HeaderChart';
import selectYearMixins from "../../mixins/selectYear.mixins"

export default {
    mixins: [selectYearMixins],
    components: {
        [Select.name]: Select,
        [Option.name]: Option,
        [Button.name]: Button,
        HeaderChart,
        Spinner,
    },
    methods: {
        async search() {
            this.loading = true;
            const promise1 = this.loadMandates();
            const promise2 = this.loadMeetings();
            const promise3 = this.loadCalls();
            await Promise.all([promise1, promise2, promise3]);
            this.loading = false;
        },
        async loadMandates() {
            this.mandateData = await MandateService.getSummary(this.yearSelect.value);
        },
        async loadMeetings() {
            this.meetingData = await MeetingService.getSummary(this.yearSelect.value);
        },
        async loadCalls() {
            this.callData = await CallService.getSummary(this.yearSelect.value);
        }
    },
    props: {
        isStatsPanelClicked: {
            default: true,
             type: Boolean
        }
    },
    async mounted() {
        if (!this.isStatsPanelClicked)
            await this.search()
    },
    data() {
        return {
            loading: false,
            mandateData: null,
            meetingData: null,
            callData: null,
            chartLabels: [
                'OCT',
                'OCT',
                'NOV',
                'NOV',
                'DEC',
                'DEC',
                'JAN',
                'JAN',
                'FEV',
                'FEV',
                'MAR',
                'MAR'
            ]
        };
    }
}


</script>

<style lang="scss">

</style>