import Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

function getFormattedDateTime(dateString) {
    return moment(dateString).format('Do MMMM YYYY [à] H[h]mm');
}

function getFormattedDateShort(dateString) {
    return moment(dateString).format('DD-MM');
}

function getLastHeightWeeks() {
    let weeks = [];
    for (let i = 7; i >= 0; i--) {
        weeks.push({
            start: moment().subtract(i, 'weeks').startOf('isoWeek').toISOString(),
            end: moment().subtract(i, 'weeks').endOf('isoWeek').toISOString(),
        });
    }
    return weeks;
}

function isDateInRange(startDate, endDate, date) {
    return moment().range(moment(startDate), moment(endDate)).contains(moment(date));
}

function getCurrentYear() {
    const currentMonth = moment().month();
    if (currentMonth >= 0 && currentMonth <= 6) {
        return moment().year();
    } else {
        return moment().year() + 1;
    }
}

function isDateInLast6Month(date) {
    return isDateInRange(moment().subtract(12, 'months'), moment().subtract(6, 'months'), date)
}

export { getFormattedDateTime, getFormattedDateShort, getLastHeightWeeks, isDateInRange, getCurrentYear, isDateInLast6Month };