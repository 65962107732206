<template>
  <card class="lead-form-cell">
    <div>
      <h4 class="card-title mt-0">
        <input
          class="lead-cell-title"
          :value="lead.name"
          @keydown="onNameKeyDown"
          @input="onNameChange"
        />
      </h4>
      <div>
        <label>Mené par</label>
        <AgentSelector
          :dropup="false"
          placeholder="Mené par"
          v-model="lead.doneBy"
          type="search"
          @input="onDoneByChange"
        />
      </div>
      <div>
        <fg-input>
          <el-date-picker
            :value="lead.dateCreated"
            @input="onDateChange"
            :clearable="false"
            type="datetime"
            placeholder="Date et heure"
          >
          </el-date-picker>
        </fg-input>
        <el-select
          multiple=""
          class="select-info w-100 select-margin-bottom"
          collapse-tags=""
          :value="selects.outcome.value"
          @input="onOutcomeChanged"
          placeholder="Résultats"
        >
          <el-option
            v-for="option in selects.outcome.options"
            class="select-primary"
            :value="option.value"
            :label="option.label"
            :key="option.label"
          >
          </el-option>
        </el-select>
        <el-select
          multiple=""
          class="select-info w-100"
          collapse-tags=""
          :value="selects.contact.value"
          @input="onContactChanged"
          placeholder="Moyens de communication"
        >
          <el-option
            v-for="option in selects.contact.options"
            class="select-primary"
            :value="option.value"
            :label="option.label"
            :key="option.label"
          >
          </el-option>
        </el-select>
        <div class="form-group has-label mt-1">
          <label>Notes</label>
          <div class="textarea-notes-container">
            <textarea
              rows="5"
              class="form-control textarea-notes overflow-hidden"
              v-model="lead.notes"
              @input="onNotesChanged"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="w-100 d-flex justify-content-center">
        <n-button
          class="mt-4"
          type="danger"
          @click.native="onDeleteLeadClicked"
          @blur.native="
            () => {
              deleteConfirmation = false;
            }
          "
          >{{ deleteButtonLabel }}</n-button
        >
      </div>
    </div>
  </card>
</template>

<script>
import { Card, FormGroupInput as FgInput } from "src/components";
import { DatePicker, TimeSelect, Select, Option } from "element-ui";
import { leadOutcomes, leadContact } from "../../util/outcomes.util";
import LeadService from "../../services/lead.service";
import AgentSelector from "../AgentSelector";

export default {
  components: {
    Card,
    FgInput,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Select.name]: Select,
    [Option.name]: Option,
    AgentSelector,
  },
  computed: {
    deleteButtonLabel() {
      if (this.deleteConfirmation) return "Supprimer (Confirmez!)";
      return "Supprimer";
    },
  },
  props: {
    lead: Object,
    setError: Function,
    deleteLead: Function,
  },
  data() {
    let self = this;
    return {
      deleteConfirmation: false,
      selects: {
        outcome: {
          options: leadOutcomes,
          value: self.lead.selectedOutcomes,
        },
        contact: {
          options: leadContact,
          value: self.lead.selectedContact,
        },
      },
    };
  },
  methods: {
    onDeleteLeadClicked() {
      if (!this.deleteConfirmation) {
        this.deleteConfirmation = true;
      } else {
        this.deleteLead(this.lead.id);
      }
    },
    async onNameChange(e) {
      this.setError(null);
      this.lead.name = e.target.value;
      await LeadService.put(this.getData("name"));
    },
    async onDoneByChange(e) {
      this.setError(null);
      await LeadService.put(this.getData("doneBy"));
    },
    onNameKeyDown(e) {
      if (e.keyCode === 13) {
        e.preventDefault();
        e.target.blur();
      }
    },
    async onOutcomeChanged(newValue) {
      this.setError(null);
      this.selects.outcome.value = newValue;
      this.lead.selectedOutcomes = newValue;
      await LeadService.put(this.getData("selectedOutcomes"));
    },
    async onContactChanged(newValue) {
      this.setError(null);
      this.selects.contact.value = newValue;
      this.lead.selectedContact = newValue;
      await LeadService.put(this.getData("selectedContact"));
    },
    async onDateChange(newValue) {
      this.setError(null);
      if (!newValue) return;
      this.lead.dateCreated = newValue;
      await LeadService.put(this.getData("dateCreated"));
    },
    async onNotesChanged(event) {
      this.setError(null);
      if (!event.target.value) {
        return;
      }
      this.lead.notes = event.target.value;
      await LeadService.put(this.getData("notes"));
    },
    getData(attribute) {
      let data = {
        id: this.lead.id,
      };
      data[attribute] = this.lead[attribute];
      return data;
    },
  },
};
</script>

<style lang="scss">
.card.lead-form-cell .card-title .lead-cell-title {
  font: inherit;
  margin: inherit;
  padding: inherit;
  border: 1px solid white;
  box-sizing: border-box;
  margin: 0;
}
.select-margin-bottom {
  margin-bottom: 10px;
}

.textarea-notes {
  resize: vertical !important;
  overflow-y: auto !important;
  max-height: none !important;
}

@media screen and (max-width: 461px) {
  .card.lead-form-cell .card-title .lead-cell-title {
    max-width: 250px;
  }
}

@media screen and (max-width: 425px) {
  .card.lead-form-cell .card-title .lead-cell-title {
    max-width: 230px;
  }
}

@media screen and (max-width: 384px) {
  .card.lead-form-cell .card-title .lead-cell-title {
    max-width: 200px;
  }
}

@media screen and (max-width: 358px) {
  .card.lead-form-cell .card-title .lead-cell-title {
    max-width: 170px;
  }
}

@media screen and (max-width: 328px) {
  .card.lead-form-cell .card-title .lead-cell-title {
    max-width: 150px;
  }
}

@media screen and (max-width: 300px) {
  .card.lead-form-cell .card-title .lead-cell-title {
    max-width: 100px;
  }
}
</style>
