<template>
<footer class="footer page-login__footer position-absolute">
    <div class="container-fluid d-flex justify-content-center">
        <div class="copyright">
            © Missions commerciales de l'Université Laval. Conception: <a href="https://www.linkedin.com/in/antoineguay/" target="_blank">Antoine Guay</a> & <a href="https://www.linkedin.com/company/levioconsulting/" target="_blank">Levio</a>.
        </div>
    </div>
</footer>
</template>

<script>
export default {}
</script>

<style lang="scss">
.footer {
    bottom: 0;
    left: 0;
    right: 0;
}
</style>
