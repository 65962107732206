import axios from 'axios';
import fileDownload from 'js-file-download';

class DataService {
    constructor() {
        this.basePath = "data";
        this.cancelToken = axios.CancelToken;
        this.source = this.cancelToken.source();
    }

    cancelRequest() {
        this.source.cancel();
        this.cancelToken = axios.CancelToken;
        this.source = this.cancelToken.source();
    }

    async downloadData(payload) {
        const self = this;
        let annualRevenue = payload.annualRevenue;
        if (annualRevenue === "unknown") {
            annualRevenue = "";
        }
        let params = `?mandateGivenYears=${payload.mandateGivenYears}&meetingOutcomes=${payload.meetingOutcomes}&newsletter=${payload.newsletter}&annualRevenue=${annualRevenue}&industries=${payload.industries.join()}&markets=${payload.markets.join()}&mandateCountries=${payload.mandateCountries.join()}&outputAttributes=${payload.outputAttributes.join()}&regions=${payload.regions.join()}&outputFile=${payload.outputFile}`;
        try {
            const response = await axios.get(`${this.basePath}/download${params}`,
                { responseType: 'arraybuffer', cancelToken: self.source.token })
                .then((response) => {
                    return response;
                }
                );
            fileDownload(response.data, "data.xlsx");
        } catch (e) {
            console.log(e);
        }
    }
}

export default new DataService();