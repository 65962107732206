<template>
    <li class="follow-up-widget-step "
        :class="{ 'completed': completed, 'clickable': clickable && followUpModal, 'canceled': canceled }">
        <a @click="openModal">
            {{ label }}
        </a>
    </li>
</template>

<script>
import eventBus from '../../util/eventBus';
export default {
    props: {
        completed: {
            default: false,
            type: Boolean,
        },
        clickable: {
            default: false,
            type: Boolean,
        },
        followUpModal: Object,
        type: {
            type: String,
            default: 'lead',
            validator: function (value) {
                return ['lead', 'call', 'meeting', 'mandate'].indexOf(value) !== -1;
            },
        },
        canceled: {
            type: Boolean,
            default: false,
        },
        file: Object,
    },
    created() {
        eventBus.$on('displayFileModal', (fileId) => {
            if (this.clickable && this.followUpModal) {
                this.$store.commit('files/active', fileId);
                this.followUpModal.form = 'call';
                this.followUpModal.display = true;
            }
        })
    },
    destroyed() {
        eventBus.$off('displayFileModal')
    },
    data() {
        let label = 'Lead';
        if (this.type === 'call') {
            label = 'Appel';
        } else if (this.type === 'meeting') {
            label = 'RDV';
        } else if (this.type === 'mandate') {
            label = 'Mandat';
        }
        return {
            label: label,
        };
    },
    methods: {
        openModal() {
            if (this.clickable && this.followUpModal) {
                this.$store.commit('files/active', this.file.id);
                this.followUpModal.form = this.type;
                this.followUpModal.display = true;
            }
        },
    },
}
</script>

<style lang="scss">
$completedhover: #343a40;
$completed: #17a2b8;
$canceledhover: #fa7a50;
$canceled: #f96332;
$todo: #dddddd;

.follow-up-widget-step:first-child a {
    border-radius: 4px 0 0 4px;
    padding: 0 10px;
    padding-left: 10px;
}

.follow-up-widget-step,
.follow-up-widget-step a {
    display: inline-block;
}

.follow-up-widget-step:not(:first-child) a {
    padding-left: 20px;
}

.follow-up-widget-step+.follow-up-widget-step:before {
    padding: 0;
    content: "";
}

.follow-up-widget-step.clickable a {
    cursor: pointer;
}

.follow-up-widget-step a {
    white-space: nowrap;
    position: relative;
    color: #fff;
    text-decoration: none;
    background-color: $todo;
    border: 1px solid $todo;
    height: 36px;
    padding: 0 10px 0 25px;
    line-height: 36px;
    transition: background-color, border, 200ms ease-in-out;
}

.follow-up-widget-step a:not([href]):not([tabindex]) {
    color: #fff;
}

.follow-up-widget-step a:after,
.follow-up-widget-step a:before {
    position: absolute;
    top: -1px;
    width: 0;
    height: 0;
    content: '';
    border-top: 18px solid transparent;
    border-bottom: 18px solid transparent;
}

.follow-up-widget-step a:before {
    right: -10px;
    z-index: 3;
    border-left-color: $todo;
    border-left-style: solid;
    border-left-width: 10px;
    transition: border-left-color 200ms ease-in-out;
}

.follow-up-widget-step a:after {
    right: -11px;
    z-index: 2;
    border-left: 11px solid #fff;
}

.follow-up-widget-step.completed {

    a {
        background-color: $completed;
        border: 1px solid $completed;
    }

    a:before {
        border-left-color: $completed;
    }

    a:focus,
    a:hover {
        background-color: $completedhover;
        border: 1px solid $completedhover;
    }

    a:focus:before,
    a:hover:before {
        border-left-color: $completedhover;
    }
}

.follow-up-widget-step.canceled {

    a {
        background-color: $canceled;
        border: 1px solid $canceled;
    }

    a:before {
        border-left-color: $canceled;
    }

    a:focus,
    a:hover {
        background-color: $canceledhover;
        border: 1px solid $canceledhover;
    }

    a:focus:before,
    a:hover:before {
        border-left-color: $canceledhover;
    }
}
</style>
