<template>
    <card class="search-card">
        <div slot="header">
            <h4 class="card-title">Recherche</h4>
        </div>
        <div class="mb-3 row align-items-center">
            <fg-input class="col-lg-3 col-md-4 col-sm-6 col-12" label="Compagnie recherchée" placeholder="Compagnie recherchée"
                v-model="query.name" type="search" @keyup.enter="search(true, true)" />
            <fg-input class="col-lg-3 col-md-4 col-sm-6 col-12" label="Ville" placeholder="Ville" v-model="query.city"
                type="search" />
            <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                <label>Revenu annuel</label>
                <RevenueSelector type="search" v-model="query.annualRevenue" />
            </div>
            <n-checkbox class="col-lg-3 col-md-4 col-sm-6 col-12" v-model="query.notAssignedInt">Entreprises non assignées - commercial</n-checkbox>
        </div>
        <div class="mb-3 row d-flex align-items-center">
            <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                <label>Marchés visés</label>
                <DefaultTagWidget :dropup="false" v-model="query.markets" placeholder="Marchés"
                    :source="countriesRegionsList" />
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                <label>Industries</label>
                <DefaultTagWidget :display-suggestions-on-focus="true" :max-suggestions="null" :dropup="false"
                    v-model="query.industries" placeholder="Indust..." :source="industriesList" />
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                <label>Mandats (marchés)</label>
                <DefaultTagWidget :dropup="false" v-model="query.mandateDestinations" placeholder="Marchés"
                    :source="countriesRegionsList" />
            </div>
            <n-checkbox class="col-lg-3 col-md-4 col-sm-6 col-12" v-model="query.notAssignedCan">Entreprises non assignées - recrutement</n-checkbox>
        </div>
        <div class="mb-3 row d-flex align-items-center">
            <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                <label>Mandats (années)</label>
                <DefaultTagWidget :dropup="false" v-model="query.mandateYears" placeholder="Années" :source="years" />
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                <label class="d-block">Regions</label>
                <el-select class="select-transparent" multiple="" collapse-tags :value="regions.value"
                    @input="onRegionsChanged" placeholder="Regions">
                    <el-option v-for="option in regions.options" class="select-primary" :value="option.value"
                        :label="option.label" :key="option.value">
                    </el-option>
                </el-select>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                <label class="d-block">Dossiers Chauds</label>
                <div class="d-flex align-items-center">
                    <img v-if="query.isHotFile" class="fire-icon" src="img/fire-red.svg" @click="onIsHotFileChange" />
                    <img v-else  class="fire-icon" src="img/fire-black.svg" @click="onIsHotFileChange"/>
                    <el-select v-if="query.isHotFile" class="select-transparent ml-2" clearable multiple collapse-tags :value="query.fileYears"
                            @input="onYearsChanged" placeholder="Années">
                            <el-option v-for="option in yearSelect.options" class="select-primary" :value="option.value"
                                :label="option.label" :key="option.value">
                            </el-option>
                    </el-select>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                    <label class="d-block">Résultat d'appel/rdv</label>
                    <el-select class="select-transparent" multiple="" collapse-tags :value="query.meetingOutcomes"
                        @input="onOutcomeChanged" placeholder="ResmeetingOutcomesultats">
                        <el-option v-for="option in outcomeSelect.options" class="select-primary" :value="option.value"
                            :label="option.label" :key="option.value">
                        </el-option>
                    </el-select>
                </div>
        </div>
        <div class="mb-3 row d-flex align-items-center">
            <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                <label class="d-block">Objectives</label>
                    <el-select class="select-transparent ml-2" clearable multiple collapse-tags :value="query.objectives"
                            @input="onObjectivesChanged" placeholder="Objectives">
                            <el-option v-for="objective in missionObjectives" class="select-primary" :value="objective"
                                :label="objective" :key="objective">
                            </el-option>
                    </el-select>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                    <label class="d-block">Année</label>
                    <el-select class="select-transparent ml-2" clearable multiple collapse-tags :value="query.allFileYears"
                            @input="onAllFileYearsChanged" placeholder="Années">
                            <el-option v-for="option in yearSelect.options" class="select-primary" :value="option.value"
                                :label="option.label" :key="option.value">
                            </el-option>
                    </el-select>
                </div>
        </div>
        <div class="row d-flex justify-content-center">
            <n-button type="primary" round="" :disabled="loading" @click.native="search(true, true)">Rechercher<i
                    class="now-ui-icons ui-1_zoom-bold ml-2"></i></n-button>
        </div>
    </card>
</template>

<script>
import { Card, Checkbox, Button, FormGroupInput as FgInput } from 'src/components';
import DefaultTagWidget from '../../components/DefaultTagWidget';
import countriesRegionsList from '../../util/countries.regions.util';
import industriesList from '../../util/industries.util';
import RevenueSelector from '../../components/RevenueSelector';
import regionsMixins from '../../mixins/regions.mixins';
import selectYearMixins from '../../mixins/selectYear.mixins'
import outcomesMixins from '../../mixins/outcomes.mixins';
import { missionObjectives } from '../../util/objectives.util';

let years = [];
for (let i = 1997; i <= (new Date()).getFullYear() + 1; i++) {
    years.push(i.toString());
}

export default {
    mixins: [regionsMixins, selectYearMixins,outcomesMixins],
    components: {
        Card,
        [Checkbox.name]: Checkbox,
        [Button.name]: Button,
        FgInput,
        DefaultTagWidget,
        RevenueSelector
    },
    props: {
        query: Object,
        loading: Boolean,
        search: Function,
    },
    data() {
        return {
            countriesRegionsList: countriesRegionsList,
            industriesList: industriesList,
            years: years,
            missionObjectives
        }
    },
    created() {
        this.yearSelect.options.reverse()
    },
    methods: {
        onRegionsChanged(newValue) {
            this.regions.value = newValue;
            this.query.regions = newValue
        },
        onIsHotFileChange() {
            this.query.isHotFile = !this.query.isHotFile;
        },
        onYearsChanged(newValue) {
            this.query.fileYears = newValue
        },
        onOutcomeChanged(newValue) {
            this.query.meetingOutcomes = newValue
        },
        onObjectivesChanged(newValue) {
            this.query.objectives = newValue
        },
        onAllFileYearsChanged(newValue) {
            this.query.allFileYears = newValue
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/sass/select-transparent.scss";

.search-card {
    .form-check {
        padding-left: 15px !important;
    }
}

:deep .el-input {
    input {
        margin: 0 !important;
    }
}

:deep .el-select {
    width: 100%;
}

:deep .el-tag {
    margin-top: 4px !important;
}

.fire-icon {
    height: 20px;
    cursor: pointer;
}

:deep .el-tag {
    width: 75% !important;
}

:deep .el-tag~.el-tag {
    width: 10% !important;
    margin-right: 0px !important;
}
</style>
