var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{staticClass:"follow-up-modal",attrs:{"show":_vm.followUpModal.display,"header-classes":"justify-content-center"},on:{"update:show":function($event){return _vm.$set(_vm.followUpModal, "display", $event)}}},[_c('div',{staticClass:"title title-up d-flex flex-column align-items-center",attrs:{"slot":"header"},slot:"header"},[(_vm.file)?_c('div',{staticClass:"row mb-2 programme-type"},[_c('h5',{staticClass:"mb-0 mr-3"},[_vm._v("Programme:")]),(_vm.file.type === 'CAN')?_c('img',{staticClass:"programme-icon cursor-pointer",attrs:{"src":"img/commerciale-inactive.png"},on:{"click":function($event){return _vm.changeFileType('INT')}}}):_vm._e(),(_vm.file.type === 'INT')?_c('img',{staticClass:"programme-icon",attrs:{"src":"img/commerciale-active.png"},on:{"click":function($event){return _vm.changeFileType('INT')}}}):_vm._e(),(_vm.file.type === 'INT')?_c('img',{staticClass:"programme-icon cursor-pointer",attrs:{"src":"img/recrutement-inactive.png"},on:{"click":function($event){return _vm.changeFileType('CAN')}}}):_vm._e(),(_vm.file.type === 'CAN')?_c('img',{staticClass:"programme-icon",attrs:{"src":"img/recrutement-active.png"},on:{"click":function($event){return _vm.changeFileType('CAN')}}}):_vm._e()]):_vm._e(),(_vm.file)?_c('h6',{staticClass:"m-0 title__account-name text-truncate font-weight-normal"},[_vm._v(" "+_vm._s(_vm.file.account.name)+" ")]):_vm._e(),_c('h4',{staticClass:"m-0 font-weight-bold"},[_vm._v(_vm._s(_vm.title))])]),_c('div',{staticClass:"d-flex align-items-center position-relative"},[(
        (_vm.file && !_vm.file.leadId && _vm.followUpModal.form === 'lead') ||
        _vm.followUpModal.form !== 'lead'
      )?_c('n-button',{staticClass:"addElement",attrs:{"type":"success","round":"","icon":""},nativeOn:{"click":function($event){return _vm.addElement.apply(null, arguments)}}},[_c('i',{staticClass:"now-ui-icons ui-1_simple-add"})]):_vm._e(),_c('div',{staticClass:"mr-3"},[_c('i',{staticClass:"now-ui-icons arrows-1_minimal-left",class:{ 'cursor-pointer': _vm.displayLeftArrow, 'text-white': !_vm.displayLeftArrow },on:{"click":_vm.decrement}})]),_c('div',{staticClass:"flex-grow-1"},[(_vm.followUpModal.form === 'lead')?_c('LeadForm',{ref:"LeadFormRef",attrs:{"leads":_vm.leads,"loading":_vm.loading,"error":_vm.error,"set-error":_vm.setError,"delete-lead":_vm.deleteLead}}):_vm._e(),(_vm.followUpModal.form === 'call')?_c('CallForm',{ref:"CallFormRef",attrs:{"calls":_vm.calls,"loading":_vm.loading,"error":_vm.error,"set-error":_vm.setError,"delete-call":_vm.deleteCall}}):_vm._e(),(_vm.followUpModal.form === 'meeting')?_c('MeetingForm',{ref:"MeetingFormRef",attrs:{"meetings":_vm.meetings,"loading":_vm.loading,"error":_vm.error,"set-error":_vm.setError,"delete-meeting":_vm.deleteMeeting,"update-canceled-flag":_vm.updateCanceledFlag,"update-virtual-flag":_vm.updateVirtualFlag,"delegations":_vm.delegations}}):_vm._e(),(_vm.followUpModal.form === 'mandate')?_c('MandateForm',{ref:"MandateFormRef",attrs:{"mandates":_vm.mandates,"loading":_vm.loading,"error":_vm.error,"set-error":_vm.setError,"delete-mandate":_vm.deleteMandate}}):_vm._e()],1),_c('div',{staticClass:"ml-3"},[_c('i',{staticClass:"now-ui-icons arrows-1_minimal-right",class:{
          'cursor-pointer': _vm.displayRightArrow,
          'text-white': !_vm.displayRightArrow,
        },on:{"click":_vm.increment}})])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }