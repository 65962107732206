<template>
<div class="year-selector position-relative show">
    <fg-input placeholder="Année" :value="year" @input="onInput" @focus="focused = true" @blur="onblur" @keydown="onKeyDown"></fg-input>
    <ul class="dropdown-menu show" v-if="suggestions.length > 0 && focused">
        <a class="dropdown-item cursor-pointer" v-for="(suggestion, index) in suggestions" :key="index" @mousedown="onSuggestionSelect(suggestion)">
            {{suggestion}}
        </a>
    </ul>
</div>
</template>

<script>
import {FormGroupInput as FgInput} from 'src/components';
import {DropDown} from 'src/components';

const maxSuggestions = 5;
let years = [];
for (let i = 1997; i <= (new Date()).getFullYear() + 1; i++) {
    years.push(i);
}

export default {
    components: {
        FgInput,
        DropDown,
    },
    watch: {
        focused(newValue) {
            if (!newValue) {
                this.currentFocus = -1;
            }
        },
    },
    props: {
        value: String,
    },
    data() {
        return {
            currentFocus: -1,
            suggestions: [],
            year: '',
            focused: false,
        };
    },
    created() {
        this.setYearFromValue();
    },
    methods: {
        onKeyDown(e) {
            const suggestions = this.$el.querySelectorAll('.dropdown-menu .dropdown-item');
            if (e.keyCode == 40) { //down
                e.preventDefault();
                this.currentFocus++;
                this.addActive(suggestions);
            } else if (e.keyCode == 38) { //up
                e.preventDefault();
                this.currentFocus--;
                this.addActive(suggestions);
            } else if (e.keyCode == 13) { //enter
                e.preventDefault();
                if (this.currentFocus > -1) {
                    if (suggestions) this.onSuggestionSelect(this.suggestions[this.currentFocus]);
                }
            }
        },
        addActive(suggestions) {
            this.removeActive(suggestions);
            if (this.currentFocus >= suggestions.length) this.currentFocus = 0;
            if (this.currentFocus < 0) this.currentFocus = (suggestions.length - 1);
            suggestions[this.currentFocus].classList.add("autocomplete-active");
        },
        removeActive(suggestions) {
            for (let i = 0; i < suggestions.length; i++) {
                suggestions[i].classList.remove("autocomplete-active");
            }
        },
        setYearFromValue() {
            this.year = this.value;
        },
        onblur() {
            this.focused = false;
            this.suggestions = [];
            this.setYearFromValue();
        },
        onInput(val) {
            this.focused = true;
            this.year = val;
            let self = this;
            this.currentFocus = -1;
            this.removeActive(this.$el.querySelectorAll('.dropdown-menu .dropdown-item'));
            this.suggestions = this.$_.first(self.$_.filter(years, function(num) { 
                return num.toString().includes(val.toString()); 
            }), maxSuggestions);
        },
        onSuggestionSelect(year) {
            this.$emit('input', year.toString());
            this.focused = false;
            this.year = year;
            this.suggestions = [];
        },
    },
}
</script>

<style lang="scss">
.year-selector {
    .form-group {
        input:focus {
            border: 1px solid #E3E3E3;
        }

        &::after {
            content: '';
        }
    }

    .autocomplete-active {
        background-color: hsla(0,0%,87.1%,.3) !important;
    }
}
</style>
