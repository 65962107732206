<template>
  <router-view></router-view>
</template>

<script>
export default {};
</script>

<style lang="scss">
@import './src/assets/sass/global.scss'; 
@import "./src/assets/sass/admin.scss";
</style>

