import UserService from '../services/user.service';

export default {
    data() {
        return {
            enabledUsers: [],
            commercialUsers: [],
            recurtementUsers: [],
            allUsers: [],
            loading: false,
        };
    },
    methods: {
        async fetchEnabledUsers() {
            this.enabledUsers = await UserService.findEnabled();

            const filterByType = (user, type) => user.roles.indexOf(type) > -1;

            this.commercialUsers = this.enabledUsers.filter(user => filterByType(user, "AGENT_INT"));
            this.recurtementUsers = this.enabledUsers.filter(user => filterByType(user, "AGENT_CAN"));
        },
        async fetchAllUsers(agentsOnly) {
            this.allUsers = await UserService.findAll();


            if (agentsOnly) {
                this.allUsers = this.$_.reject(this.allUsers, function(user) {
                    return (user.roles.indexOf('ADMIN') > -1) || (user.roles.indexOf('COORDO_INT') > -1)
                    || (user.roles.indexOf('COORDO_CAN') > -1);
                });
            }
        },
        async post(data) {
            const newUser = await UserService.post(data);
            if (newUser.enabled) {
                this.enabledUsers.push(newUser);
            }
            this.allUsers.push(newUser);
            this.$notify({
                message: `Utilisateur '${newUser.username}' ajouté!`,
                type: 'success',
                timeout: 3000,
                horizontalAlign: 'right',
                verticalAlign: 'top',
            });
        },
        async put(data, attribute) {
            await UserService.put(data);
            const userId = data.id;
            const enabledUser = this.$_.findWhere(this.enabledUsers, {id: userId});
            enabledUser[attribute] = data[attribute];
            const allUser = this.$_.findWhere(this.allUsers, {id: userId});
            allUser[attribute] = data[attribute];
            if (this.delegation1Users) {
                const delegation1User = this.$_.findWhere(this.delegation1Users, {id: userId});
                if (delegation1User) {
                    delegation1User[attribute] = data[attribute];
                    return;
                }
                const delegation2User = this.$_.findWhere(this.delegation2Users, {id: userId});
                if (delegation2User) {
                    delegation2User[attribute] = data[attribute];
                    return;
                }
                const delegation3User = this.$_.findWhere(this.delegation3Users, {id: userId});
                if (delegation3User) {
                    delegation3User[attribute] = data[attribute];
                }
            }
        },
        async pauseUser(userId) {
            const data = {
                id: userId,
                enabled: false,
            };
            await UserService.put(data);
            this.enabledUsers = this.$_.without(this.enabledUsers, this.$_.findWhere(this.enabledUsers, {id: userId}));
            const user = this.$_.findWhere(this.allUsers, {id: userId});
            user.enabled = false;
        },
        async playUser(userId) {
            const data = {
                id: userId,
                enabled: true,
                accountLocked: false,
                failedLoginAttempts: 0,
            };
            const newUser = await UserService.put(data);
            this.enabledUsers.push(newUser);
            const user = this.$_.findWhere(this.allUsers, {id: userId});
            user.enabled = true;
        },
        async deleteUser(userId) {
            await UserService.delete(userId);
            this.enabledUsers = this.$_.without(this.enabledUsers, this.$_.findWhere(this.enabledUsers, {id: userId}));
            this.allUsers = this.$_.without(this.allUsers, this.$_.findWhere(this.allUsers, {id: userId}));
        }
    }
};
