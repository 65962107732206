import ApiService from './api.service';

class MandateService {

    constructor() {
        this.basePath = "mandates";
        this.fullSelector = `(
            year,
            destination,
            summary,
            followUp,
            notesAdmin,
            confidentialDetails,
            objectives,
            agentId,
            clientId,
            fileId
        )`;
    }

    async getSummary(year) {
        try {
            const response = await ApiService.get(`${this.basePath}/summary?year=${year}`);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    }

    async getAll(mandateIds) {
        try {
            const response = await ApiService.get(`${this.basePath}?mandateIds=${mandateIds.join()}&selector=${this.fullSelector.replace(/\s/g, '')}`);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    }

    async put(data) {
        try {
            const response = await ApiService.put(`${this.basePath}?selector=${this.fullSelector}`, data);
            return response.data;
        } catch (e) {
            throw e;
        }
    }

    async post(mandate, bypass) {
        if (!bypass) {
            bypass = false;
        } else {
            bypass = true;
        }
        try {
            const response = await ApiService.post(`${this.basePath}?selector=${this.fullSelector}&bypass=${bypass}`, mandate);
            return response.data;
        } catch (e) {
            throw e;
        }
    }

    async delete(mandateId) {
        try {
            await ApiService.delete(`${this.basePath}/${mandateId}`);
        } catch (e) {
            throw e;
        }
    }

}

export default new MandateService();