<template>
<div class="panel-header panel-header-lg">
    <h6 class="position-absolute text-light ml-3 mt-3" style="top: 0;">Mandats</h6>
    <Spinner size="4" v-if="loading"/>
    <header-chart :height="255" :data="chartData" :labels="chartLabels" v-if="!loading">
    </header-chart>
</div>
</template>

<script>
import HeaderChart from './HeaderChart';
import MandateService from '../../services/mandate.service';
import {getCurrentYear} from '../../util/time.util';
import {Spinner} from 'src/components';

export default {
    components: {
        HeaderChart,
        Spinner,
    },
    async created() {
        this.loading = true;
        this.chartData = await MandateService.getSummary(getCurrentYear());
        this.loading = false;
    },
    data() {
        return {
            loading: true,
            chartData: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            chartLabels: [
                'OCT',
                'OCT',
                'NOV',
                'NOV',
                'DEC',
                'DEC',
                'JAN',
                'JAN',
                'FEV',
                'FEV',
                'MAR',
                'MAR'
            ],
        };
    }
}
</script>

<style lang="scss">

</style>
