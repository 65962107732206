<template>
<div>
    <h3 class="mb-1">{{label1}}</h3>
    <div class="mb-1 d-flex justify-content-center">
        <n-pagination :page-count="pageCountEnabled" :value="currentPageEnabled" @input="pageChangedEnabled"></n-pagination>
    </div>
    <table cellspacing="0" cellpadding="0" border="0" class="w-100 custom-table">
        <thead class="has-gutter">
            <tr class="row">
                <th rowspan="1" colspan="1" class="col-9 col-md-4 col-lg-3">Username</th>
                <th rowspan="1" colspan="1" class="d-none d-md-flex col-md-5 col-lg-4">Prénom</th>
                <th rowspan="1" colspan="1" class="d-none d-lg-flex col-lg-3">Nom</th>
                <th rowspan="1" colspan="1" class="col-3 col-lg-2"></th>
            </tr>
        </thead>
        <tbody>
            <tr class="row body"
            v-for="(user, index) in pageResultsEnabled"
            :key="index"
            @click="displayModal(user)"
            @contextmenu.prevent="$refs.menu.open($event, {user: user})">
                <td rowspan="1" colspan="1" class="col-9 col-md-4 col-lg-3">{{user.username}}</td>
                <td rowspan="1" colspan="1" class="d-none d-md-flex col-md-5 col-lg-4">{{user.firstName}}</td>
                <td rowspan="1" colspan="1" class="d-none d-lg-flex col-lg-3">{{user.lastName}}</td>
                <td rowspan="1" colspan="1" class="col-3 col-lg-2">
                    <n-button @click.native.stop="pauseUser(user.id)" type="warning" round="" icon="" class="mt-0 mb-0">
                        <i class="now-ui-icons media-1_button-pause"></i>
                    </n-button>
                </td>
            </tr>
        </tbody>
    </table>
    <h3 class="mb-1 mt-5">{{label2}}</h3>
    <div class="mb-1 d-flex justify-content-center">
        <n-pagination :page-count="pageCountAll" :value="currentPageAll" @input="pageChangedAll"></n-pagination>
    </div>
    <div class="mb-1 d-flex justify-content-center">
        <fg-input placeholder="Filtrer" v-model="filter"></fg-input>
    </div>
    <table cellspacing="0" cellpadding="0" border="0" class="w-100 custom-table">
        <thead class="has-gutter">
            <tr class="row">
                <th rowspan="1" colspan="1" class="col-10 col-sm-8 col-md-5 col-lg-6">Nom complet et username</th>
                <th rowspan="1" colspan="1" class="d-none d-md-flex col-md-3 col-lg-2">Activé</th>
                <th rowspan="1" colspan="1" class="d-none d-sm-flex col-2"></th>
                <th rowspan="1" colspan="1" class="col-2"></th>
            </tr>
        </thead>
        <tbody>
            <tr class="row body" v-for="(user, index) in pageResultsAll" :key="index" @click="displayModal(user)">
                <td rowspan="1" colspan="1" class="col-10 col-sm-8 col-md-5 col-lg-6">{{user.firstName}} {{user.lastName}} ({{user.username}})</td>
                <td rowspan="1" colspan="1" class="d-none d-md-flex col-md-3 col-lg-2">
                    <i class="now-ui-icons ui-1_check" v-if="user.enabled"></i>
                </td>
                <td rowspan="1" colspan="1" class="d-none d-sm-flex col-2">
                    <n-button @click.native.stop="deleteUser(user.id)" type="danger" round="" icon="" class="mt-0 mb-0">
                        <i class="fa fa-trash"></i>
                    </n-button>
                </td>
                <td rowspan="1" colspan="1" class="col-2">
                    <n-button @click.native.stop="pauseUser(user.id)" type="warning" round="" icon="" class="mt-0 mb-0" v-if="user.enabled">
                        <i class="now-ui-icons media-1_button-pause"></i>
                    </n-button>
                    <n-button @click.native.stop="playUser(user.id)" type="success" round="" icon="" class="mt-0 mb-0" v-if="!user.enabled">
                        <i class="now-ui-icons media-1_button-play"></i>
                    </n-button>
                </td>
            </tr>
        </tbody>
    </table>
    <modal :show.sync="showModal" header-classes="justify-content-center">
        <h4 slot="header" class="title title-up">Modifier l'utilisateur</h4>
        <div v-if="modalUser">
            <div class="row">
                <fg-input
                    class="col-12 col-md-6"
                    label="Prénom"
                    placeholder="Prénom"
                    v-model="modalUser.firstName"
                    :debounce="300"
                    @save="save('firstName')"/>
                <fg-input
                    class="col-12 col-md-6"
                    label="Nom"
                    placeholder="Nom"
                    v-model="modalUser.lastName"
                    :debounce="300"
                    @save="save('lastName')"/>
            </div>
            <div class="row">
                <fg-input
                    class="col-12 col-md-6"
                    label="Username"
                    placeholder="Username"
                    v-model="modalUser.username"
                    :debounce="300"
                    @save="save('username')"/>
                <div class="col-12 col-md-6 d-flex align-items-center" v-if="!hideCheckboxes">
                    <n-checkbox :checked="hasRole('ADMIN')" @input="e => rolesChanged('ADMIN', e)">
                        Admin
                    </n-checkbox>
                </div>
            </div>
            <div class="row password-section" v-if="!hideCheckboxes">
                <fg-input
                    class="col-12 col-md-6"
                    label="Nouveau MDP"
                    placeholder="Nouveau MDP"
                    v-model="newPassword"/>
                <div class="col-12 col-md-6 d-flex align-items-center justify-content-start">
                    <n-button type="danger" @click.native="changePassword" :disabled="newPassword === ''">Changer MDP</n-button>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6">
                    <label class="d-block">Territoire</label>
                    <el-select class="select-transparent select-territoire" multiple="" collapse-tags v-model="modalUser.territory" placeholder="Territoire" @input="onTerritoireChanged">
                        <el-option v-for="option in territoire" class="select-primary" :value="option.value" :label="option.label" :key="option.value">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="row" v-if="!(modalUser.delegationId && modalUser.delegationId !== '') && !(modalUser.permanenceId && modalUser.permanenceId !== '')">
                <div class="col-12 col-md-6 d-flex align-items-center" >
                    <n-checkbox :checked="hasRole('AGENT_CAN')" @input="e => rolesChanged('AGENT_CAN', e)">
                       Agent de recrutement
                    </n-checkbox>
                </div>
                <div class="col-12 col-md-6 d-flex align-items-center" >
                    <n-checkbox :checked="hasRole('AGENT_INT')" @input="e => rolesChanged('AGENT_INT', e)">
                        Agent commercial
                    </n-checkbox>
                </div>
            </div>
            <div class="row" v-if="(modalUser.delegationId && modalUser.delegationId !== '') || (modalUser.permanenceId && modalUser.permanenceId !== '')">
                <div class="col-12">Cet(te) agent(e) fait partie d'une délégation et/ou d'une permanence. Il/elle ne peut pas changer de programme.</div>
            </div>
            <div class="row" v-if="!hideCheckboxes">
                <div class="col-12 col-md-6 d-flex align-items-center" >
                    <n-checkbox :checked="hasRole('COORDO_CAN')" @input="e => rolesChanged('COORDO_CAN', e)">
                        Coordo - Recrutement
                    </n-checkbox>
                </div>
                <div class="col-12 col-md-6 d-flex align-items-center" >
                    <n-checkbox :checked="hasRole('COORDO_INT')" @input="e => rolesChanged('COORDO_INT', e)">
                        Coordo - Commercial
                    </n-checkbox>
                </div>
            </div>
        </div>
        <template slot="footer">
            <div></div>
            <n-button type="danger" @click.native="showModal = false">Fermer</n-button>
        </template>
    </modal>
    <modal :show.sync="showAddModal.display" header-classes="justify-content-center">
        <h4 slot="header" class="title title-up">Ajouter un utilisateur</h4>
        <Spinner size="4" v-if="showAddModal.loading"/>
        <div v-if="!showAddModal.loading">
            <div class="row">
                <fg-input
                    class="col-12 col-md-6"
                    label="Username"
                    placeholder="Username"
                    v-model="newUser.username"/>
                <fg-input
                    class="col-12 col-md-6"
                    label="Mot de passe"
                    placeholder="Mot de passe"
                    v-model="newUser.password"/>
            </div>
            <div class="row">
                <fg-input class="col-12 col-md-6" label="Prénom" placeholder="Prénom" v-model="newUser.firstName"/>
                <fg-input class="col-12 col-md-6" label="Nom" placeholder="Nom" v-model="newUser.lastName"/>
            </div>
            <div class="row align-items-start">
                <div class="col-12 col-md-6">
                    <label class="d-block">Territoire</label>
                    <el-select class="select-transparent select-territoire" multiple="" collapse-tags v-model="newUser.territoire" placeholder="Territoire">
                        <el-option v-for="option in territoire"
                            class="select-primary" :value="option.value" :label="option.label" :key="option.value">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6 d-flex align-items-center" >
                    <n-checkbox v-model="newUser.enabled">Activé</n-checkbox>
                </div>
                <div class="col-12 col-md-6 d-flex align-items-center" v-if="!hideCheckboxes">
                    <n-checkbox :checked="hasRole('ADMIN', true)" @input="e => rolesChanged('ADMIN', e, true)">
                        Admin
                    </n-checkbox>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6 d-flex align-items-center" >
                    <n-checkbox :checked="hasRole('AGENT_CAN', true)" @input="e => rolesChanged('AGENT_CAN', e, true)">
                        Agent de recrutement
                    </n-checkbox>
                </div>
                <div class="col-12 col-md-6 d-flex align-items-center" >
                    <n-checkbox :checked="hasRole('AGENT_INT', true)" @input="e => rolesChanged('AGENT_INT', e, true)">
                        Agent commercial
                    </n-checkbox>
                </div>
            </div>
            <div class="row" v-if="!hideCheckboxes">
                <div class="col-12 col-md-6 d-flex align-items-center" >
                    <n-checkbox :checked="hasRole('COORDO_CAN', true)" @input="e => rolesChanged('COORDO_CAN', e, true)">
                        Coordo - Recrutement
                    </n-checkbox>
                </div>
                <div class="col-12 col-md-6 d-flex align-items-center" >
                    <n-checkbox :checked="hasRole('COORDO_INT', true)" @input="e => rolesChanged('COORDO_INT', e, true)">
                        Coordo - Commercial
                    </n-checkbox>
                </div>
            </div>
        </div>
        <template slot="footer">
            <div></div>
            <n-button type="success" @click.native="add">Ajouter!</n-button>
        </template>
    </modal>
    <FloatingButtonGroup :buttons="[{
        icon: 'now-ui-icons ui-1_simple-add',
        color: 'success',
        tooltip: 'Ajouter un utilisateur',
        onClick: () => {
            newUser = {enabled: true, roles: defaultRoles, territoire: [ 'QC' ]};
            showAddModal.display = true;
        }
    }]"/>
    <vue-context ref="menu">
        <template slot-scope="menuscope">
            <li>
                <a href="#" @click.prevent="removeShotgunsFromUser(menuscope.data.user)">
                    <span class="mr-1" v-if="menuscope.data">Enlever tous les shotguns à {{menuscope.data.user.firstName}} {{menuscope.data.user.lastName}}</span>
                    <i class="now-ui-icons arrows-1_refresh-69 ml-1"></i>
                </a>
            </li>
        </template>
    </vue-context>
</div>
</template>

<script>
import {
    Pagination,
    FormGroupInput as FgInput,
    Modal,
    Checkbox,
    Spinner,
} from 'src/components';
import { Select, Option, Tooltip } from 'element-ui'
import {normalizeLowerCaseString} from '../../util/string.util';
import FloatingButtonGroup from '../../components/FloatingButton/FloatingButtonGroup';
import { VueContext } from 'vue-context';
import UserService from '../../services/user.service';

export default {
    components: {
        [Pagination.name]: Pagination,
        FgInput,
        Modal,
        [Checkbox.name]: Checkbox,
        FloatingButtonGroup,
        Spinner,
        VueContext,
        [Select.name]: Select,
        [Option.name]: Option,
        [Tooltip.name]: Tooltip,
    },
    watch: {
        enabledUsers() {
            if (this.currentPageEnabled > this.pageCountEnabled) {
                this.pageChangedEnabled(1);
            }
        },
        allUsers: Array,
        enabledUsers() {
            if (this.currentPageAll > this.pageCountAll) {
                this.pageChangedAll(1);
            }
        }
    },
    computed: {
        sortedUsersAll() {
            const filter = normalizeLowerCaseString(this.filter);
            const filteredResults = this.$_.filter(this.allUsers, function(el) {
                const elName = normalizeLowerCaseString(`${el.firstName} ${el.lastName} ${el.username}`);
                return elName.includes(filter);
            });
            return this.$_.sortBy(filteredResults, (el) => normalizeLowerCaseString(`${el.firstName} ${el.lastName}`));
        },
        pageCountAll() {
            if (!this.sortedUsersAll) return 1;
            return Math.ceil(this.sortedUsersAll.length / this.pageSize);
        },
        pageResultsAll() {
            let startIndex = ((this.currentPageAll - 1) * this.pageSize);
            if (startIndex >= this.sortedUsersAll.length) {
                startIndex = this.sortedUsersAll.length - 1;
            }
            let endIndex = (this.currentPageAll * this.pageSize);
            if (endIndex > this.sortedUsersAll.length) {
                endIndex = this.sortedUsersAll.length;
            }
            return this.sortedUsersAll.slice(startIndex, endIndex);
        },
        sortedUsersEnabled() {
            return this.$_.sortBy(this.enabledUsers, (el) => normalizeLowerCaseString(el.username));
        },
        pageCountEnabled() {
            if (!this.sortedUsersEnabled) return 1;
            return Math.ceil(this.sortedUsersEnabled.length / this.pageSize);
        },
        pageResultsEnabled() {
            let startIndex = ((this.currentPageEnabled - 1) * this.pageSize);
            if (startIndex >= this.sortedUsersEnabled.length) {
                startIndex = this.sortedUsersEnabled.length - 1;
            }
            let endIndex = (this.currentPageEnabled * this.pageSize);
            if (endIndex > this.sortedUsersEnabled.length) {
                endIndex = this.sortedUsersEnabled.length;
            }
            return this.sortedUsersEnabled.slice(startIndex, endIndex);
        },
    },
    props: {
        enabledUsers: Array,
        allUsers: Array,
        pauseUser: Function,
        playUser: Function,
        deleteUser: Function,
        put: Function,
        post: Function,
        label1: {
            type: String,
            default: "Comptes activés:"
        },
        label2: {
            type: String,
            default: "Tous les comptes:"
        },
        hideCheckboxes: {
            type: Boolean,
            default: false
        },
        defaultRoles: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    data() {
        return {
            filter: "",
            newPassword: "",
            pageSize: 10,
            currentPageEnabled: 1,
            currentPageAll: 1,
            showModal: false,
            modalUser: null,
            showAddModal: {
                loading: false,
                display: false,
            },
            newUser: {
                enabled: true,
                roles: [],
                territoire: [ 'QC' ]
            },
            territoire: [
                { value: 'QC' , label: 'Québec' },
                { value: 'CA' , label: 'Canada' },
                { value: 'US' , label: 'USA' },
            ],
        }
    },
    methods: {
        async changePassword() {
            if (!/\S/.test(this.newPassword)) {
                return;
            }
            const model = {
                originalPassword: '',
                newPassword: this.newPassword,
                newPasswordConfirmation: this.newPassword,
                id: this.modalUser.id
            };
            try {
                await UserService.changePassword(model, true);
                this.$notify({
                    message: `Mot de passe changé!`,
                    type: 'success',
                    timeout: 4000,
                    horizontalAlign: 'right',
                    verticalAlign: 'top',
                });
                this.newPassword = "";
            } catch (e) {
                alert("Une erreur s'est produite! Le mot de passe n'a pas pu être changé. Assurez-vous d'avoir entré le bon mot de passe actuel.");
            }
        },
        async removeShotgunsFromUser(user) {
            await UserService.removeShotguns(user.id);
        },
        async add() {
            this.showAddModal.loading = true;
            await this.post(this.newUser);
            this.newUser = {
                enabled: true,
                password: this.newUser.password,
                roles: this.newUser.roles,
                territoire: this.newUser.territoire
            };
            this.showAddModal.loading = false;
        },
        hasRole(role, isNewUser) {
            let collection = this.modalUser;
            if (isNewUser) {
                collection = this.newUser;
            }
            return collection.roles.indexOf(role) >= 0;
        },
        rolesChanged(role, added, toNewUser) {
            let collection = this.modalUser;
            if (toNewUser) {
                collection = this.newUser;
            }
            const index = collection.roles.indexOf(role);
            if (index >= 0) {
                collection.roles.splice(index, 1);
            } else {
                collection.roles.push(role)
            }

            if (!toNewUser) {
                this.save("roles");
            }
        },
        save(attribute, value) {
            const data = {
                id: this.modalUser.id,
            };
            data[attribute] = this.modalUser[attribute];
            this.put(data, attribute);
        },
        displayModal(user) {
            this.modalUser = user;
            this.showModal = true;
        },
        pageChangedEnabled(newValue) {
            this.currentPageEnabled = newValue;
        },
        pageChangedAll(newValue) {
            this.currentPageAll = newValue;
        },
        onTerritoireChanged(territoire) {
            console.log(this.modalUser)
            this.save('territory');
        },
    },
}
</script>

<style lang="scss" scoped>
@import "assets/sass/select-transparent.scss";
.password-section {
    border: 1px solid lightgrey;
    border-radius: 5px;
}
.select-territoire {
    width: 100%;
    height: 44px;
}

:deep .el-input__inner {
    margin: 0px 1px !important;
    padding: 0px 22px !important;
}

:deep .el-select__tags {
    top: 32% !important;
    margin-top: 3px !important;
}

:deep .el-tag {
    margin-top: 2px !important;
}
</style>
