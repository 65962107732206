<template>
    <card class="card-chart" no-footer-line>
        <div slot="header">
            <h5 class="card-category">{{ title }}</h5>
            <h2 class="card-title">
                <animated-number :value="count"></animated-number>
            </h2>
        </div>
        <div class="chart-area">
            <line-chart v-if="!loading" :labels="data.labels" :data="data.values" :color="color" :height="200">
            </line-chart>
            <Spinner size="4" v-if="loading" />
        </div>
        <div slot="footer" class="stats">
            <i class="refresh now-ui-icons arrows-1_refresh-69" @click="update"></i> Mettre à jour
        </div>
    </card>
</template>

<script>
import {
    Card,
    AnimatedNumber,
    Spinner
} from 'src/components';
import LineChart from 'src/components/Charts/LineChart';

export default {
    components: {
        Card,
        AnimatedNumber,
        LineChart,
        Spinner,
    },
    computed: {
        count() {
            if (this.loading) return 0;
            return this.data.values.reduce((total, currentValue) => total + currentValue);
        },
    },
    props: {
        title: String,
        color: String,
        data: Object,
        loading: Boolean,
        update: Function,
    },
}
</script>

<style lang="scss" scoped>
.card-chart {
    height: 95%;
    display: flex;

    .refresh {
        cursor: pointer;
    }
}
</style>
