<template>
    <div>
        <section class="mb-5">
            <h3>Dossiers chauds</h3>
            <div class="hot-file-action mb-5">
                <div class="pagination">
                    <n-pagination :page-count="pageCountEnabled" :value="currentPageEnabled"
                        @input="pageChangedEnabled">
                    </n-pagination>
                </div>
                <div class="filter">
                    <fg-input placeholder="Filtrer" v-model="filter"></fg-input>
                </div>
                <div class="years">
                    <el-select key="randomKey" class="select-danger" placeholder="Année" v-model="yearSelect.value"
                        @input="onYearChange('currentFile')">
                        <el-option v-for="option in yearSelect.options" class="select-danger" :value="option.value"
                            :label="option.label" :key="option.label">
                        </el-option>
                    </el-select>
                </div>
                <div class="d-flex assigned">
                    <n-radio class="mr-1 mt-0" name="hotFile" label="assigned" @input="onFilterFileChange">
                        Assigné
                    </n-radio>
                    <n-radio class="mr-1 mt-0" name="hotFile" label="notAssigned" @input="onFilterFileChange">Non
                        assigné
                    </n-radio>
                    <n-radio class="mr-1 mt-0" name="hotFile" label="allFile" @input="onFilterFileChange">
                        Tous
                    </n-radio>
                </div>

            </div>
            <Spinner size="4" v-if="loading" />
            <table v-if="!loading && pageResultsEnabled.length > 0" cellspacing="0" cellpadding="0" border="0"
                class="w-100 custom-table">
                <thead class="has-gutter">
                    <tr class="row">
                        <th rowspan="1" colspan="1" class="col-5 col-md-4 col-lg-2">Entreprise</th>
                        <th rowspan="1" colspan="1" class="d-none d-lg-flex col-lg-2">Date</th>
                        <th rowspan="1" colspan="1" class="d-none d-lg-flex col-lg-2">Provenance</th>
                        <th rowspan="1" colspan="1" class="col-5 col-md-4 col-lg-2">Coordonnateur assigné</th>
                        <th rowspan="1" colspan="1" class="d-none d-lg-flex col-lg-2">Qualification</th>
                        <th rowspan="1" colspan="1" class="col-1 col-lg-1"></th>
                        <th rowspan="1" colspan="1" class="col-1 col-lg-1"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="row body" v-for="(file, index) in pageResultsEnabled"  @click="goToAccountPage(file)" :key="file.id">
                        <td rowspan="1" colspan="1" class="col-5 col-md-4 col-lg-2"> {{ file | companyName }}</td>
                        <td rowspan="1" colspan="1" class="d-none d-lg-flex col-lg-2"> {{ file.year }}</td>
                        <td rowspan="1" colspan="1" class="d-none d-lg-flex col-lg-2">
                            <el-select class="select-primary" collapse-tags="" v-model="file.provenance"
                                @input="onProvenanceChange(file)" placeholder="Provenance">
                                <el-option v-for="provenance in provenances" class="select-primary"
                                    :value="provenance.id" :label="provenance.name" :key="provenance.id">
                                </el-option>
                            </el-select>
                        </td>
                        <td rowspan="1" colspan="1" class="col-5 col-md-4 col-lg-2">
                            <AgentSelector :key="file.id" :dropup="false" :is-coordinator="true"
                                v-model="file.coordoRessourceID" @click.native.stop placeholder="Assigné à"
                                type="search" @input="assignedCoordinator(file)" />
                        </td>
                        <td rowspan="1" colspan="1" class="d-none d-lg-flex col-lg-2">{{ "Qualification" }}</td>
                        <td rowspan="1" colspan="1" class="col-1 col-lg-1">
                            <el-tooltip :open-delay="100" content="Archiver le dossier" placement="top">
                                <img  class="fire-icon" src="img/fire-red.svg" @click="changeHotStatus(file)"/> 
                            </el-tooltip>
                        </td>
                        <td rowspan="1" colspan="1" class="col-1 col-lg-1">
                            <el-tooltip :open-delay="100" content="Supprimer le dossier" placement="top">
                                <n-button @click.native.stop="deleteFile(file.id, 'activeFile')" type="danger" round="" icon=""
                                    class="mt-0 mb-0">
                                    <i class="now-ui-icons ui-1_simple-delete"></i>
                                </n-button>
                            </el-tooltip>
                        </td>
                    </tr>
                </tbody>
            </table>
            <h3 v-if="!loading && pageResultsEnabled.length === 0" class="text-center mt-2">Opss!!! pas de dossiers <img
                    class="widget-icon" src="img/fire-red.svg" /> pour cette année.
            </h3>
        </section>
        <section>
            <h3>Archive des dossiers chauds</h3>
            <div class="hot-file-action mb-5">
                <div class="pagination">
                    <n-pagination :page-count="pageCountArchiveEnabled" :value="currentPageArchiveEnabled"
                        @input="pageChangedArchiveEnabled">
                    </n-pagination>
                </div>
                <div class="filter">
                    <fg-input placeholder="Filtrer" v-model="filterArchive"></fg-input>
                </div>
                <div class="years">
                    <el-select key="randomKey" class="select-danger" placeholder="Année" v-model="selectYearArchive"
                        @input="onYearChange('archiveFile')">
                        <el-option v-for="option in yearSelect.options" class="select-danger" :value="option.value"
                            :label="option.label" :key="option.label">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <Spinner size="4" v-if="archiveLoading" />
            <table v-if="!archiveLoading && pageResultsArchiveEnabled.length > 0" cellspacing="0" cellpadding="0"
                border="0" class="w-100 custom-table">
                <thead class="has-gutter">
                    <tr class="row">
                        <th rowspan="1" colspan="1" class="col-9 col-md-4 col-lg-2">Entreprise</th>
                        <th rowspan="1" colspan="1" class="d-none d-md-flex col-md-5 col-lg-2">Date</th>
                        <th rowspan="1" colspan="1" class="d-none d-lg-flex col-lg-2">Provenance</th>
                        <!-- <th rowspan="1" colspan="1" class="d-none d-lg-flex col-lg-2">Coordonnateur assigné</th> -->
                        <th rowspan="1" colspan="1" class="d-none d-lg-flex col-lg-4">Qualification</th>
                        <th rowspan="1" colspan="1" class="col-2 col-lg-1"></th>
                        <th rowspan="1" colspan="1" class="col-2 col-lg-1"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="row body" v-for="(file, index) in pageResultsArchiveEnabled" @click="goToAccountPage(file)" :key="file.id">
                        <td rowspan="1" colspan="1" class="col-9 col-md-4 col-lg-2"> {{ file | companyName }}</td>
                        <td rowspan="1" colspan="1" class="d-none d-md-flex col-md-5 col-lg-2"> {{ file.year }}</td>
                        <td rowspan="1" colspan="1" class="d-none d-lg-flex col-lg-2"> {{ file.provenance |
                                getProvenance(provenances)
                        }}</td>
                        <!-- <td rowspan="1" colspan="1" class="d-none d-lg-flex col-lg-2">{{ file.coordoRessourceID }}</td> -->
                        <td rowspan="1" colspan="1" class="d-none d-lg-flex col-lg-4">{{ "Qualification" }}</td>
                        <td rowspan="1" colspan="1" class="col-2 col-lg-1">
                            <el-tooltip :open-delay="100" content="Activer le dossier" placement="top">
                                <img  class="fire-icon" src="img/fire-black.svg" @click="changeHotStatus(file)" />
                            </el-tooltip>
                        </td>
                        <td rowspan="1" colspan="1" class="col-2 col-lg-1">
                            <el-tooltip :open-delay="100" content="Supprimer le dossier" placement="top">
                                <n-button @click.native.stop="deleteFile(file.id, 'archiveFile')" type="danger" round="" icon=""
                                    class="mt-0 mb-0">
                                    <i class="now-ui-icons ui-1_simple-delete"></i>
                                </n-button>
                            </el-tooltip>
                        </td>
                    </tr>
                </tbody>
            </table>
            <h3 v-if="!archiveLoading && pageResultsArchiveEnabled.length === 0" class="text-center mt-2">Opss!!! pas de
                archive dossiers pour cette année.</h3>
        </section>
        <confirm-modal ref="confirmDialog"></confirm-modal>
    </div>
</template>
    
<script>
import selectYearMixins from "../../mixins/selectYear.mixins"
import {
    Pagination,
    Radio,
    Spinner,
    ConfirmModal
} from 'src/components';
import AgentSelector from "../../components/AgentSelector.vue"
import { normalizeLowerCaseString } from '../../util/string.util';
import { getCurrentYear } from '../../util/time.util';

import { Select, Option } from 'element-ui'

import FileService from "../../services/file.service"

export default {
    mixins: [selectYearMixins],
    components: {
        [Select.name]: Select,
        [Option.name]: Option,
        AgentSelector,
        [Pagination.name]: Pagination,
        [Radio.name]: Radio,
        Spinner,
        ConfirmModal
    },
    async created() {
        await Promise.all([this.loadFiles(getCurrentYear()), this.loadArchiveFiles(getCurrentYear())])   
    },
    data() {
        return {
            provenances: [
                {
                    id: "DIR",
                    name: 'Direction'
                },
                {
                    id: "AM",
                    name: 'Ancien mandant'
                },
                {
                    id: "DCTV",
                    name: 'Destination close trop vite'
                },
                {
                    id: "AGN",
                    name: 'Agent'
                },
            ],
            filter: "",
            filterArchive: "",
            pageSize: 10,
            currentPageEnabled: 1,
            currentPageArchiveEnabled: 1,
            showModal: false,
            files: [],
            archiveFiles: [],
            loading: false,
            archiveLoading: false,
            filterFileType: null,
            selectYearArchive: getCurrentYear().toString(),
            selector:`(
                year,
                account:(
                    id,
                    name
                ),
                hot,
                provenance,
                coordoRessourceID
            )`
        }
    },
    watch: {
        files() {
            if (this.currentPageEnabled > this.pageCountEnabled) {
                this.pageChangedEnabled(1);
            }
        },
        archiveFiles() {
            if (this.currentPageArchiveEnabled > this.pageCountArchiveEnabled) {
                this.pageChangedArchiveEnabled(1);
            }
        }
    },
    computed: {
        sortedFilesEnabled() {
            const filter = normalizeLowerCaseString(this.filter);
            const filteredResults = this.$_.filter(this.files, function (el) {
                const elName = normalizeLowerCaseString(`${el.year} ${el?.account?.name}`);
                return elName.includes(filter);
            }).filter(file => {
                if (this.filterFileType === 'assigned')
                    return file.coordoRessourceID
                if (this.filterFileType === 'notAssigned')
                    return !file.coordoRessourceID
                return true
            });
            return this.$_.sortBy(filteredResults, (el) => normalizeLowerCaseString(`${el.year} ${el?.account?.name}`));
        },
        pageCountEnabled() {
            if (!this.sortedFilesEnabled) return 1;
            return Math.ceil(this.sortedFilesEnabled.length / this.pageSize);
        },
        pageResultsEnabled() {
            let startIndex = ((this.currentPageEnabled - 1) * this.pageSize);
            if (startIndex >= this.sortedFilesEnabled.length) {
                startIndex = this.sortedFilesEnabled.length - 1;
            }
            let endIndex = (this.currentPageEnabled * this.pageSize);
            if (endIndex > this.sortedFilesEnabled.length) {
                endIndex = this.sortedFilesEnabled.length;
            }
            return this.sortedFilesEnabled.slice(startIndex, endIndex);
        },

        sortedFilesArchiveEnabled() {
            const filter = normalizeLowerCaseString(this.filterArchive);
            const filteredResults = this.$_.filter(this.archiveFiles, function (el) {
                const elName = normalizeLowerCaseString(`${el.year} ${el?.account?.name} `);
                return elName.includes(filter);
            });
            return this.$_.sortBy(filteredResults, (el) => normalizeLowerCaseString(`${el.year} ${el?.account?.name}`));
        },
        pageCountArchiveEnabled() {
            if (!this.sortedFilesArchiveEnabled) return 1;
            return Math.ceil(this.sortedFilesArchiveEnabled.length / this.pageSize);
        },
        pageResultsArchiveEnabled() {
            let startIndex = ((this.currentPageArchiveEnabled - 1) * this.pageSize);
            if (startIndex >= this.sortedFilesArchiveEnabled.length) {
                startIndex = this.sortedFilesArchiveEnabled.length - 1;
            }
            let endIndex = (this.currentPageArchiveEnabled * this.pageSize);
            if (endIndex > this.sortedFilesArchiveEnabled.length) {
                endIndex = this.sortedFilesArchiveEnabled.length;
            }
            return this.sortedFilesArchiveEnabled.slice(startIndex, endIndex);
        },
    },

    methods: {
        async loadFiles(year) {
            this.loading = true
            this.files = await FileService.getHot(year, this.selector, true);
            this.loading = false
        },
        async loadArchiveFiles(year) {
            this.archiveLoading = true
            this.archiveFiles = await FileService.getHot(year, this.selector, false);
            this.archiveLoading = false
        },
        goToAccountPage(file) {
            const accountId = file.account.id ?? null
            if (!accountId) {
                alert("Une erreur est survenue! Rafraîchissez la page!");
                return
            }
            const routeData = this.$router.resolve({ name: 'account', params: { id: accountId } });
            window.open(routeData.href, '_blank');
        },
        pageChangedEnabled(newValue) {
            this.currentPageEnabled = newValue;
        },
        pageChangedArchiveEnabled(newValue) {
            this.currentPageArchiveEnabled = newValue;
        },
        async onYearChange(typeFile) {
            if (typeFile === "currentFile") {
                await this.loadFiles(this.yearSelect.value)
                return
            }
            await this.loadArchiveFiles(this.selectYearArchive)
        },
        onFilterFileChange(type) {
            this.filterFileType = type
        },
        onProvenanceChange(file) {
            this.updateFile(file)
        },
        assignedCoordinator(file) {
            this.updateFile(file)
        },
        async updateFile(file) {
            try {
                await FileService.put(file)
            } catch (e) {
                alert("Une erreur est survenue lors de la sauvegarde automatique! Rafraîchissez la page!");
            }
        },
        async deleteFile(fileId, fileType) {
            let confirmation = true;
            confirmation = await this.$refs.confirmDialog.show({
                    title: 'Supprimer le dossier',
                    message: 'Êtes-vous sûr de supprimer ce dossier?',
                    okButton: 'Supprimer',
                    type:'danger'
                })
            if (!confirmation)
                return
            
            let data = {
                id: fileId,
                hot: null
            };
            await FileService.put(data);
            if(fileType === 'activeFile') {
                this.files = this.files.filter(F => F.id !== fileId)
                return
            }
            this.archiveFiles = this.files.filter(F => F.id !== fileId)
        },
        async changeHotStatus(file) {
            if(file.hot) {
                this.files = this.files.filter(F => F.id !== file.id);
                this.archiveFiles.unshift({...file, hot: false})
            }else {
                this.archiveFiles = this.archiveFiles.filter(F => F.id !== file.id);
                this.files.unshift({...file, hot: true})
            }
            let data = {
                id: file.id,
                hot: !file.hot
            };
            await FileService.put(data);
        }
    },
    filters: {
        companyName: (file) => {
            return file.account.name ?? ""
        },
        getProvenance(provenanceID, provenances) {
            const provenance = provenances.filter(provenance => provenance.id === provenanceID)
            if (provenance)
                return provenance.name
            return ""
        }
    }
}
</script>
    
<style lang="scss" scoped>
.password-section {
    border: 1px solid lightgrey;
    border-radius: 5px;
}

.cursor-pointer {
    cursor: pointer;
}

.widget-icon {
    height: 25px;
}

th,
td {
    text-align: center !important;
}

:deep .form-group {
    display: flex;
    justify-content: center;
    height: 100%;
    width: auto;
    margin-bottom: 0px !important;

    input {
        width: auto;
        height: 100%;
    }
}

.filter {
    grid-area: filter;
    justify-self: center;
}

.pagination {
    grid-area: pagination;
    justify-self: center;
}

.years {
    grid-area: years;
    justify-self: end;
}

.assigned {
    grid-area: assigned;
    justify-self: end;
}

.hot-file-action {
    height: 60px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
        ". pagination assigned"
        ". filter years";
    align-items: center;
}

@media (max-width: 600px) {
    .hot-file-action {
        margin-bottom: 10rem !important;
        grid-template-columns: 1fr;
        gap: 1rem;
        grid-template-areas:
            "pagination"
            "filter"
            "assigned"
            "years";


        .filter,
        .pagination,
        .years,
        .assigned {
            justify-self: center;
        }

    }
}

.fire-icon {
    height: 38px;
}
</style>