<template>
<div>
  <div style="display: flex">
    <fg-input
        label="Nom de la délégation"
        placeholder="Nom de la délégation"
        v-model="delegation.name"
        :debounce="300" style="width: 400px;"/>

    <div style="padding: 20px;">
      <n-button @click.native.stop="save()" type="success" round="" icon="" class="mt-0 mb-0">
        <i class="now-ui-icons ui-1_check"></i>
      </n-button>

      <n-button v-if="delegation.id !== null" @click.native.stop="remove()" type="danger" round="" icon="" class="mt-0 mb-0">
        <i class="now-ui-icons ui-1_simple-delete"></i>
      </n-button>
    </div>
  </div>

    <table cellspacing="0" cellpadding="0" border="0" class="w-100 custom-table">
        <thead class="has-gutter">
            <tr class="row">
                <th rowspan="1" colspan="1" class="col-10 col-sm-3">Nom</th>
                <th rowspan="1" colspan="1" class="d-none d-sm-flex col-sm-2">Shotguns</th>
                <th rowspan="1" colspan="1" class="d-none d-sm-flex col-sm-2">Leads</th>
                <th rowspan="1" colspan="1" class="d-none d-sm-flex col-sm-1">Appels</th>
                <th rowspan="1" colspan="1" class="d-none d-sm-flex col-sm-1">RDV</th>
                <th rowspan="1" colspan="1" class="d-none d-sm-flex col-sm-2">Accompagnements</th>
                <th rowspan="1" colspan="1" class="col-2 col-sm-1"></th>
            </tr>
        </thead>
        <tbody>
            <tr class="row body" v-for="(user, index) in sortedUsers" :key="index" @click="() => {
                detailsModal.display = true;
                detailsModal.data = user;
                loadUserData();
            }">
                <td rowspan="1" colspan="1" class="col-10 col-sm-3">{{user.firstName}} {{user.lastName}}</td>
                <td rowspan="1" colspan="1" class="d-none d-sm-flex col-sm-2">{{user.stats.shotgunCount}}</td>
                <td rowspan="1" colspan="1" class="d-none d-sm-flex col-sm-2">{{user.stats.leadCount}}</td>
                <td rowspan="1" colspan="1" class="d-none d-sm-flex col-sm-1">{{user.stats.callCount}}</td>
                <td rowspan="1" colspan="1" class="d-none d-sm-flex col-sm-1">{{user.stats.meetingCount}}</td>
                <td rowspan="1" colspan="1" class="d-none d-sm-flex col-sm-2">{{user.stats.accompaniments}}</td>
                <th rowspan="1" colspan="1" class="col-2 col-sm-1">
                    <n-button @click.native.stop="removeFromDelegation(user.id, delegation.id)" type="danger" round="" icon="" class="mt-0 mb-0">
                        <i class="now-ui-icons ui-1_simple-delete"></i>
                    </n-button>
                </th>
            </tr>
        </tbody>
    </table>
    <modal :show.sync="addModal.display" header-classes="justify-content-center">
        <h4 slot="header" class="title title-up">Ajouter des utilisateurs</h4>
        <div>
          <label class="filter-label" for="userType">Filter: </label>

          <select name="usetType" id="userType" @change="selectFilterType">
            <option value="tous">Tous</option>
            <option value="AGENT_INT">Agents Commerciaux</option>
            <option value="AGENT_CAN">Agents recrutement</option>
          </select>
        </div>
        <div>
            <table cellspacing="0" cellpadding="0" border="0" class="w-100 custom-table">
                <thead class="has-gutter">
                    <tr class="row">
                        <th rowspan="1" colspan="1" class="col-10 col-md-4 cell-format">Username</th>
                        <th rowspan="1" colspan="1" class="d-none d-md-flex col-md-7 cell-format">Nom</th>
                        <th rowspan="1" colspan="1" class="col-2 col-md-1 cell-format"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="row" v-for="(user, index) in addableUsers" :key="index">
                        <td rowspan="1" colspan="1" class="col-10 col-md-4 cell-format">{{user.username}}</td>
                        <td rowspan="1" colspan="1" class="d-none d-md-flex col-md-7 cell-format">{{user.firstName}} {{user.lastName}}</td>
                        <td rowspan="1" colspan="1" class="col-2 col-md-1 cell-format">
                            <n-button @click.native.stop="()=> {  cleanAddableList(user.id); addToDelegation(user.id, delegation.id); }" type="success" round="" icon="" class="mt-0 mb-0">
                                <i class="now-ui-icons ui-1_simple-add"></i>
                            </n-button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </modal>
    <modal :show.sync="detailsModal.display" header-classes="justify-content-center">
        <h4 slot="header" class="title title-up">{{detailsModal.data.firstName}} {{detailsModal.data.lastName}}</h4>
        <div>
            <Spinner size="4" v-if="loading"/>
            <div>
                <FollowUpWidget
                    v-for="(file, index) in files"
                    :key="index"
                    :file="file"
                    :display-year="true"
                />
            </div>
        </div>
    </modal>
    <confirm-modal class="remove-delegation" ref="confirmModal"></confirm-modal>
    <FloatingButtonGroup v-if="delegation.id !== null" :buttons="[{
        icon: 'now-ui-icons ui-1_simple-add',
        color: 'primary',
        tooltip: 'Ajouter une personne à la délégation',
        onClick: () => {
            addModal.display = true;
        }
    }]"/>
</div>
</template>

<script>
import {
    Spinner,
    FormGroupInput as FgInput,
    Modal,
} from 'src/components';
import FloatingButtonGroup from '../../components/FloatingButton/FloatingButtonGroup';
import DelegationService from '../../services/delegation.service';
import {normalizeLowerCaseString} from '../../util/string.util';
import FollowUpWidget from '../../components/FollowUpWidgets/FollowUpWidget';
import { mapGetters } from 'vuex';
import { ConfirmModal } from 'src/components';

export default {
    computed: {
        ...mapGetters({
            files: 'files/all',
        }),
        sortedUsers() {
            return this.$_.sortBy(this.users, (el) => normalizeLowerCaseString(`${el.firstName} ${el.lastName}`));
        }
    },
    components: {
        Spinner,
        FgInput,
        Modal,
        FloatingButtonGroup,
        FollowUpWidget,
        ConfirmModal
    },
    props: {
        delegation: Object,
        users: Array,
        removeFromDelegation: Function,
        addToDelegation: Function,
        receiveDelegation: Function,
        removeDelegation: Function,
        enabledUsers: Array
    },
    data() {
        return {
            loading: true,
            addModal: {
                display: false,
            },
            detailsModal: {
                display: false,
                data: {},
            },
            filterType: "tous",
            addableUsers: Array
        };
    },
    async created() {
      this.filterMembers();
    },
    methods : {
        async save() {

          if (!this.delegation.id) {

              const newDelegation = {
                createdBy: null,
                dateCreated: null,
                dateDeleted: null,
                dateModified: null,
                deletedBy: null,
                id: null,
                modifiedBy: null,
                name: this.delegation.name,
                type: "INT"
              }

              const delegation = await DelegationService.post(newDelegation);

              if (this.receiveDelegation) {
                this.receiveDelegation(delegation);
              }

          } else {
            await DelegationService.put(this.delegation);
          }

        },
        async remove() {

          const confirmation = await this.$refs.confirmModal.show({
                title: `Fermer une délégation.`,
                message: "Êtes-vous sûr.e de vouloir fermer cette délégation: "+ this.delegation.name +"?",
                okButton: `Accepter`,
                cancelButton: 'Annuler',
                type: 'danger'
            })

            if (confirmation) {
              if (this.removeDelegation) {
                // eslint-disable-next-line vue/no-mutating-props
                this.delegation.deleted = true;

                const delegation = await DelegationService.put(this.delegation);

                this.removeDelegation(delegation.id);

              }
            }

            return;
        },
        selectFilterType(e) {
          this.filterType = "tous";

          if (e) {
            e.stopPropagation();
            this.filterType = e.currentTarget.value;
          }

          this.filterMembers();
        },
        filterMembers() {

          const filterAvailableUsers = user => (!user.delegationId) && !user.admin;

          this.addableUsers = this.enabledUsers.filter(filterAvailableUsers);

          if (this.filterType !== "tous") {
            this.addableUsers = this.addableUsers.filter(user => user.roles.findIndex(role => role === this.filterType) > -1);
          }
        },
        cleanAddableList(id) {
          this.addableUsers = this.addableUsers.filter(user => user.id !== id);
        },
        async loadUserData() {
            this.loading = true;
            await this.$store.dispatch('files/loadUser', this.detailsModal.data.id);
            this.loading = false;
        },
    },
}
</script>

<style lang="scss" scoped>
:deep .modal-content {
    width: 500px;
    height: 700px;
    overflow-y: scroll;
}

:deep .cell-format {
    padding: 2px !important;
    align-items: center !important;
    justify-content: left !important;
}

:deep .modal.remove-delegation .modal-content {
    width: 500px;
    height: 230px !important;
    overflow-y: hidden !important;
}

</style>
