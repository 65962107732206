<template>
<div class="page-intdashboard">
    <card>
        <tabs type="primary" v-if="!loading" :handle-click="setTabContentData">
            <tab-pane label="Agents commerciaux">
                <UserPanel
                    :enabled-users="commercialUsers"
                    :all-users="allUsers"
                    :pause-user="pauseUser"
                    :play-user="playUser"
                    :delete-user="deleteUser"
                    :put="put"
                    :post="post"
                    :label1="'Comptes agents commerciaux activés'"
                    :label2="'Tous les comptes agents'"
                    v-bind:default-roles="['AGENT_INT']"
                    :hide-checkboxes="true"/>
            </tab-pane>

            <tab-pane label="Agents recrutement">
                <UserPanel
                    :enabled-users="recurtementUsers"
                    :all-users="allUsers"
                    :pause-user="pauseUser"
                    :play-user="playUser"
                    :delete-user="deleteUser"
                    :put="put"
                    :post="post"
                    :label1="'Comptes agents recrutement activés'"
                    :label2="'Tous les comptes agents'"
                    v-bind:default-roles="['AGENT_CAN']"
                    :hide-checkboxes="true"/>
            </tab-pane>

            <tab-pane label="Permanences">
                <PermanentPanel
                    :enabled-users="enabledUsers"
                    :type="'INT'"/>
            </tab-pane>

            <tab-pane label="Stats">
                <StatsPanel />
            </tab-pane>
            <tab-pane label="Dossiers chauds">
                <FilePanel />
            </tab-pane>

            <tab-pane v-for="(delegation, index) in delegations" :key="index" :id="delegation.id" :label="delegation.name">
                <DelegationPanel
                    v-if="delegation.id === tabSelected"
                    :key="index"
                    :delegation="delegation"
                    :users="tabData"
                    :remove-from-delegation="removeFromDelegation"
                    :add-to-delegation="addToDelegation"
                    :remove-delegation="removeDelegation"
                    :enabled-users="enabledUsers"/>
            </tab-pane>

            <tab-pane label="+">
              <DelegationPanel
                    :key="-1"
                    :delegation="delegationTemp"
                    :users="[]"
                    :remove-from-delegation="()=>{}"
                    :add-to-delegation="()=>{}"
                    :receive-delegation="receiveDelegation"
                    :enabled-users="[]"/>

            </tab-pane>
        </tabs>
        <Spinner size="4" v-if="loading"/>
    </card>
</div>
</template>

<script>
import {
    Card,
    Tabs,
    TabPane,
    Spinner,
} from 'src/components';
import DelegationService from '../../services/delegation.service';
import UserService from '../../services/user.service';
import UserPanel from './UserPanel';
import PermanentPanel from './PermanentPanel';
import DelegationPanel from './DelegationPanel';
import adminMixins from '../../mixins/admin.mixins';
import StatsPanel from './StatsPanel';
import FilePanel from './FilePanel';

export default {
    mixins: [adminMixins],
    components: {
        Card,
        Tabs,
        TabPane,
        UserPanel,
        PermanentPanel,
        DelegationPanel,
        Spinner,
        StatsPanel,
        FilePanel
    },
    data() {
        return {
            delegations: [],
            delegationUsers: [],
            tabSelected: "",
            tabData: [],
            delegationTemp: {name: "Nouvelle délégation", id: null}
        };
    },
    async created() {
        this.loading = true;
        await Promise.all([this.fetchDelegations(), this.fetchEnabledUsers(), this.fetchAllUsers(true)]);

        const userRequests = this.delegations.map((delegation, index) => {
           return this.createUserRequest(delegation.id, index)();
        });

        await Promise.all(userRequests);
        this.loading = false;
    },
    methods: {
        async removeFromDelegation(userId, delegationId) {
            await UserService.put({
                id: userId,
                delegationId: "",
            }, `(
                firstName,
                lastName,
                stats
            )`);

            const userIndex = this.enabledUsers.findIndex(user => user.id === userId);

            if (userIndex > -1) {
              this.enabledUsers[userIndex].delegationId = "";
            }

            this.tabData = this.tabData.filter(user => user.id !== userId);

        },
        async addToDelegation(userId, delegationId) {
            const user = await UserService.put({
                id: userId,
                delegationId: delegationId,
            }, `(
                firstName,
                lastName,
                stats
            )`);

            const userAffected = this.enabledUsers.find(user => user.id === userId);

            if (userAffected) {
              userAffected.delegationId = delegationId;
            }

            this.tabData.push(user);
        },
        async fetchDelegations() {
            this.delegations = await DelegationService.getAll();
        },
        createUserRequest(delegationId, index) {
           const context = this;
           return async function () {
            const selector = `(
                firstName,
                lastName,
                stats
            )`;

            context.delegationUsers[index] = {
              id: delegationId,
              users: await UserService.findByDelegation(delegationId, selector) || []
            };
         }
        },
        setTabContentData(tab) {
          this.tabSelected = tab.id;
          if (this.tabSelected) {
            this.tabData = this.delegationUsers.find(delegation => delegation.id === this.tabSelected).users || [];
          }
        },
        receiveDelegation(newDelegation) {
          this.delegations.splice(this.delegations.length, 1, newDelegation);
          this.delegationUsers.splice(this.delegationUsers.length, 1, {
              id: newDelegation.id,
              users: []
            });

             // eslint-disable-next-line vue/no-mutating-props
             this.delegationTemp.name = "Nouvelle délégation";
             // eslint-disable-next-line vue/no-mutating-props
             this.delegationTemp.id = null;
        },
        removeDelegation(delegationId) {

          this.delegations = this.delegations.filter(delegation => delegation.id !== delegationId);
          this.delegationUsers = this.delegationUsers.filter(delegation => delegation.id !== delegationId);
        }
    },
}
</script>

<style lang="scss" scoped>
:deep  .card-body {
    .nav__items {
        .nav-item:last-child {
            a, a.active, a:hover, a.active:hover {
              padding: 0;
              font-size: 30px;
              background-color: transparent;
              min-width: 50px;
              > div {
                height: 43px;
                border-radius: 50%;
                width: 43px;
                background-color: #18ce0f;
                color: #FFFFFF;
                line-height: 43px;
              }
            }
        }
    }
}
:deep li {
    margin-bottom: 5px !important;
}

</style>
