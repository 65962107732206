<template>
    <div class="page-settings container">
        <card>
            <div>
                <h4>Changer votre mot de passe</h4>
            </div>
            <form @submit.prevent="changePassword" v-if="!loading">
                <div class="row">
                    <fg-input v-model="model.originalPassword"
                                v-validate="'required|alpha_dash'"
                                type="password"
                                name="originalPassword"
                                :error="getError('originalPassword')"
                                class="col-md-4 col-sm-6 col-12"
                                placeholder="Mot de passe actuel"
                                label="Mot de passe actuel">
                    </fg-input>

                    <fg-input v-model="model.newPassword"
                                v-validate="'required|min:8|alpha_dash'"
                                type="password"
                                name="newPassword"
                                ref="newPassword"
                                :error="getError('newPassword')"
                                class="col-md-4 col-sm-6 col-12"
                                placeholder="Nouveau mot de passe"
                                label="Nouveau mot de passe">
                    </fg-input>

                    <fg-input v-model="model.newPasswordConfirmation"
                                v-validate="'required|min:8|alpha_dash|confirmed:newPassword'"
                                type="password"
                                name="newPasswordConfirmation"
                                :error="getError('newPasswordConfirmation')"
                                class="col-md-4 col-sm-6 col-12"
                                placeholder="Confirmation nouveau mot de passe"
                                label="Confirmation nouveau mot de passe">
                    </fg-input>
                </div>
                
                <n-button native-type="submit" type="primary" round block 
                :disabled="errors.all().length > 0 || model.originalPassword === '' || model.newPassword === '' || model.newPasswordConfirmation === ''">
                    Confirmer!
                </n-button>
            </form>
            <Spinner size="4" v-if="loading"/>
        </card>
    </div>
</template>

<script>
import {
    Card,
    Spinner,
    FormGroupInput as FgInput,
} from 'src/components';
import UserService from '../../services/user.service';

export default {
    components: {
        Card,
        FgInput,
        Spinner,
    },
    data() {
        return {
            loading: false,
            model: {
                originalPassword: '',
                newPassword: '',
                newPasswordConfirmation: '',
            },
        };
    },
    methods: {
        getError(fieldName) {
            return this.errors.first(fieldName);
        },
        async changePassword() {
            this.loading = true;
            try {
                await UserService.changePassword(this.model);
                this.$notify({
                    message: `Mot de passe changé!`,
                    type: 'success',
                    timeout: 4000,
                    horizontalAlign: 'right',
                    verticalAlign: 'top',
                });
            } catch (e) {
                alert("Une erreur s'est produite! Le mot de passe n'a pas pu être changé. Assurez-vous d'avoir entré le bon mot de passe actuel.");
            }
            this.loading = false;
        }
    }
}
</script>

<style lang="scss">

</style>