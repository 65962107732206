import ApiService from './api.service';

class FileService {

    constructor() {
        this.basePath = "files";
        this.fullSelector = `(
            year,
            dateModified,
            account:(
                name
            ),
            notSignedReasons,
            leadIds,
            callIds,
            meetingIds,
            mandateIds,
            canceledFlag,
            virtualFlag,
            type,
            hot,
            provenance,
            coordoRessourceID
        )`;
    }

    async post(file) {
        try {
            const response = await ApiService.post(`${this.basePath}?selector=${this.fullSelector}`, file);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    }

    async put(file) {
        try {
            const response = await ApiService.put(`${this.basePath}?selector=${this.fullSelector}`, file);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    }

    async get(year, selector) {
        let choosedSelector = this.fullSelector;
        if (selector) {
            choosedSelector = selector;
        }
        try {
            const response = await ApiService.get(`${this.basePath}/${year}?selector=${choosedSelector.replace(/\s/g, '')}`);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    }

    async getFileById(fileId, selector) {
        let choosedSelector = this.fullSelector;
        if (selector) {
            choosedSelector = selector;
        }
        try {
            const response = await ApiService.get(`${this.basePath}/file?fileId=${fileId}&selector=${choosedSelector.replace(/\s/g, '')}`);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    }

    async getHot(year, selector, isHot) {
        let choosedSelector = this.fullSelector;
        if (selector) {
            choosedSelector = selector;
        }
        try {
            const response = await ApiService.get(`${this.basePath}/hot/${year}?ishot=${isHot}&selector=${choosedSelector.replace(/\s/g, '')}`);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    }

    async getByUserId(year, userId) {
        try {
            const response = await ApiService.get(`${this.basePath}/${year}/${userId}?selector=${this.fullSelector.replace(/\s/g, '')}`);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    }

    async delete(fileId) {
        try {
            await ApiService.delete(`${this.basePath}/${fileId}`);
        } catch (e) {
            throw e;
        }
    }

    async duplicateAndChangeType(fileId) {
        try {
            const response = await ApiService.post(`${this.basePath}/duplicateAndChangeType/${fileId}?selector=${this.fullSelector}`);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    }
}

export default new FileService();