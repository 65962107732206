const TOKEN_KEY = 'access_token';
const REFRESH_TOKEN_KEY = 'refresh_token';
const WEBSOCKET_TOKEN_KEY = 'websocket_token';
import jwt from 'jsonwebtoken';

class TokenService {

    getToken() {
        return localStorage.getItem(TOKEN_KEY);
    }

    saveToken(accessToken) {
        localStorage.setItem(TOKEN_KEY, accessToken);
    }

    removeToken() {
        localStorage.removeItem(TOKEN_KEY);
    }

    getRefreshToken() {
        return localStorage.getItem(REFRESH_TOKEN_KEY);
    }

    saveRefreshToken(refreshToken) {
        localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
    }

    removeWebsocketToken() {
        localStorage.removeItem(REFRESH_TOKEN_KEY);
    }

    getWebsocketToken() {
        return localStorage.getItem(WEBSOCKET_TOKEN_KEY);
    }

    saveWebsocketToken(websocketToken) {
        localStorage.setItem(WEBSOCKET_TOKEN_KEY, websocketToken);
    }

    removeRefreshToken() {
        localStorage.removeItem(WEBSOCKET_TOKEN_KEY);
    }

    isAdmin() {
        const decoded = jwt.decode(this.getToken());
        if (decoded && decoded.authorities) {
            return decoded.authorities.includes("ADMIN");
        }
        return false;
    }

    isCoordoCan() {
        const decoded = jwt.decode(this.getToken());
        if (decoded && decoded.authorities) {
            return decoded.authorities.includes("COORDO_CAN");
        }
        return false;
    }

    isCoordoInt() {
        const decoded = jwt.decode(this.getToken());
        if (decoded && decoded.authorities) {
            return decoded.authorities.includes("COORDO_INT");
        }
        return false;
    }

    isAgentInt() {
        const decoded = jwt.decode(this.getToken());
        if (decoded && decoded.authorities) {
            return decoded.authorities.includes("AGENT_INT");
        }
        return false;
    }

    isAgentCan() {
        const decoded = jwt.decode(this.getToken());
        if (decoded && decoded.authorities) {
            return decoded.authorities.includes("AGENT_CAN");
        }
        return false;
    }

}

export default new TokenService();