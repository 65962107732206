<template>
    <div class="d-flex flex-wrap default-tag-widget">
        <el-tag :key="index" v-for="(tag, index) in tags.value" size="small" type="primary" :closable="!readonly"
            @close="handleClose(tag)">
            {{ displayName(tag) }}
        </el-tag>

        <div class="position-relative show" :class="dropup" v-if="!readonly">
            <input type="text" :placeholder="placeholder" class="form-control input-new-tag" v-model="tags.inputValue"
                ref="saveTagInput" size="mini" @keydown="onKeyDown" @blur="() => { focused = false; }"
                @focus="() => { focused = true; }">
            <ul class="dropdown-menu show" v-if="displaySuggestions">
                <a class="dropdown-item cursor-pointer" v-for="(suggestion, index) in suggestions" :key="index"
                    @mousedown="handleInputConfirm(suggestion)">{{ suggestion }}</a>
            </ul>
        </div>
        <confirm-modal ref="confirmDialog"></confirm-modal>
    </div>
</template>

<script>
import { Tag } from 'element-ui';
import { DropDown, ConfirmModal } from 'src/components';
import { normalizeLowerCaseString } from '../util/string.util';

export default {
    components: {
        [Tag.name]: Tag,
        DropDown,
        ConfirmModal
    },
    props: {
        value: {
            type: Array,
            required: true,
        },
        attribute: {
            default: null,
            type: String,
        },
        placeholder: {
            type: String,
            required: true,
        },
        source: {
            type: Array,
            required: true,
        },
        maxSuggestions: {
            type: Number,
            default: () => 5,
        },
        displaySuggestionsOnFocus: {
            type: Boolean,
            default: false,
        },
        allowDuplicates: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        dropup: {
            type: Boolean,
            default: true,
        },
        removeWithConfirmation: {
            type: Boolean,
            default: false,
        },
        confirmModalTitle: {
            type: String,
            default: "",
        },
        confirmModalMessage: {
            type: String,
            default: "",
        },
        confirmModalOkButton: {
            type: String,
            default: "Supprimer",
        }
    },
    watch: {
        focused(newValue) {
            if (!newValue) {
                this.currentFocus = -1;
            }
        },
        value(newValue) {
            this.tags = {
                value: newValue,
                inputValue: ''
            }
        }
    },
    computed: {
        displaySuggestions() {
            return this.suggestions.length > 0 && this.focused;
        },
        suggestions() {
            const normalizedNewValue = normalizeLowerCaseString(this.tags.inputValue);
            if (/\S/.test(normalizedNewValue)) {
                let fullList = this.$_.filter(this.source, function (item) {
                    return normalizeLowerCaseString(item).includes(normalizedNewValue);
                });
                if (!this.allowDuplicates) {
                    fullList = this.$_.difference(fullList, this.value);
                }
                if (this.maxSuggestions) {
                    return this.$_.first(fullList, this.maxSuggestions);
                } else {
                    return fullList;
                }
            } else if (this.displaySuggestionsOnFocus) {
                let fullList = this.source;
                if (!this.allowDuplicates) {
                    fullList = this.$_.difference(fullList, this.value);
                }
                return fullList;
            } else {
                return [];
            }
        },
    },
    data() {
        let self = this;
        return {
            tags: {
                value: self.value,
                inputValue: '',
            },
            focused: false,
            currentFocus: -1,
        };
    },
    methods: {
        displayName(tag) {
            if (!this.attribute) return tag;
            return tag[this.attribute];
        },
        onKeyDown(e) {
            this.focused = true;
            const suggestions = this.$el.querySelectorAll('.dropdown-menu .dropdown-item');
            if (e.keyCode == 40) { //down
                e.preventDefault();
                this.currentFocus++;
                this.addActive(suggestions);
            } else if (e.keyCode == 38) { //up
                e.preventDefault();
                this.currentFocus--;
                this.addActive(suggestions);
            } else if (e.keyCode == 13) { //enter
                e.preventDefault();
                if (this.currentFocus > -1) {
                    if (suggestions) this.handleInputConfirm(this.suggestions[this.currentFocus]);
                }
            }
        },
        addActive(suggestions) {
            this.removeActive(suggestions);
            if (this.currentFocus >= suggestions.length) this.currentFocus = 0;
            if (this.currentFocus < 0) this.currentFocus = (suggestions.length - 1);
            suggestions[this.currentFocus].classList.add("autocomplete-active");
        },
        removeActive(suggestions) {
            for (let i = 0; i < suggestions.length; i++) {
                suggestions[i].classList.remove("autocomplete-active");
            }
        },

        async handleClose(tag) {
            let confirmation = true;
            if (this.removeWithConfirmation) {
                confirmation = await this.$refs.confirmDialog.show({
                    title: this.confirmModalTitle,
                    message: this.confirmModalMessage,
                    okButton: this.confirmModalOkButton,
                })
            }
            if (!confirmation)
                return
            let oldItem;
            this.tags.value.splice(this.tags.value.indexOf(tag), 1);
            oldItem = tag;
            this.$emit('input', this.tags.value);
            this.$emit('oldItem', oldItem);
        },
        handleInputConfirm(suggestion) {
            this.currentFocus = -1;
            let newItem;
            if (!this.attribute) {
                this.tags.value.push(suggestion);
                newItem = suggestion;
            } else {
                let data = {};
                data[this.attribute] = suggestion;
                this.tags.value.push(data);
                newItem = data;
            }
            this.$emit('input', this.tags.value);
            this.$emit('newItem', newItem);
            this.focused = false;
            this.tags.inputValue = '';
        }
    },
}
</script>

<style lang="scss">
.default-tag-widget .autocomplete-active {
    background-color: hsla(0, 0%, 87.1%, .3) !important;
}
</style>
