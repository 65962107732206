<template>
<div class="floating-button-group d-flex flex-column">
    <el-tooltip v-for="(button, index) in buttons" :key="index" :content="button.tooltip" :open-delay="100" placement="top">
        <n-button class="mb-1 mt-1 shadow" size="lg" :type="button.color" @click.native="button.onClick" round="" icon="">
            <i :class="button.icon"></i>
        </n-button>
    </el-tooltip>
</div>
</template>

<script>
import {Button} from 'src/components';
import {Tooltip} from 'element-ui';

export default {
    components: {
        [Button.name]: Button,
        [Tooltip.name]: Tooltip
    },
    props: {
        buttons: {
            type: Array,
            default: () => {
                return [
                    {
                        icon: "now-ui-icons ui-1_simple-add",
                        color: "success",
                        tooltip: "Ajouter ....",
                        onClick: () => {}
                    },
                    {
                        icon: "now-ui-icons ui-1_simple-delete",
                        color: "danger",
                        tooltip: "Supprimer ....",
                        onClick: () => {}
                    }
                ];
            }
        }
    }
}
</script>

<style lang="scss">
.floating-button-group {
    position: fixed;
    right: 10px;
    bottom: 10px;
    z-index: 2;
}
</style>