<template>
    <div>
        <Spinner size="4" v-if="loading" />
        <span v-if="error" class="text-danger italic">{{ formattedError }}</span>
        <div v-if="!loading">
            <tabs ref="TabsRef" type="primary">
                <tab-pane v-for="(call, index) in calls" :label="call.name">
                    <CallFormCell :key="index" :call="call" :set-error="setError" :delete-call="deleteCall" />
                </tab-pane>
            </tabs>
        </div>
    </div>
</template>

<script>
import { Spinner, Tabs, TabPane } from 'src/components';
import CallFormCell from './CallFormCell';

export default {
    computed: {
        formattedError() {
            if (this.error.response.status === 403) return "Vous ne pouvez pas supprimer cet appel s'il y a encore des rendez-vous!";
            return "Une erreur s'est produite!";
        }
    },
    components: {
        Spinner,
        CallFormCell,
        Tabs,
        TabPane
    },
    props: {
        calls: Array,
        loading: Boolean,
        error: Error,
        deleteCall: Function,
        setError: Function,
    },
}
</script>

<style lang="scss" scoped>
:deep .nav-link {
    padding: 4px 23px !important;
}

:deep .tab-space {
    padding: 20px 0 0px 0px;
}
</style>