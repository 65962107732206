import VueRouter from 'vue-router';
import routes from './routes';
import TokenService from '../services/token.service';

const router = new VueRouter({
    routes,
    linkActiveClass: 'active'
});

router.beforeEach(async (to, from, next) => {
    const isAdmin = to.matched.some(record => record.meta.admin);
    const isCoordoInt = to.matched.some(record => record.meta.coordoInt);
    const isCoordoCan = to.matched.some(record => record.meta.coordoCan);
    const onlyWhenLoggedOut = to.matched.some(record => record.meta.onlyWhenLoggedOut);
    const loggedIn = !!TokenService.getToken();

    if (location.protocol != 'https:' && process.env.NODE_ENV !== "development"){
        location.href = 'https:' + window.location.href.substring(window.location.protocol.length);
    }

    if (loggedIn && onlyWhenLoggedOut) {
        return next('/');
        
    }

    if (isAdmin && loggedIn && !TokenService.isAdmin()) {
        return next('/');
    }

    if (isCoordoInt && loggedIn && !TokenService.isAdmin() && !TokenService.isCoordoInt()) {
        return next('/');
    }

    if (isCoordoCan && loggedIn && !TokenService.isAdmin() && !TokenService.isCoordoCan()) {
        return next('/');
    }

    if (to.name !== 'account') {
        document.title = "CRM - Missions commerciales de l'Université Laval";
    }

    next();
});

export default router;