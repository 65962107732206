<template>
    <card class="search-result-card">
        <Spinner size="4" v-if="loading" />
        <div v-if="!loading">
            <div class="row justify-content-end">
                <div class="col-md-4 d-flex justify-content-center">
                    <n-pagination :page-count="pageCount" :value="pageValue" @input="pageChanged"></n-pagination>
                </div>
                <div class="col-md-4 align-content-div d-flex align-items-center">
                    <n-pagination-params :query="query" :pageCount="pageCount" :pageValue="pageValue"
                        :pageChanged="pageChanged" :search="search"></n-pagination-params>
                </div>
            </div>
            <table cellspacing="0" cellpadding="0" border="0" class="w-100 custom-table">
                <thead class="has-gutter">
                    <tr class="row">
                        <th rowspan="1" colspan="1" class="col-6 col-md-4 col-lg-2 d-flex justify-content-between">
                            <span>Compagnie</span>
                            <i @click="sort" class="now-ui-icons arrows-1_minimal-down cursor-pointer"
                                v-if="query.sortOrder === 'desc'"></i>
                            <i @click="sort" class="now-ui-icons arrows-1_minimal-up cursor-pointer" v-else></i>
                        </th>
                        <th rowspan="1" colspan="1" class="col-3 col-md-2 col-lg-1 d-flex align-items-center">
                            <span class="mr-1 d-none d-md-block">Assigné - commercial</span>
                        </th>
                        <th rowspan="1" colspan="1" class="col-3 col-md-2 col-lg-1 d-flex align-items-center">
                            <span class="mr-1 d-none d-md-block">Assigné - recrutement</span>
                        </th>
                        <th rowspan="1" colspan="1" class="d-none d-lg-table-cell col-lg-2">Marchés visés</th>
                        <th rowspan="1" colspan="1" class="d-none d-lg-table-cell col-lg-2">Objectifs</th>
                        <th rowspan="1" colspan="1" class="d-none d-lg-table-cell col-lg-2">Candidats ciblés</th>
                        <th rowspan="1" colspan="1" class="d-none d-md-table-cell col-md-4 col-lg-2">Description</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="row body" v-for="(data, index) in pageResults" :key="index"
                        @click="goToAccountPage(data.id)">
                        <td rowspan="1" colspan="1" class="col-6 col-md-4 col-lg-2">{{ data.name }}</td>
                        <td rowspan="1" colspan="1" class="col-3 col-md-2 col-lg-1 text-center">
                            <span v-if="data.ownershipIntId && data.ownershipIntId !== ''">
                                {{ data.ownerInt | ownership }}
                            </span>
                        </td>
                        <td rowspan="1" colspan="1" class="col-3 col-md-2 col-lg-1 text-center">
                            <span v-if="data.ownershipCanId && data.ownershipCanId !== ''">
                                {{ data.ownerCan | ownership }}
                            </span>
                        </td>
                        <td rowspan="1" colspan="1" class="d-none d-lg-table-cell col-lg-2">
                            {{ data.markets | formatMarkets }}</td>
                        <td rowspan="1" colspan="1" class="d-none d-lg-table-cell col-lg-2">
                            {{ data.files | formatObjectifs }}
                        </td>
                        <td rowspan="1" colspan="1" class="d-none d-lg-table-cell col-lg-2">
                            {{ data.files | formatCandidats }}
                        </td>
                        <td rowspan="1" colspan="1" class="d-none d-md-table-cell col-md-4 col-lg-2">
                            {{ data.description }}
                        </td>
                    </tr>
                </tbody>
            </table>
            <br>
            <div class="row justify-content-end">
                <div class="col-md-4 d-flex justify-content-center">
                    <n-pagination :page-count="pageCount" :value="pageValue" @input="pageChanged"></n-pagination>
                </div>
                <div class="col-md-4 align-content-div d-flex align-items-center">
                    <n-pagination-params :query="query" :pageCount="pageCount" :pageValue="pageValue"
                        :pageChanged="pageChanged" :search="search"></n-pagination-params>
                </div>
            </div>
        </div>
    </card>
</template>

<script>
import {
    Card,
    Pagination,
    PaginationParams,
    Spinner,
} from 'src/components';
import { Select, Option } from 'element-ui';
export default {
    components: {
        Card,
        [Pagination.name]: Pagination,
        [PaginationParams.name]: PaginationParams,
        Spinner,
        [Select.name]: Select,
        [Option.name]: Option,
    },
    props: {
        results: Object,
        loading: Boolean,
        query: Object,
        search: Function,
    },
    data() {
        return {
            choices: [30, 50, 70, 90, 100, 120],
            size: this.query.size
        };
    },
    computed: {
        pages() {
            return this.results.totalCount;
        },
        pageCount() {
            return Math.ceil(this.results.totalCount / this.query.size);
        },
        pageValue() {
            return this.query.page + 1;
        },
        pageResults() {
            return this.results.accounts;
        },
    },
    methods: {
        goToAccountPage(accountId) {
            const routeData = this.$router.resolve({ name: 'account', params: { id: accountId } });
            window.open(routeData.href, '_blank');
        },
        formatOwner(owner) {
            if (!owner) return "";
            return `${owner.firstName} ${owner.lastName}`;
        },
        pageChanged(newValue) {
            this.query.page = newValue - 1;
            this.search(true, false);
        },
        sort() {
            this.query.sortOrder = this.query.sortOrder === 'desc' ? 'asc' : 'desc'
            this.search(true, false);
        },
    },
    filters: {
        formatMarkets(markets) {
            if (!markets)
                return "";
            const marketsName = markets.map(M => M.name).filter(name => name)
            return [...new Set(marketsName)].join(", ")
        },
        formatObjectifs(files) {
            if (!files)
                return "";
            const objectifs = files.map(F => F.goals?.map(G => G.name)).flat().filter(name => name)
            return [...new Set(objectifs)].join(", ")
        },
        formatCandidats(files) {
            if (!files)
                return "";
            const condidats = files.map(F => F.goals?.map(G => G.candidats).flat()).flat().filter(name => name)
            return [...new Set(condidats)].join(", ")
        },
        ownership(user) {
            return user ? `${user?.firstName} ${user?.lastName}` : "";
        }
    }
}
</script>

<style lang="scss">
.search-result-card {
    .select-magin-page {
        margin: 0 0 0 10px;
        height: fit-content;
    }
    .align-content-div {
        display: flex;
        justify-content: flex-end;
    }
    input.el-input__inner {
        margin: 0 !important;
    }
    .select-page {
        width: 100px;
    }
    .custom-table {
        tr {
            margin: 0;
            transition: background-color 200ms ease-in-out;

            &.body:hover {
                background-color: rgba(0, 0, 0, .05);
                cursor: pointer;
            }
        }

        th,
        td {
            border-bottom: 1px solid #EBEEF5;
            padding: 12px 17px;
            vertical-align: middle;
            box-sizing: border-box;
            text-overflow: ellipsis;
            text-align: left;
            min-width: 0;
        }

        th {
            font-size: 1.45em;
            font-weight: 300;
            overflow: hidden;
            color: #f96332 !important;
        }
    }
}
@media screen and (max-width: 767px) {
    .search-result-card {
        .align-content-div {
            justify-content: center;
        }
    }
}
</style>
