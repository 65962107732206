import ApiService from './api.service';

class ContactService {

    constructor() {
        this.basePath = 'contacts';
        this.fullSelector = `(
            name,
            email,
            title,
            city,
            mobilePhone,
            officePhone,
            stillWorkingThere,
            reasonLeaving,
            linkedIn,
            newsletter,
            interestedInNewsletter,
            concerns,
            hierarchyRole,
            isAlumniMCUL,
            hasBeenCalled,
            hierarchyRole
        )`;
    }

    async getAccountContacts(accountId) {
        try {
            const selector = `(id,name)`;
            const response = await ApiService.get(`${this.basePath}/findByAccountId/${accountId}?selector=${selector}`);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    }

    async get(contactId) {
        try {
            const selector = `(id,name)`;
            const response = await ApiService.get(`${this.basePath}/${contactId}?selector=${selector}`);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    }

    async put(data) {
        try {
            const response = await ApiService.put(`${this.basePath}?selector=${this.fullSelector}`, data);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    }

    async post(contact) {
        try {
            const response = await ApiService.post(`${this.basePath}?selector=${this.fullSelector}`, contact);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    }

    async delete(contactId) {
        try {
            await ApiService.delete(`${this.basePath}/${contactId}`);
        } catch (e) {
            throw e;
        }
    }
}

export default new ContactService();