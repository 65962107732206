import ApiService from './api.service';

class AccountService {

    constructor() {
        this.basePath = "accounts";
        this.fullSelector = `
        (
            name,
            description,
            city,
            phoneFax,
            billingAddress,
            phoneOffice,
            website,
            notes,
            notesAdmin,
            annualRevenue,
            region,
            industries,
            ownershipIntId,
            ownershipCanId,
            territory,
            contacts:(
                name,
                email,
                title,
                city,
                mobilePhone,
                officePhone,
                stillWorkingThere,
                reasonLeaving,
                linkedIn,
                newsletter,
                hasBeenCalled,
                interestedInNewsletter,
                concerns,
                hierarchyRole,
                isAlumniMCUL
            ),
            markets:(
                name,
                canceled
            ),
            files:(
                year,
                dateModified,
                account:(
                    name
                ),
                notSignedReasons,
                leadIds,
                callIds,
                meetingIds,
                mandateIds,
                canceledFlag,
                virtualFlag,
                type,
                hot,
                coordoRessourceID,
                goals:(
                    goalId,
                    name,
                    marketId,
                    fileId,candidats,
                    canceled
                )
            ),
            leads:(
                name,
                selectedOutcomes,
                selectedContact,
                notes,
                dateCreated,
                dateModified,
                modifiedBy,
                createdBy,
                agent:(
                    firstName,
                    lastName
                ),
                description,
                fileId
            ),
            calls:(
                name,
                selectedOutcomes,
                dateCreated,
                dateModified,
                modifiedBy,
                createdBy,
                agent:(
                    firstName,
                    lastName
                ),
                description,
                dateStart,
                fileId
            ),
            meetings:(
                name,
                partnerId,
                selectedOutcomes,
                canceled,
                dateCreated,
                dateModified,
                modifiedBy,
                createdBy,
                agent:(
                    firstName,
                    lastName
                ),
                dateStart,
                description,
                fileId,
                type
            ),
            mandates:(
                year,
                destination,
                summary,
                followUp,
                confidentialDetails,
                objectives,
                dateModified,
                modifiedBy,
                fileId,
                agent:(
                    firstName,
                    lastName
                ),
                client:(
                    name
                )
            )
        )`;
    }

    async update(data) {
        try {
            const response = await ApiService.put(`${this.basePath}?selector=${this.fullSelector}`, data);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    }

    async search(query) {
        const selector = "(id,name,description,markets:(name,canceled),ownershipIntId,ownershipCanId,ownerInt:(firstName,lastName),ownerCan:(firstName,lastName),files:(year,goals:(goalId,fileId,name,marketId,candidats,canceled)))";
        let params = `&page=${query.page}&size=${query.size}&name=${query.name}&isHotFile=${query.isHotFile}&selectedOutcomes=${query.meetingOutcomes}`;
        if (query.fileYears?.length > 0) {
            params += `&fileYears=${query.fileYears}`;
        }
        if (query.city && query.city !== "") {
            params += `&city=${query.city}`;
        }
        if (query.notAssignedInt) {
            params += `&notAssignedInt=${query.notAssignedInt}`;
        }
        if (query.notAssignedCan) {
            params += `&notAssignedCan=${query.notAssignedCan}`;
        }
        if (query.markets && query.markets.length > 0) {
            params += `&markets=${query.markets.join()}`;
        }
        if (query.industries && query.industries.length > 0) {
            params += `&industries=${query.industries.join()}`;
        }
        if (query.mandateDestinations && query.mandateDestinations.length > 0) {
            params += `&mandateDestinations=${query.mandateDestinations.join()}`;
        }
        if (query.mandateYears && query.mandateYears.length > 0) {
            params += `&mandateYears=${query.mandateYears.join()}`;
        }
        if (query.sortOrder && (query.sortOrder === 'asc' || query.sortOrder === 'desc')) {
            params += `&sortOrder=${query.sortOrder}`;
        }
        if (query.sortAttribute === 'name') {
            params += `&sortAttribute=${query.sortAttribute}`;
        }
        if (query.annualRevenue && query.annualRevenue !== "") {
            params += `&annualRevenue=${query.annualRevenue}`;
        }
        if (query.regions && query.regions !== "") {
            params += `&regions=${query.regions.join()}`;
        }
        if (query.allFileYears?.length > 0) {
            params += `&allFileYears=${query.allFileYears}`;
        }
        if (query.objectives?.length > 0) {
            params += `&objectives=${query.objectives}`;
        }
        try {
            const response = await ApiService.get(`${this.basePath}?selector=${selector}${params}`);
            return response.data;
        } catch (e) {
            console.log(e);
            return [];
        }
    }

    async get(id, selector = null) {
        let choosedSelector = this.fullSelector;
        if (selector)
            choosedSelector = selector
        try {
            const response = await ApiService.get(`${this.basePath}/${id}?selector=${choosedSelector.replace(/\s/g, '')}`);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    }

    async post(account) {
        try {
            const response = await ApiService.post(`${this.basePath}?selector=()`, account);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    }

    async delete(accountId) {
        try {
            await ApiService.delete(`${this.basePath}/${accountId}`);
        } catch (e) {
            throw e;
        }
    }
}

export default new AccountService();