import { getCurrentYear } from '../util/time.util';

export default {
    data() {
        const currentYear = getCurrentYear();
        let yearOptions = [];
        for (let i = 1997; i <= currentYear; i++) {
            yearOptions.push({
                value: i.toString(),
                label: i.toString()
            });
        }
        return {
            yearSelect: {
                value: currentYear.toString(),
                options: yearOptions
            },
        };
    },
    methods: {
        onYearsChanged(newValue) {
            this.yearSelect.value = newValue;
        },
    },
};