<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <SearchCard :query="query" :loading="loading" :search="search" />
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <SearchResultCard :results="results" :loading="loading" :query="query" :search="search" />
            </div>
        </div>
        <FloatingButtonGroup :buttons="[{
            icon: 'now-ui-icons ui-1_simple-add',
            color: 'success',
            tooltip: 'Ajouter une compagnie',
            onClick: addAccount
        }]" />
    </div>
</template>

<script>
import SearchCard from './SearchCard';
import SearchResultCard from './SearchResultCard';
import AccountService from '../../services/account.service';
import FloatingButtonGroup from '../../components/FloatingButton/FloatingButtonGroup';

export default {
    components: {
        SearchCard,
        SearchResultCard,
        FloatingButtonGroup,
    },
    async created() {
        const annualRevenue = this.$route.query.annualRevenue ? this.$route.query.annualRevenue : "";
        const page = this.$route.query.page ? parseInt(this.$route.query.page) : 0;
        const fileYears = this.$route.query.fileYears && Array.isArray(this.$route.query.fileYears) ? this.$route.query.fileYears
            : this.$route.query.fileYears ? this.$route.query.fileYears.split(',') : [];
        const name = this.$route.query.name ? this.$route.query.name : "";
        const city = this.$route.query.city ? this.$route.query.city : "";
        // const notAssigned = this.$route.query.notAssigned && this.$route.query.notAssigned === 'true' ? true : false;
        const markets = this.$route.query.markets && Array.isArray(this.$route.query.markets) ? this.$route.query.markets
            : this.$route.query.markets ? this.$route.query.markets.split(',') : [];
        const industries = this.$route.query.industries && Array.isArray(this.$route.query.industries) ? this.$route.query.industries
            : this.$route.query.industries ? this.$route.query.industries.split(',') : [];
        const mandateDestinations = this.$route.query.mandateDestinations && Array.isArray(this.$route.query.mandateDestinations)
            ? this.$route.query.mandateDestinations : this.$route.query.mandateDestinations ? this.$route.query.mandateDestinations.split(',')
                : [];
        const mandateYears = this.$route.query.mandateYears && Array.isArray(this.$route.query.mandateYears)
            ? this.$route.query.mandateYears : this.$route.query.mandateYears ? this.$route.query.mandateYears.split(',')
                : [];
        const sortAttribute = this.$route.query.sort ? this.$route.query.sort : "name";
        const sortOrder = this.$route.query.sortOrder ? this.$route.query.sortOrder : "asc";
        const isHotFile = this.$route.query.isHotFile && this.$route.query.isHotFile === 'true' ? true : false;
        const meetingOutcomes = this.$route.query.meetingOutcomes && Array.isArray(this.$route.query.meetingOutcomes) ? this.$route.query.meetingOutcomes
            : this.$route.query.meetingOutcomes ? this.$route.query.meetingOutcomes.split(',') : [];
        const objectives = this.$route.query.objectives && Array.isArray(this.$route.query.objectives) ? this.$route.query.objectives
            : this.$route.query.objectives ? this.$route.query.objectives.split(',') : [];
        const allFileYears = this.$route.query.allFileYears && Array.isArray(this.$route.query.allFileYears) ? this.$route.query.allFileYears
            : this.$route.query.allFileYears ? this.$route.query.allFileYears.split(',') : [];

        this.query = {
            name: name,
            city: city,
            // notAssigned: notAssigned,
            meetingOutcomes,
            objectives,
            allFileYears,
            markets: markets,
            industries: industries,
            mandateDestinations: mandateDestinations,
            mandateYears: mandateYears,
            page,
            size: 50,
            sortAttribute: sortAttribute,
            sortOrder: sortOrder,
            annualRevenue: annualRevenue,
            regions: null,
            isHotFile,
            fileYears
        };
        await this.search();
    },
    data() {
        return {
            loading: true,
            query: {},
            results: {
                totalCount: 0,
                accounts: []
            },
        };
    },
    methods: {
        async addAccount() {
            try {
                const newAccount = {
                    industries: [],
                    markets: []
                };
                const response = await AccountService.post(newAccount);
                const routeData = this.$router.resolve({ name: 'account', params: { id: response.id } });
                if (response && response.id) {
                    window.open(routeData.href, '_blank');
                } else {
                    console.error('Error: Invalid response format');
                }
            } catch (error) {
                console.error('Error adding account:', error);
            }
        },
        setLoading(newValue) {
            this.loading = newValue;
        },
        async search(replaceParams, resetPage) {

            this.loading = true;
            if (resetPage) {
                this.query.page = 0;
            }
            if (replaceParams) {
                this.$router.replace({ query: this.query });
            }
            try {
                this.results = await AccountService.search(this.query);
            } catch (e) {
                this.results = {
                    totalCount: 0,
                    accounts: []
                }
            } finally {
                this.loading = false;
            }
        },
    },
}
</script>

<style lang="scss"></style>
