<template>
  <div class="page-account">
    <card>
      <div class="container" v-if="!loading">
        <div class="row mt-2 d-flex justify-content-between align-items-center">
          <h2 class="text-primary">
            {{ account.name }}
          </h2>
          <span class="save-notice text-secondary">{{ savingStatus }}</span>
        </div>
        <div class="row d-flex justify-content-end">
          <el-tooltip content="Supprimer la compagnie" :open-delay="100" placement="top">
            <n-button type="danger" @click.native="deleteAccount" v-click-outside="resetDeleteConfirm">
              <span class="mr-1">{{ deleteLabel }}</span>
              <i class="fa fa-trash"></i>
            </n-button>
          </el-tooltip>
        </div>
        <div class="row">
          <fg-input :class="{ 'input-warning': account.name === '' || !account.name }" class="col-md-4 col-sm-6 col-12"
            label="Compagnie" placeholder="Compagnie" v-model="account.name" :debounce="debounce"
            @save="defaultOnInput('name')" @mousedown="onNameFocused" @blur="onLostFocus" :warning="warning" />
          <div class="col-md-4 col-sm-6 col-12">
            <label>Revenu annuel</label>
            <RevenueSelector :class="{ 'input-warning': account.annualRevenue === 'unknown' }"
              v-model="account.annualRevenue" @input="defaultOnInput('annualRevenue')" />
          </div>
          <fg-input :class="{ 'input-warning': account.website === '' || !account.website }"
            class="col-md-4 col-sm-6 col-12" label="Site Web" placeholder="Site Web" v-model="account.website"
            :debounce="debounce" @save="defaultOnInput('website')" />
        </div>
        <div class="row">
          <fg-input :class="{
            'input-warning': account.billingAddress === '' || !account.billingAddress,
          }" class="col-md-4 col-sm-6 col-12" label="Adresse" placeholder="Adresse" v-model="account.billingAddress"
            :debounce="debounce" @save="defaultOnInput('billingAddress')" />
          <fg-input :class="{ 'input-warning': account.city === '' || !account.city }" class="col-md-4 col-sm-6 col-12"
            label="Ville" placeholder="Ville" v-model="account.city" :debounce="debounce"
            @save="defaultOnInput('city')" />
          <div class="col-md-4 col-sm-6 col-12">
            <label>Agent commercial</label>
            <AgentSelector :dropup="false" placeholder="Assigné à" v-model="account.ownershipIntId" type="search"
              @input="defaultOnInput('ownershipIntId')" />
          </div>
        </div>
        <div class="row">
          <fg-input :class="{
            'input-warning': account.phoneOffice === '' || !account.phoneOffice,
          }" class="col-md-4 col-sm-6 col-12" label="Téléphone bureau" placeholder="Téléphone bureau"
            v-model="account.phoneOffice" :debounce="debounce" @save="defaultOnInput('phoneOffice')" />
          <fg-input :class="{ 'input-warning': account.phoneFax === '' || !account.phoneFax }"
            class="col-md-4 col-sm-6 col-12" label="Téléphone fax" placeholder="Téléphone fax" v-model="account.phoneFax"
            :debounce="debounce" @save="defaultOnInput('phoneFax')" />
          <div class="col-md-4 col-sm-6 col-12">
            <label>Agent de recrutement</label>
            <AgentSelector :dropup="false" placeholder="Assigné à" v-model="account.ownershipCanId" type="search"
              @input="defaultOnInput('ownershipCanId')" />
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-md-4 col-sm-6 col-12">
            <label class="d-block">Regions</label>
            <el-select class="select-transparent" collapse-tags v-model="account.region" @input="onRegionsChanged"
              placeholder="Regions">
              <el-option v-for="option in regions.options" class="select-primary" :value="option.value"
                :label="option.label" :key="option.value">
              </el-option>
            </el-select>
          </div>
          <div class="form-group has-label col-md-4 col-sm-6 col-12">
            <label>Industries</label>
            <TagWidget :source="industries" :display-suggestions-on-focus="true" :max-suggestions="null"
              placeholder="Industries" v-model="account.industries" @input="defaultOnInput('industries')" />
          </div>
          <div class="col-md-4 col-sm-12 col-12">
            <label class="d-block">Territoire</label>
            <el-select class="select-transparent" collapse-tags v-model="account.territory" @input="onTerritoryChanged"
              placeholder="Territory">

              <el-option v-for="option in territoire" :disabled="!isAdmin && !user.territory?.includes(option.value)"
                class="select-primary" :value="option.value" :label="option.label" :key="option.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="row"></div>
        <div class="row">
          <div class="form-group has-label col-12">
            <label>Marchés visés.&nbsp;</label>
            <label v-if="!distinctAccountDestinations.length">Vous pouvez ajouter les marchés visés par cette entreprise à
              même les menus
              d'Appels et de RDVs.</label>
            <TagWidget :source="distinctAccountDestinations" placeholder="" :value="distinctAccountDestinations"
              :readonly="true" attribute="name" />
          </div>
        </div>
        <div class="row">
          <div class="form-group has-label col-12">
            <label>Objectifs</label>
            <goal mode="READ" :accountMarkets="accountDestinations" canDisplayFileModal></goal>
          </div>
        </div>
        <div class="row">
          <custom-textarea v-model="account.description" label="Description de l'entreprise" :debounce="debounce"
            @save="defaultOnInput('description')" />
        </div>
        <div class="row">
          <custom-textarea v-model="account.notes" label="Notes" :debounce="debounce" @save="defaultOnInput('notes')" />
        </div>
        <StaticTable class="mb-2" label="Contacts" :add="addContact" :adding="addingContact"
          adding-tooltip="Ajouter un contact" column1="Nom" column2="Mobile" column3="Email" column4="Alumni MCUL"
          :source="accountContacts" :selector-one="(e) => e.name" :selector-two="(e) => e.mobilePhone"
          :selector-three="(e) => e.email" :selector-four="(e) => e.isAlumniMCUL" @click="(data) => {
            modals.contactModal.display = true;
            modals.contactModal.data = data;
          }" :displayisAlumniMCULCheckbox="true" :error="(e) => !e.stillWorkingThere" />
        <div class="row mb-5">
          <div class="w-100 d-flex justify-content-between align-items-center">
            <label class="ml-3">Avancements</label>
            <el-tooltip content="Créer un dossier!" :open-delay="100" placement="top">
              <n-button class="mr-3" type="success" round="" icon="" @click.native="addFile()" :disabled="addingFile">
                <i class="now-ui-icons ui-1_simple-add"></i>
              </n-button>
            </el-tooltip>
          </div>
          <FollowUpWidget v-for="(file, index) in files" :key="index" :file="file" :display-year="true"
            :follow-up-modal="modals.followUpModal" :delete-file="deleteFile" />
        </div>
        <div class="row">
          <h5>
            Contenu en lecture seule (1997 à 2019)
          </h5>
        </div>
        <div>
          <!-- <el-tooltip content="Créer un mandat" :open-delay="100" placement="top" v-if="isAdmin">
            <n-button class="mr-0 float-right" type="success" round="" icon="" @click.native="addMandate">
                <i class="now-ui-icons ui-1_simple-add"></i>
            </n-button>
        </el-tooltip> -->
          <StaticTable class="mb-3" label="Mandats" column1="Année" column2="Destination" column3="Résumé"
            :source="staticMandates" :selector-one="(e) => e.year" :selector-two="(e) => e.destination"
            :selector-three="(e) => e.summary" @click="(data) => {
              modals.mandateModal.data = data;
              modals.mandateModal.display = true;
            }
              " />
        </div>
        <StaticTable class="mb-3" label="Leads" column1="Nom" column2="Créé" column3="Agent" :source="staticLeads"
          :selector-one="(e) => e.name" :selector-two="(e) => getFormattedDateTime(e.dateCreated)" :selector-three="(e) => {
            if (e.agent) return e.agent.firstName + ' ' + e.agent.lastName;
            else return '';
          }
            " @click="(data) => {
    modals.leadModal.display = true;
    modals.leadModal.data = data;
  }
    " />
        <StaticTable class="mb-3" label="Appels" column1="Nom" column2="Date" column3="Agent" :source="staticCalls"
          :selector-one="(e) => e.name" :selector-two="(e) => getFormattedDateTime(e.dateStart)" :selector-three="(e) => {
            if (e.agent) return e.agent.firstName + ' ' + e.agent.lastName;
            else return '';
          }
            " @click="(data) => {
    modals.callModal.display = true;
    modals.callModal.data = data;
  }
    " />
        <StaticTable class="mb-3" label="RDV" column1="Nom" column2="Date" column3="Agent" :source="staticMeetings"
          :selector-one="(e) => e.name" :selector-two="(e) => getFormattedDateTime(e.dateStart)" :selector-three="(e) => {
            if (e.agent) return e.agent.firstName + ' ' + e.agent.lastName;
            else return '';
          }
            " @click="(data) => {
    modals.meetingModal.display = true;
    modals.meetingModal.data = data;
  }
    " />
      </div>
      <Spinner size="4" v-if="loading" />
    </card>
    <ContactModal :modal="modals.contactModal" />
    <LeadModal :modal="modals.leadModal" />
    <CallModal :modal="modals.callModal" />
    <MeetingModal :modal="modals.meetingModal" />
    <MandateModal :modal="modals.mandateModal" />
    <FollowUpModal :follow-up-modal="modals.followUpModal" />
  </div>
</template>

<script>
import { Card, Spinner, FormGroupInput as FgInput, Button, Goal } from "src/components";
import { Tooltip } from "element-ui";
import FollowUpWidget from "../../components/FollowUpWidgets/FollowUpWidget";
import CustomTextarea from "./CustomTextarea";
import AccountService from "../../services/account.service";
import UserService from '../../services/user.service';
import FileService from "../../services/file.service";
import TagWidget from "../../components/DefaultTagWidget";
import industries from "../../util/industries.util";
import AgentSelector from "../../components/AgentSelector";
import RevenueSelector from "../../components/RevenueSelector";
import { getFormattedDateTime, getCurrentYear } from "../../util/time.util";
import StaticTable from "./StaticTable";
import ContactModal from "./ContactModal";
import LeadModal from "./LeadModal";
import CallModal from "./CallModal";
import MeetingModal from "./MeetingModal";
import MandateModal from "./MandateModal";
import moment from "moment";
import FollowUpModal from "../../components/FollowUpWidgets/FollowUpModal";
import { mapGetters } from "vuex";
import SocketService from "../../services/socket.service";
import TokenService from "../../services/token.service";
import MandateService from "../../services/mandate.service";
import regionsMixins from "../../mixins/regions.mixins";
import contactsMixins from "../../mixins/contacts.mixins";

export default {
  mixins: [regionsMixins, contactsMixins],
  components: {
    Card,
    FgInput,
    Spinner,
    TagWidget,
    CustomTextarea,
    FollowUpWidget,
    AgentSelector,
    RevenueSelector,
    StaticTable,
    ContactModal,
    LeadModal,
    CallModal,
    MeetingModal,
    MandateModal,
    [Button.name]: Button,
    [Tooltip.name]: Tooltip,
    FollowUpModal,
    Goal,
  },
  computed: {
    ...mapGetters({
      files: "files/all",
      accountDestinations: "files/accountDestinations",
    }),
    distinctAccountDestinations() {
      let flags = [],
        output = [],
        length = this.accountDestinations.length;
      for (let i = 0; i < length; i++) {
        if (flags[this.accountDestinations[i].name]) continue;
        flags[this.accountDestinations[i].name] = true;
        output.push(this.accountDestinations[i]);
      }
      return output;
    },
    deleteLabel() {
      if (this.deleteConfirm) return "Supprimer (Confirmer!)";
      return "Supprimer";
    },
    savingStatus() {
      if (this.saving) {
        return "Sauvegarde en cours...";
      } else if (this.error) {
        return "Erreur de sauvegarde!";
      } else {
        return "Modifications sauvegardées";
      }
    },
    staticLeads() {
      if (!this.account.leads) return [];
      let leads = this.$_.filter(this.account.leads, function (lead) {
        return !lead.fileId || lead.fileId === "";
      });
      leads.sort(function (a, b) {
        return moment(b.dateCreated).diff(moment(a.dateCreated));
      });
      return leads;
    },
    staticCalls() {
      if (!this.account.calls) return [];
      let calls = this.$_.filter(this.account.calls, function (call) {
        return !call.fileId || call.fileId === "";
      });
      calls.sort(function (a, b) {
        return moment(b.dateStart).diff(moment(a.dateStart));
      });
      return calls;
    },
    staticMeetings() {
      if (!this.account.meetings) return [];
      let meetings = this.$_.filter(this.account.meetings, function (meeting) {
        return !meeting.fileId || meeting.fileId === "";
      });
      meetings.sort(function (a, b) {
        return moment(b.dateStart).diff(moment(a.dateStart));
      });
      return meetings;
    },
    staticMandates() {
      if (!this.account.mandates) return [];
      return this.$_.filter(this.account.mandates, function (mandate) {
        return !mandate.fileId || mandate.fileId === "";
      });
    },
    isAdmin() {
      return TokenService.isAdmin();
    },
  },
  async created() {
    this.loading = true;
    const id = this.$route.params.id;
    this.account = await AccountService.get(this.$route.params.id);
    if (!this.account) {
      this.$router.push({ name: "home" });
    } else {
      await this.$store.commit("files/all", this.account.files);
      this.accountId = this.account.id;
      this.setAccountDestinations();
      this.setAccountGoals();
      this.setAccountContacts();
      document.title = this.account.name;
      this.loading = false;
    }

    this.user = await UserService.getCurrent();
  },
  watch: {
    "modals.followUpModal.display"(val) {
      if (!val) {
        this.$store.commit("files/active", null);
      }
    },
    'user.territory': {
      handler() {
        this.setDefaultTerritory();
      },
      deep: true
    },
    'account.territory': {
      handler() {
        this.setDefaultTerritory();
      },
      deep: true
    },
  },
  mounted() {
    this.setDefaultTerritory();
  },
  data() {
    return {
      account: {
        territory: null
      },
      loading: true,
      industries: industries,
      saving: false,
      error: null,
      debounce: 300,
      addingFile: false,
      deleteConfirm: false,
      warning: "",
      modals: {
        followUpModal: {
          display: false,
          form: "lead",
        },
        contactModal: {
          display: false,
          data: null,
        },
        leadModal: {
          display: false,
          data: null,
        },
        callModal: {
          display: false,
          data: null,
        },
        meetingModal: {
          display: false,
          data: null,
        },
        mandateModal: {
          display: false,
          data: null,
        },
      },
      goals: [],
      user: [],
      territoire: [
        { value: 'QC', label: 'Québec' },
        { value: 'CA', label: 'Canada' },
        { value: 'US', label: 'USA' },
      ],
    };
  },
  methods: {
    async addMandate() {
      const newMandate = {
        summary: "",
        year: "",
        destination: "",
        agentId: "",
        clientId: "",
        followUp: "",
        confidentialDetails: "",
        accountId: this.account.id,
      };
      await MandateService.post(newMandate, true);
      this.account.mandates.push(newMandate);
    },
    onNameFocused() {
      this.warning = "Vous allez modifier le nom de la compagnie!";
    },
    onLostFocus() {
      this.warning = "";
    },
    resetDeleteConfirm() {
      this.deleteConfirm = false;
    },
    async deleteAccount() {
      if (this.deleteConfirm) {
        await AccountService.delete(this.account.id);
        this.$router.push({ name: "home" });
      } else {
        this.deleteConfirm = true;
      }
    },
    getFormattedDateTime(timeString) {
      return getFormattedDateTime(timeString);
    },
    defaultOnInput(attribute) {
      let data = {
        id: this.account.id,
      };
      data[attribute] = this.account[attribute];
      if (attribute === "ownershipIntId" && data[attribute] && data[attribute] !== "") {
        const currentYear = getCurrentYear().toString();
        let fileForCurentYearExists = false;
        for (let i = 0; i < this.files.length; i++) {
          if (currentYear === this.files[i].year && this.files[i].type === "INT") {
            fileForCurentYearExists = true;
            break;
          }
        }
        if (!fileForCurentYearExists) {
          this.addFile("INT");
        }
      }
      if (attribute === "ownershipCanId" && data[attribute] && data[attribute] !== "") {
        const currentYear = getCurrentYear().toString();
        let fileForCurentYearExists = false;
        for (let i = 0; i < this.files.length; i++) {
          if (currentYear === this.files[i].year && this.files[i].type === "CAN") {
            fileForCurentYearExists = true;
            break;
          }
        }
        if (!fileForCurentYearExists) {
          this.addFile("CAN");
        }
      }
      this.save(data);
    },
    async addFile(type) {
      this.addingFile = true;
      let newFile = {
        year: getCurrentYear().toString(),
        accountId: this.account.id,
        type: type,
      };
      if (!type) {
        newFile = {
          year: getCurrentYear().toString(),
          accountId: this.account.id,
        };
      }
      const file = await FileService.post(newFile);
      this.$store.commit("files/add", file);
      this.addingFile = false;
    },
    async deleteFile(fileId) {
      await FileService.delete(fileId);
      this.$store.commit("files/remove", fileId);
      SocketService.emit("fileDeleted", fileId);
    },
    async save(data) {
      this.saving = true;
      this.error = null;
      try {
        await AccountService.update(data);
      } catch (e) {
        alert(
          "Une erreur est survenue lors de la sauvegarde automatique! Rafraîchissez la page!"
        );
        this.error = e;
      } finally {
        this.saving = false;
      }
    },
    onRegionsChanged(region) {
      this.account.region = region;
      const data = {
        id: this.account.id,
        region: region,
      };
      this.save(data);
    },
    setDefaultTerritory() {
      // Only set default if both user.territory and account.territory are empty or falsy
      if ((!this.isAdmin || (this.isAdmin && (!this.user.territory || this.user.territory.length === 0))) && !this.account.territory) {
        this.account.territory = 'QC';
      }
    },
    onTerritoryChanged(territory) {
      this.account.territory = territory;
      const data = {
        id: this.account.id,
        territory: territory,
      };
      this.save(data);
    },
    setAccountDestinations() {
      this.$store.commit("files/addAccountDestinations", this.account.markets);
    },
    setAccountGoals() {
      const goals = this.account.files
        .map((F) => {
          F.goals = F.goals?.map((goal) => ({ ...goal, year: F.year }));
          return F.goals;
        })
        .flat()
        .filter((G) => G);
      this.$store.commit("goals/addAccountGoals", goals);
    },
    setAccountContacts() {
      this.$store.commit("files/addAccountContacts", this.account.contacts);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/sass/select-transparent.scss";

.page-account {
  .save-notice {
    font-size: smaller;
    font-style: italic;
    text-decoration: underline;
  }

  .input-warning {
    input {
      border: 1px solid #f96332;
    }
  }
}

:deep .el-input {
  input {
    margin: 0 !important;
  }
}

:deep .el-select {
  width: 100%;
}

:deep .el-tag {
  margin-top: 4px !important;
}

:deep .modal-dialog {
  max-width: 60% !important;
  width: 60% !important;
  margin: 2.5rem auto;
}

@media (max-width: 950px) {
  :deep .modal-dialog {
    max-width: 100% !important;
    width: 100% !important;
    margin: 1rem auto;
  }
}
</style>
