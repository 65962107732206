<template>
<card class="accounts-table-card">
    <div v-if="!loading">
        <table cellspacing="0" cellpadding="0" border="0" class="w-100 custom-table">
            <thead class="has-gutter">
                <tr class="row">
                    <th rowspan="1" colspan="1" class="col-12">Mes Compagnies</th>
                    <!-- <th rowspan="1" colspan="1" class="d-none d-md-table-cell col-md-1 text-center">Lead</th>
                    <th rowspan="1" colspan="1" class="d-none d-md-table-cell col-md-1 text-center">Appel</th>
                    <th rowspan="1" colspan="1" class="d-none d-md-table-cell col-md-1 text-center">RDV</th>
                    <th rowspan="1" colspan="1" class="d-none d-md-table-cell col-md-1 text-center">Mandat</th> -->
                </tr>
            </thead>
            <tbody>
                <tr class="row body" v-for="(data, index) in accounts" :key="index" @click="goToAccountPage(data.id)">
                    <td rowspan="1" colspan="1" class="col-12">{{data.name}}</td>
                    <!-- <td rowspan="1" colspan="1" class="d-none d-md-table-cell col-md-1 text-center">
                        <span v-if="data.hasLeads">X</span>
                    </td>
                    <td rowspan="1" colspan="1" class="d-none d-md-table-cell col-md-1 text-center">
                        <span v-if="data.hasCalls">X</span>
                    </td>
                    <td rowspan="1" colspan="1" class="d-none d-md-table-cell col-md-1 text-center">
                        <span v-if="data.hasMeetings">X</span>
                    </td>
                    <td rowspan="1" colspan="1" class="d-none d-md-table-cell col-md-1 text-center">
                        <span v-if="data.hasMandates">X</span>
                    </td> -->
                </tr>
            </tbody>
        </table>
    </div>
    <Spinner size="4" v-if="loading"/>
</card>
</template>

<script>
import {Card, Spinner} from 'src/components';

export default {
    components: {
        Card,
        Spinner
    },
    props: {
        loading: Boolean,
        accounts: Array,
    },
    methods: {
        goToAccountPage(accountId) {
            const routeData = this.$router.resolve({name: 'account', params: { id: accountId }});
            window.open(routeData.href, '_blank');
        },
    }
}
</script>

<style lang="scss">
.accounts-table-card {
    .custom-table {
        tr {
            margin: 0;
            transition: background-color 200ms ease-in-out;

            &.body:hover {
                background-color: rgba(0,0,0,.05);
                cursor: pointer;
            }
        }

        th, td {
            border-bottom: 1px solid #EBEEF5;
            padding: 12px 17px;
            vertical-align: middle;
            box-sizing: border-box;
            text-overflow: ellipsis;
            text-align: left;
            min-width: 0;
        }

        th {
            font-size: 1.45em;
            font-weight: 300;
            overflow: hidden;
            color: #f96332 !important;
        }
    }
}
</style>