<template>
    <modal :show.sync="display" header-classes="justify-content-center" type="notice" @close="cancel"
        modalClasses="confirm-dialog">
        <div slot="header" class="title title-up d-flex flex-column align-items-center">
            <h4 class="m-0 font-weight-bold">{{ title }}</h4>
        </div>
        <div class="d-flex align-items-center">
            <p>{{ message }}</p>
        </div>
        <template slot="footer">
            <div class="w-100 d-flex justify-content-end">
                <n-button :type="type" class="mr-2" @click.native="confirm">
                    {{ okButton }}
                </n-button>
                <n-button type="info" @click.native="cancel">
                    {{ cancelButton }}
                </n-button>

            </div>
        </template>
    </modal>
</template>
<script>
import { Modal } from 'src/components';
export default {
    name: 'confirmModal',
    components: {
        Modal
    },
    data() {
        return {
            display: false,
            title: undefined,
            message: undefined, // Main text content
            okButton: undefined, // Text for confirm button; leave it empty because we don't know what we're using it for
            cancelButton: 'Cancel', // text for cancel button
            type: 'danger',
            // Private variables
            resolvePromise: undefined,
            rejectPromise: undefined,
        }
    },
    methods: {
        show(opts = {}) {
            this.title = opts.title
            this.message = opts.message
            this.okButton = opts.okButton
            if (opts.cancelButton) {
                this.cancelButton = opts.cancelButton
            }
            if (opts.type) {
                this.type = opts.type
            }
            // Once we set our config, we tell the popup modal to open
            this.display = true;
            // Return promise so the caller can get results
            return new Promise((resolve, reject) => {
                this.resolvePromise = resolve
                this.rejectPromise = reject
            })
        },

        confirm() {
            this.display = false;
            this.resolvePromise(true)
        },

        cancel() {
            this.display = false;
            this.resolvePromise(false)
            // Or you can throw an error
            // this.rejectPromise(new Error('User cancelled the dialogue'))
        },
    }
}
</script>
<style lang="scss" scoped>
:deep .confirm-dialog {
    width: 35% !important;
    align-self: center;
    z-index: 2000;
    // overflow-y: unset !important;
}
</style>